/**
 * Exception handler: AnalyticsException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsException(message) {
  this.message = message;
  this.name = 'AnalyticsException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsUserActionEventObjectException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsUserActionEventObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsUserActionEventObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}


/**
 * Exception handler: AnalyticsSocialActionEventObjectException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsSocialActionEventObjectException ( message )
{
  this.message = message;
  this.name = 'AnalyticsSocialActionEventObjectException';
  this.toString = () => `${ this.name }: ${ this.message }`;
}


/**
 * Exception handler: AnalyticsNonInteractiveEventObjectException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsNonInteractiveEventObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsNonInteractiveEventObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsProductImpressionObjectException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsProductImpressionObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsProductImpressionObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsProductDetailObjectException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsProductDetailObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsProductDetailObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsProductClickObjectException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsProductClickObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsProductClickObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsPromotionClickObjectException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsPromotionClickObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsPromotionClickObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsProductObjectException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsProductObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsProductObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsPromotionObjectException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsPromotionObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsPromotionObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsTagDirectiveException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsTagDirectiveException(message) {
  this.message = message;
  this.name = 'AnalyticsTagDirectiveException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsTagDirectiveException
 * @param {String} message - Exception error message.
 * @constructor
 */
export function AnalyticsPromotionImpressionObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsPromotionImpressionObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}
