<template>
  <div :style="`--silderDuration: ${transitionTime}ms`" ref="hcp">
    <div class="hpc" aria-labelledby="carouselheading" v-if="!smallScreen" :class="{'hpc-four-slide': slides.length === 4}">
      <figure class="hpc-card" :class="{'active': index === item }" v-for="(slide, index) in slides" :key="index" @click="pauseSlide(index)" @mouseover.stop="pauseSlideHover(index)" @mouseleave.stop="resetOver" :style="{'width': index === item ? activeSlideWidth : `${inActiveSlideWidth}px`}">
        <div class="hpc-card__background-image -close" :style="{backgroundImage: `url(${slide.ClosedBackgroundImage})`, 'width': index === item ? activeSlideWidth : `${inActiveSlideWidth}px`}"></div>
        <div class="hpc-card__background-image -open" :style="{backgroundImage: `url(${slide.OpenBackgroundImage})`}"></div>
        <div class="hpc-card__content" :style="{'width': index !== item ? `${inActiveSlideWidth}px` : activeSlideWidth}">
          <div class="hpc-card__title">
            <h2 v-html="slide.SliderTitle"></h2>
          </div>
          <div class="hpc-card__subtitle">
            <p v-html="slide.SliderSubTitle"></p>
            <div v-if="slide.CtaLabel!==''">
              <a :href="slide.CtaLink"  :class="ctaClass" :title="slide.CtaLabel" @click="taggingPush(slide.SliderTitle, slide.CtaLink)"><span class="btn__wrapper">{{ slide.CtaLabel }}</span></a>
            </div>
          </div>
        </div>
      </figure>
    </div>
    <div class="hpc-swipe" v-else>
      <swiper class="swiper" ref="swiper" :options="swiperOptions">
        <swiper-slide v-for="(slide, index) in slides" class="hpc-card" :data-index="index" :key="index + 0">
          <div class="hpc-card__background-image" :style="`backgroundImage: url(${slide.OpenBackgroundImageForMobile})`"></div>
          <div class="hpc-card__content">
            <div class="hpc-card__title">
              <h2 v-html="slide.SliderTitle"></h2>
            </div>
            <div class="hpc-card__subtitle">
              <p v-html="slide.SliderSubTitle"></p>
              <div :class="className" v-if="slide.CtaLabel!==''">
                <a :href="slide.CtaLink" :title="slide.CtaLabel" class="btn__wrapper" @click="taggingPush(slide.SliderTitle, slide.CtaLink)">{{slide.CtaLabel}}</a>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div> 
      </swiper>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@EventBus'
import { Swiper as SwiperClass, Pagination, Autoplay } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';

SwiperClass.use([Pagination, Autoplay]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
export default {
  name: 'home-page-carousel',
  components: { Swiper, SwiperSlide },
  props: {
    slides: { type: Array, required: true, default: undefined },
    staticMode: { type: Boolean, default: false },
    sliderDuration: { type: Number, default: 6 },
    pageCategory: { type: String, default: 'homepage' },
    ctaClass: { type: String, default: 'btn btn--white' },
  },
  data() {
    return {
      item: 0,
      itemNo: this.slides.length,
      transitionTime: (0 < this.sliderDuration ? this.sliderDuration : 6) * 1000,
      transition: null,
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      swiperOptions: {
        loop: true,
        initialSlide: 0,
        speed: 500,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets"
        },
        autoplay: { 
          delay: (0 < this.sliderDuration ? this.sliderDuration : 6) * 1000,
          disableOnInteraction: false,
        }
      },
      countMouseOver: 0,
      compWidth: 1440,
      className: this.ctaClass.includes('btn-white') ? 'btn btn-white' : this.ctaClass,
    }
  },
  created() {
    if (!this.staticMode) {
      this.autoTransition();
    }
  },
  mounted() {
    const compThis = this;
    compThis.screenMobile();
    eventBus.$on('mediaquery::changed', () => {
      compThis.smallScreen = !window.matchMedia('(min-width: 1024px)').matches;
    });
    if (compThis.smallScreen && compThis.staticMode) {
      this.autoTransition();
    }
    this.compWidth = this.$refs.hcp.getBoundingClientRect().width
  },
  methods: {
    taggingPush(title, url) {
      let urlValue = url ? url : 'none',
        titlevalue = title.toLowerCase().replace(/<(.|\n)*?>/g, '')
      AnalyticsHandler.pushDataLayer({
        'event': 'uaevent',
        'ecommerce': 'undefined',
        'event_name': 'slider_button_click',
        'eventCategory': `${this.pageCategory}`,
        'eventAction': 'select::slider navigation',
        'eventLabel': `${titlevalue}::${window.location.origin + urlValue}`,
        'cta_name': `${titlevalue}`,
        'link_url': window.location.origin + urlValue,
        'module_name': 'homepage_slider'
      });
    },
    pauseSlide(index) {
      clearInterval(this.transition);
      this.item = index;
      if (!this.staticMode) {
        this.autoTransition();
      }
    },
    pauseSlideHover(index) {
      this.countMouseOver++
      if (this.countMouseOver < 2) {
        this.pauseSlide(index)
      }
    },
    resetOver () {
      this.countMouseOver = 0
    },
    autoTransition() {
      this.transition = setInterval(() => {
        this.transitionSlide()
      }, this.transitionTime)
    },
    transitionSlide() {
      this.item = this.item + 1;
      if (this.item > this.itemNo-1) {
        this.item = 0;
      }
    },
    screenMobile() {
      eventBus.$on('mediaquery::changed', (mq) => {
        this.smallScreen = !!(mq.size === 'small' || mq.size === 'medium');
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.transition);
  },
  computed: {
    inActiveSlideWidth() {
      return this.slides.length === 4 ? (this.compWidth - 520) / 3 : (this.compWidth - 740) / 2
    },
    activeSlideWidth() {
      return this.slides.length === 4 ? '520px' : '740px'
    }
  }
}
</script>
<style lang="scss" src="./homePageCarousel.scss"></style>
