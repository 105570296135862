import { AnalyticsHandler } from '@Foundation/analyticsHandler';

export let parsedCheckBoxes = [];

/**
 * handle form label click - must have free text inside <label /> tag (not additional tags around the text) && input[type='checkbox']
 * @param {HTMLElement label} clickedContainer - clicked lable
 */
export const handleCheckBoxStateChange = (clickedContainer) => {
  parsedCheckBoxes = parsedCheckBoxes.map(item => {
    if (clickedContainer && (item.checkboxValue === clickedContainer.textContent)) {
      item.isChecked = clickedContainer.querySelector('input[type="checkbox"]').checked;
    }
    return item;
  });
};

/**
 * Handle from post && send analytics data
 */
export const handleFormSubmit = () => {
  /**
     * parse selected checkboxes values into the string to push
     * in to analytics
     */
  const selectedChecboxLabels = parsedCheckBoxes.reduce((accomulator, currentVal) => {
    if (currentVal.isChecked) {
      return accomulator + `${currentVal.checkboxValue}::`;
    }
    return accomulator;
  }, '');

  AnalyticsHandler.pushDataLayer({
    'ecommerce': undefined,
    'event': 'uaevent',
    'eventAction': 'submit::diagnostic',
    'eventCategory': 'registration',
    'eventLabel': selectedChecboxLabels,
  });
};

export const customFormAnalytics = {
  name: 'custom-form-analytics',

  inserted: (el, binding) => {
    parsedCheckBoxes = [];
    const checkBoxContainers = el.querySelectorAll(binding.value.chckBoxContainersToWatchSelector);
    checkBoxContainers.forEach(items => {
      const checkBoxLabelWrappers = items.querySelectorAll('label');
      checkBoxLabelWrappers.forEach(item => {
        /**
                 * Parse all checkboxes to be watched to more convinient format
                 * this will allow us to have correct order upon submit
                 */
        parsedCheckBoxes.push({
          checkboxValue: item.textContent,
          isChecked: item.querySelector('input[type="checkbox"]').checked
        });

        /**
                 * add click listner to checkbox wrapper (<label><input type="checkbox"> check box actual value </label>)
                 * to handle chackbox state change
                 */
        item.addEventListener('click', () => {
          handleCheckBoxStateChange(item);
        });
      });
    });

    el.addEventListener('submit', function(e) {
      e.preventDefault();
      handleFormSubmit(parsedCheckBoxes);
    }, true);
  }
};
