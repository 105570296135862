import { VueModule } from '@Foundation/utilities';
import geoPopin from './code/Scripts/components/geo-popin';

export default VueModule({
  components: {
    geoPopin
  }
});


