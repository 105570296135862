<script>
  import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';
  import { srcset } from '@Foundation/directives/srcset/srcset';
  import { tag } from '@Foundation/analyticsHandler';

  export default {
    name: 'flipper',

    components: {
      ResponsiveImage
    },

    directives: { srcset, tag },

    data() {
      return {
        isFlipped: false
      };
    },

    computed: {
      a11yAttr() {
        return {
          front: {
            tabindex: this.isFlipped ? -1 : 0
          },
          back: {
            tabindex: !this.isFlipped ? -1 : 0
          }
        };
      }
    },

    mounted() {
      this.$root.$on('closeflipped', (payload) => {
        const { block } = payload;
        /* istanbul ignore else */
        if (block !== this.$refs.flipper) {
          this.closeFlip();
        }
      });
    },

    methods: {
      toggleFlip() {
        if (!this.isFlipped) {
          this.isFlipped = true;
          this.$refs.flipper.classList.add('flip');
          this.$root.$emit('closeflipped', { block: this.$refs.flipper });
          setTimeout(() => {
            this.$refs.closeBtn.focus();
          }, 100);
        } else {
          this.isFlipped = false;
          this.$refs.flipper.classList.remove('flip');
          this.$refs.openBtn.focus();
          setTimeout(() => {
            this.$refs.openBtn.blur();
          }, 100);
        }
      },
      closeFlip() {
        this.isFlipped = false;
        this.$refs.flipper.classList.remove('flip');
      }
    }
  };
</script>

<style lang='scss' src="./flipper.scss"></style>
