export function convertVariable(variable, variables) {
  if (variable && variables) {
    const tmplVariable = variable.toLowerCase().replace(/__/g, '');

    if (tmplVariable in variables) {
      return variables[tmplVariable];
    }
  }

  return variable;
}

export function isVariable(variable) {
  if (typeof variable === 'string') {
    return variable.indexOf('__') > -1;
  }

  return false;
}
