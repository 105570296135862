/**
 * Convert Options to QueryParams for Sitecore
 * Example:
 *
 * ```json
 * {
 *   "SkinType": ["Value1", "Value2"],
 *   "Zone": ["ZV"]
 * }
 * ```
 * Render:
 * ```
 * SkinType=Value1,Value2&Zone=ZV
 * ```
 *
 * @param options
 * @returns {string}
 */
export const toQueryString = (options) =>
  Object.keys(options).reduce((acc, key) => {
    if (options[key]) {
      if (options[key] instanceof Array) {
        acc.push(encodeURIComponent(key) + '=' + encodeURIComponent(options[key].join(',')));
      } else {
        acc.push(encodeURIComponent(key) + '=' + encodeURIComponent(options[key]));
      }
    }

    return acc;
  }, []).join('&');