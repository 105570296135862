<template>
  <div>
    <template v-if="mounted">
      <load-script :attributes="{ id: scriptId }" :src="scriptSrc"/>
      <slot/>
    </template>
  </div>
</template>

<script>
  import LoadScript from '@Foundation/loadscript/loadscript';
  import { intersectionViewportObserver } from '@Foundation/utilities/intersectionViewportObserver';

  export default {
    name: 'sprinklr',
    components: { LoadScript },
    props: {
      scriptId: { type: String, required: true },
      scriptSrc: { type: String, required: true }
    },
    data() {
      return {
        mounted: false
      };
    },
    mounted() {
      intersectionViewportObserver(this.$el, { checkIsVisible: false }).then(() => {
        this.mounted = true;
      });
    }
  };
</script>
<style lang="scss" src="./sprinklr.scss"></style>
