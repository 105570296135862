export function QueryToObject(search) {
  const queries = search.replace(/^\?/, '');

  if (!queries) {
    return {};
  }

  return queries.split('&').reduce((acc, query) => {
    const type = query.split('=')[0];
    const value = decodeURIComponent(query.split('=')[1]).trim();

    if (value) {
      acc[type] = value.split(',');
    }

    return acc;
  }, {});
}
