/**
 * Check if element or ancestor has class
 * @param {node} element - Element to use
 * @param {string} className - Class name to look for
 * @returns {boolean}
 */
export function elementOrAncestorHasClass(element, className) {
  if (!element || element.length === 0) {
    return false;
  }

  let parent = element;

  do {
    if (parent === document) {
      break;
    }
    if (parent.className.indexOf(className) >= 0) {
      return true;
    }
  } while (parent = parent.parentNode);

  return false;
}
