export function uppercasepunctuation(text) {
  // Split the text by HTML tags, keeping the tags in the parts array
  let parts = text.split(/(<[^>]+>)/g);
  let endOfSentence = true;

  for (let i = 0; i < parts.length; i++) {
    if (!parts[i].match(/<[^>]+>/)) { // Check if the part is not an HTML tag
      // Capitalize the first letter after sentence-ending punctuation
      parts[i] = parts[i].replace(/\n/g, '');
      parts[i] = parts[i].replace(/(?:^|\.\s*|\:\s*)([a-z])/g, function(match, p1) {
        endOfSentence = false;
        return match.replace(p1, p1.toUpperCase());
      });

      // Capitalize the first letter at the beginning of the text
      if (endOfSentence && parts[i].match(/^\s*[a-z]/)) {
        parts[i] = parts[i].replace(/^\s*([a-z])/, function(match, p1) {
          return match.replace(p1, p1.toUpperCase());
        });
      }
    } else {
      // Reset endOfSentence flag if the part is an HTML tag
      endOfSentence = true;
    }
  }

  return parts.join('');
}
