import Vue from 'vue';
import Vuex from 'vuex';

import { productsStore } from '@Feature/LrpProducts';
import { concernsStore } from '@Feature/SafeSpaceArticles';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    products: productsStore,
    concerns: concernsStore,
  },
});
