<template>
  <div class="routine">
    <slot name="heading"/>
    <!-- routine info -->
    <div class="routine__info">
      <template v-for="option in dropdownOptions">
        <div v-for="item in optionsData[option.id]" :key="item"
             v-if="item.indexOf(activeOptionTitle) !== -1 && item.indexOf('-info') !== -1" class="routine__text">
          <slot :name="item"/>
        </div>
      </template>

      <div class="routine__dropdown" v-if="dropdownOptions.length > 1">
        <label for="routine-choice">{{ selectLabelText || dropdownOptions[activeOption].title }}</label>
        <div class="select-wrapper">
          <select id="routine-choice" v-model.number="activeOption">
            <option
              v-for="(item, index) in dropdownOptions"
              :value="index"
              :key="index"
            >
              {{ item.option }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- routine slider -->
    <div class="routine__slider">
      <slider
        :per-page-custom="[[320, 1], [1024, 3]]"
        :start-at="0"
        :navigation-enabled="true"
        :pagination-enabled="true"
        :is-pagination-clickable="false"
        :nav-tagging-label="navTaggingLabel"
        :item-per-page-desktop="3"
        @pageChange="whenPageChange"
        :page-category="pageCategory"
      >
        <template v-for="option in dropdownOptions">
          <slide
            v-for="item in optionsData[option.id]"
            :key="item"
            v-if="item.indexOf(activeOptionTitle) !== -1 && item.indexOf('-info') === -1"
          >
            <slot :name="item"/>
          </slide>
        </template>
      </slider>
    </div>

  </div>
</template>

<script>
import { eventBus } from '@EventBus';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import Slide from '@Feature/Slider/code/Scripts/components/slide/slide';
import Slider from '@Feature/Slider/code/Scripts/components/slider/slider';

export default {
  name: 'routine',
  components: {
    Slide,
    Slider
  },
  props: {
    dropdownOptions: { type: Array, required: true, default: null },
    selectLabelText: { type: String, required: true, default: 'I have' },
    navTaggingLabel: { type: [String, Array], required: false, default: '' },
    pageCategory: { type: String, required: false, default: 'page category' },

  },

  data() {
    return {
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      optionsData: {},
      activeOption: 0
    };
  },
  computed: {
    activeOptionTitle() {
      return this.dropdownOptions[this.activeOption].id;
    }
  },
  watch: {
    activeOption() {
      AnalyticsHandler.pushDataLayer({
        'event': 'uaevent',
        'ecommerce': 'undefined',
        'eventCategory': 'routine finder',
        'eventAction': 'select::condition',
        'eventLabel': `${this.dropdownOptions[this.activeOption].option}`
      });
    }
  },
  mounted() {
    this.getSlots()
    .then(this.$forceUpdate());

    eventBus.$on('mediaquery::changed', this.whenMediaQueryChange);
  },
  methods: {
    whenMediaQueryChange(mq) {
      this.smallScreen = !!(mq.size === 'small' || mq.size === 'medium');
    },

    setSlots(slotName) {
      Object.keys(this.$slots).forEach((item) => {
        if (item.indexOf(slotName) !== -1) {

          if (!this.optionsData[slotName]) {
            this.optionsData[slotName] = [];
          }
          this.optionsData[slotName].push(item);
        }
      });
    },
    getSlots() {
      return new Promise(resolve => {
        this.dropdownOptions.forEach(item => this.setSlots(item.id));
        resolve();
      });
    },
    whenPageChange(curPage) {
      document.dispatchEvent(new CustomEvent('carouselLazyImg'));
      AnalyticsHandler.pushDataLayer({
        'event': 'uaevent',
        'ecommerce': 'undefined',
        'eventCategory': 'routine finder',
        'eventAction': 'display',
        'eventLabel': curPage + 1
      });
    }
  }
};
</script>

<style lang="scss" src="./routine.scss"></style>
