export const newsletterclick = { // eslint-disable-line
  name: 'newsletterclick',
  bind: (el, binding) => {

    el.addEventListener('click', () => {
      let pos = 'footer';
      if (binding.value === true) {
        window.eventBus.$emit('newsletterpopup', { postion: pos, show: true });
      } else {
        window.eventBus.$emit('newsletterpopup', { postion: pos, show: false });
      }
    });
  }
};

