import { AnalyticsUserActionEventObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class UserActionEventObject extends DataObject {
  constructor(data) {
    super('uaevent', data);

    if (!data.category) {
      Logger.error("[AnalyticsUserActionEventObjectException] Category is required for uaevent (L'Oréal guideline)", data);
      throw new AnalyticsUserActionEventObjectException("Category is required for uaevent (L'Oréal guideline)");
    }

    if (!data.action) {
      Logger.error("[AnalyticsUserActionEventObjectException] Action is required for uaevent (L'Oréal guideline)", data);
      throw new AnalyticsUserActionEventObjectException("Action is required for uaevent (L'Oréal guideline)");
    }

    if (!data.label) {
      Logger.error("[AnalyticsUserActionEventObjectException] Label is required for uaevent (L'Oréal guideline)", data);
      throw new AnalyticsUserActionEventObjectException("Label is required for uaevent (L'Oréal guideline)");
    }

    this.result.ecommerce = undefined;
  }

  set category(category) {
    if (category === 'Ecommerce') {
      this.result.eventCategory = this.setProperty(category,false);
    } else {
      this.result.eventCategory = this.setProperty(category);
    }
  }

  get category() {
    return this.result.eventCategory;
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action);
  }

  get action() {
    return this.result.eventAction;
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label);
  }

  get label() {
    return this.result.eventLabel;
  }

  set product_info(e) {
    this.result.product_info = this.setProperty(e);
  }

  get product_info() {
    return this.result.product_info;
  }

  set event_name(e) {
    this.result.event_name = this.setProperty(e);
  }

  get event_name() {
    return this.result.event_name;
  }

  set form_name(e) {
    this.result.form_name = this.setProperty(e);
  }

  get form_name() {
    return this.result.form_name;
  }
  
  set module_name(e) {
    this.result.module_name = this.setProperty(e);
  }

  get module_name() {
    return this.result.module_name;
  }

  set cta_name(e) {
    this.result.cta_name = this.setProperty(e);
  }

  get cta_name() {
    return this.result.cta_name;
  }

  set link_url (e) {
    this.result.link_url = this.setProperty(e);
  }

  get link_url() {
    return this.result.link_url;
  }

  set click_area(e) {
    this.result.click_area = this.setProperty(e);
  }

  get click_area() {
    return this.result.click_area;
  }

  set breadcrumb(e) {
    this.result.breadcrumb = this.setProperty(e);
  }

  get breadcrumb() {
    return this.result.breadcrumb;
  }

  set video_title(e) {
    this.result.video_title = this.setProperty(e);
  }

  get video_title() {
    return this.result.video_title;
  }

  set article_name(e) {
    this.result.article_name = this.setProperty(e);
  }

  get article_name() {
    return this.result.article_name;
  }

  set product_tab(e) {
    this.result.product_tab = this.setProperty(e);
  }

  get product_tab() {
    return this.result.product_tab;
  }

  set method(e) {
    this.result.method = this.setProperty(e);
  }

  get method() {
    return this.result.method;
  }

  set search_keyword(e) {
    this.result.search_keyword = this.setProperty(e);
  }

  get search_keyword() {
    return this.result.search_keyword;
  }

  set referral(e) {
    this.result.referral = this.setProperty(e);
  }

  get referral() {
    return this.result.referral;
  }

   set value(value) {
    this.result.eventValue = this.setProperty(value);
  }
}
