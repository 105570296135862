<template>
  <div class="overlay" ref="overlay" @click="closeOverlay()" :class="{'overlay--open' : overlayOpen}"/>
</template>

<script>
  import { eventBus } from '@EventBus';

  export default {
    name: 'overlay',

    data() {
      return {
        overlayOpen: false
      };
    },

    mounted() {
      eventBus.$on('navOpen', this.closeNav.bind(this));
    },

    methods: {
      /**
       * This function closes the overlay when clicking on it
       * @function
       * @param {boolean} overlayOpen - When opened is false, the overlay is closed
       * @param {object} oBody - Need oBody to remove overflow hidden
       */
      closeOverlay() {
        this.overlayOpen = false;
        eventBus.$emit('closeNav', this.overlayOpen);
        const oBody = document.querySelector('body');
        oBody.classList.remove('overlay--active');
        oBody.classList.remove('nav-open');
      },

      /**
       * This function closes the nav when clicking on overlay
       * @function
       * @param {boolean} overlayOpen - Is the overlay open
       * @param {object} oBody - body
       */
      closeNav(value) {
        this.overlayOpen = value;
        const oBody = document.querySelector('body');
        if (this.overlayOpen === false) {
          oBody.classList.remove('overlay--active');
        } else {
          oBody.classList.add('overlay--active');
        }
      }
    },
  };
</script>
<style lang="scss" scoped src="./overlay.scss"></style>
