<template>
  <div class="spotscan-embed" v-if="smallScreen">
    <load-script :src="dropperApi"/>
      <div :class="dataCta">
        <a id="first-cta" href="#" @click="gatagging()" class="btn__wrapper opener button-poppin button-open-dsf-app">{{ dataCtaLabel }} </a>
      </div>
  </div>
</template>

<script>
  import LoadScript from '@Foundation/loadscript/loadscript';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';
  export default {
    name: 'spotscan-embed',
    components: { LoadScript },
    props: {
      dataTenant: { type: String, required: true },
      dataApplication: { type: String, required: true },
      dataCustomer: { type: String, required: false },
      dataCountry: { type: String, required: false },
      dataEnvironment: { type: String, required: false },
      dataTouchpoint: { type: String, required: false },
      dataType: { type: String, required: false },
      dataLoadingbg: { type: String, required: false },
      dataLoadingmsg: { type: String, required: false },
      csrfToken: { type: String, required: false },
      switchEcom: { type: String, required: false },
      switchAutostart: { type: String, required: false },
      dataCtaLabel: { type: String, required: false },
      dataPagecategory: { type: String, required: false },
      dataCta: {type: String, default: 'btn'},
      dataDsfTagging:{ type: Object, required: true }
    },
    data() {
      return {
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
        dropperApi: 'https://dsf-cdn.loreal.io/dropper.js'
      };
    },

    mounted() {

      var el = document.createElement('div');
      el.setAttribute('class', 'dsf-tag-emb');
      el.innerHTML = `<dsf-app
              tenant = ${this.dataTenant}
              application = "${this.dataApplication}"
              customer = "${this.dataCustomer}"
              country = "${this.dataCountry}"
              environment = "${this.dataEnvironment}"
              touchpoint = "${this.dataTouchpoint}"
              type = "${this.dataType}"
              switch_ecom = "${this.switchEcom}"
              loadingbg = "${this.dataLoadingbg}"
              loadingmsg = "${this.dataLoadingmsg}"
              csrf_token = "$WEBSITE_TOKEN"
              switch_autostart = "${this.switchAutostart}"
            />`;
      if (document.querySelectorAll('.dsf-tag-emb') === '' || document.querySelectorAll('.dsf-tag-emb').length < 1) {
        document.body.appendChild(el);
      }


    },
    methods: {
      gatagging() {
        const productName = document.querySelector('.pdp-tagging') ? document.querySelector('.pdp-tagging').getAttribute('data-tag-product-name') : '';
        const productId = document.querySelector('.pdp-tagging') ? document.querySelector('.pdp-tagging').getAttribute('data-tag-product-id') : '';
        const productInfo = (productName != '' && productId != '') ? `${productName.toLowerCase()}::${productId.toLowerCase()}` : 'none'; 
        const dataLayer = {
          ...this.dataDsfTagging,
          product_info: productInfo
        }
        AnalyticsHandler.pushDataLayer(dataLayer);
      }
    }

  };
</script>
