<template>
  <div v-if="show" class="cookie-banner">
    <div class="container">
      <div class="cookie-notification">
        <div class="cookie-info">
          <p >{{ ctaText }}<a :href="ctaLearnmoreLink" class="cookie-learn-more" > {{ ctaLearnmoreLinkText }} </a></p>
        </div>
        <div class="cookie-close-block">
          <a href="#" class="cookie-learn-more" @click="close()">{{ ctaButtonText }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'cookie-banner',
    props: {
      ctaText: { type: String, required: true },
      ctaButtonText: { type: String, required: true, default: 'ALLOW US' },
      ctaLearnmoreLink: { type: String, required: false },
      ctaLearnmoreLinkText: { type: String, required: false },
    },
    data() {
      return {
        show: true
      };
    },
    methods: {
      close() {
        this.createCookie('cookie-notification', 1, 365);
        this.show = false;
      },

      createCookie(name, value, days) {
        let expires = '';
        if (days) {
          const date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = `; expires=${date.toGMTString()}`;
        } else {
          expires = '';
        }
        document.cookie = `${name}=${value}${expires}; path=/`;
      }
    }
  };
</script>
<style lang='scss' src="./cookie-banner.scss"></style>
