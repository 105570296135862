<template>
  <div :class="{'-content-size': contentSize}" ref="banner">
    <responsive-image :animate="parallax ? 'parallax' : ''"
                      :srcset="srcset"
                      :aspect-ratio="aspectRatio"
                      :enable-dsf-service="enableDsfService"
                      :is-mobile-version-enabled="isMobileVersionEnabled"
                      :mobile-version-image="mobileVersionImage"
                      :desktop-images="desktopImages">
      <slot name="background" />
      <div v-if="contentSize" class="banner__content" slot="content">
        <slot />
      </div>
    </responsive-image>

    <div v-if="(!contentSize || smallScreen) && secondBannerSection" class="banner__content" slot="content" :class="{'content-style': smallScreen && isGenericBanner && dataContent}">
      <slot />
    </div>

    <slot name="text" />
  </div>
</template>
<script>
  import { eventBus } from '@EventBus';
  import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';

  export default {
    name: 'banner',
    components: { ResponsiveImage },
    props: {
      srcset: {
        type: [Object, String],
        required: false,
        default: undefined
      },
      contentSize: {
        type: Boolean,
        required: false,
        default: false
      },
      aspectRatio: {
        type: String,
        required: false,
        default: undefined
      },
      parallax: {
        type: Boolean,
        required: false,
        default: false
      },
      contentColor: {
        type: Boolean,
        required: false,
        default: false
      },
      isGenericBanner: {
        type: Boolean,
        required: false,
        default: false
      },
      dataContent: {
        type: Boolean,
        required: false,
        default: false
      },
      enableDsfService: {
        type: Boolean,
        required: false,
        default: false
      },
      isMobileVersionEnabled: {
        type: Boolean,
        required: false,
        default: false
      },
      mobileVersionImage: {
        type: String,
        required: false,
        default: ''
      },
      desktopImages: {
        type: [Object, String],
        required: false,
        default: undefined
      },
      secondBannerSection: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data() {
      return {
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      };
    },
    mounted() {
      this.screenCheck();
    },
    methods: {
      screenCheck() {
        eventBus.$on('mediaquery::changed', mq => {
          this.smallScreen = !!(mq.size === 'small' || mq.size === 'medium');
          this.removeClass();
        });
      },
      removeClass() {
        if (this.smallScreen === false && this.contentColor) {
          this.$refs.banner.classList.add('-white');
        } else {
          this.$refs.banner.classList.remove('-white');
        }
      }
    },
  };
</script>
<style lang="scss" src="./banner.scss"></style>
