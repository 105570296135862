<script>
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';

  export default {
    name: 'video-player',

    props: {
      isSrOnlyPlay: { type: String, default: 'Play' },
      isSrOnlyPause: { type: String, default: 'Pause' },
      src: { type: String, required: false, default: undefined },
      autoplay: { type: Boolean, required: false, default: undefined },
      preload: { type: String, default: 'metadata' },
      poster: { type: String, default: undefined },
      loop: { type: Boolean, default: false },
      playsinline: { type: Boolean, default: false },
      crossorigin: { type: String, default: undefined },
      videoName: { type: String, default: 'Video name' }
    },

    data() {
      return {
        loaded: false,
        playing: false
      };
    },

    computed: {
      cssClasses() {
        return [
          'video-player',
          this.loaded ? 'loaded' : '',
          this.playing && 'play'
        ];
      },

      posterBackground() {
        return this.poster && 'url(' + this.poster + ')';
      }
    },
    mounted() {
      this.initialize();
      this.$refs.videoPlayer.removeAttribute('controls');
    },
    methods: {
      initialize() {

        // ios fullscreen
        if (this.playsinline) {
          this.$refs.videoPlayer.setAttribute('playsinline', this.playsinline);
          this.$refs.videoPlayer.setAttribute('webkit-playsinline', this.playsinline);
          this.$refs.videoPlayer.setAttribute('x5-playsinline', this.playsinline);
          this.$refs.videoPlayer.setAttribute('x5-video-player-type', 'h5');
          this.$refs.videoPlayer.setAttribute('x5-video-player-fullscreen', false);
        }

        this.$refs.videoPlayer.addEventListener('loadeddata', () => {
          this.loaded = true;
          this.playing = !this.$refs.videoPlayer.paused;
        });

        this.$refs.videoPlayer.addEventListener('pause', () => {
          this.playing = false;
        });

        this.$refs.videoPlayer.addEventListener('playing', () => {
          this.playing = true;
        });
      },

      playPause() {
        if (!this.playing) {
          this.$refs.videoPlayer.play();
          this.playing = true;
          this.setAnalytics('play');
        } else {
          this.$refs.videoPlayer.pause();
          this.playing = false;
          this.setAnalytics('pause');
        }
      },
      setAnalytics(action) {
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'eventCategory': 'videos',
          'eventAction': action,
          'eventLabel': this.videoName,
          'event_name': 'video_interation',
          'video_title': this.videoName,
          'video_action': action
        });
      }
    }
  };
</script>
<style lang="scss">
  @import './video-player';
</style>
