<template>
  <div class="quick-sort">
    <div v-if="isQuickFilterEnable === 'True'">
      <slot name="quick-sort" :clickButton="clickQuickSort"></slot>
    </div>
    <slot name="filter-cta" :filterMenuOpen="openFilter" v-if="hideandshowbtn && (viewModel === 'True'|| sortFilter === 'True')"></slot>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import { QueryToObject } from '../../services/query-to-object'

export default {
  name: 'product-quick-sort',
  props: {
    isQuickFilterEnable: {
       default: ''
    },
    viewModel: {
      default: ''
    },
    sortFilter: {
      default: ''
    }
  },
  data() {
    return {
      filterOpen: false,
      hideandshowbtn: true,
    }
  },

  mounted() {
    let queries = QueryToObject(window.location.search);
    
    // Validate if the filters/sortby isEnabled.
    if(queries.age || queries.concern || queries.skinBenefit || queries.skinType || queries.sortBy) {
      this.quickSortButtonEnable(true);
    }
    
    this.dynamicClassAdd();

    window.eventBus.$on('quicksort', () => this.quickSortButtonEnable());

    window.eventBus.$on('closefilter', (payload) => {
      if (window.innerWidth <= 1024) { 
        if(!payload) {
          this.hideandshowbtn = true;
        }
      }else { 
       this.hideandshowbtn = true;
      }
    });
  },
  methods: {
    ...mapActions(['setFilterValue','setQuickFilterValue']),

    //Dynamic class adding for Active Quick sort buttons
    dynamicClassAdd() {
      document.addEventListener('click', elem => elem.target.classList.contains('quick-sort__button') && elem.target.classList.toggle('active'));
    },

    quickSortButtonEnable(initialLoad = false) {
      let filters = this.$store.getters.getToltalFilter;
      const elements = document.querySelectorAll('.quick-sort__button');

      let enabledFiltersArr = [];

      for(let key in filters) {
        if(filters[key].length) {
          enabledFiltersArr = enabledFiltersArr.concat(filters[key]);
        }
      }
      elements.forEach(elem => enabledFiltersArr.includes(elem.id) ? elem.classList.add('active') : elem.classList.remove('active'));

      //fetch filter products on initial load when filters enabled.
      initialLoad && this.$store.dispatch('fetchFilterProducts',true);      
    },

    openFilter() {
      if (window.innerWidth <= 1024) { 
          this.hideandshowbtn = false;
      }else { 
       this.hideandshowbtn = true;
      }
      this.filterOpen = true;
      let body = document.getElementsByTagName("body");
      if(body) {
        body[0].classList.add("filterPopup");
      }
      window.eventBus.$emit('openFilterModel', this.filterOpen);
    },
    async clickQuickSort(item, value) {
      if(value !=''){
        window.eventBus.$emit('quicksortTrigger', true);
      }
      this.setQuickFilterValue({ type: item, value });
      await this.$store.dispatch('fetchFilterProducts',true);
    }
  }
};
</script>

<style lang="scss" src="./product-quick-sort.scss"></style>
