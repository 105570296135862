// Handle prevent scroll (resolve issue with underscroll on iOS devices)
/**
 * expample: {'minResolution':1024,'bodyContollClass':'body-hide-overflow','openEvent':null,'closeEvent':'activeSliderCall'} - config
 */
export const preventBodyScroll = {
  name: 'prevent-body-scroll',

  inserted: (el, binding) => {
    const bindingData = binding.value;
    // TODO: create until to handle adaptivity
    if (window.innerWidth < bindingData.minResolution) {
      // if open event did was not passed add class immediately
      if (!bindingData.openEvent) {
        window.document.body.classList.add(bindingData.bodyContollClass);
      } else { // other wise wait for event
        window.eventBus.$on(bindingData.openEvent, () => {
          window.document.body.classList.add(bindingData.bodyContollClass);
        });
      }
      // wait for event to be called to remove scroll preventing class from body
      window.eventBus.$on(bindingData.closeEvent, () => {
        window.document.body.classList.remove(bindingData.bodyContollClass);
      });
    }
  }
};
