<template>
  <div class="carousel" ref="carousel" :class="{ 'carousel--withCounter': showCounter }">
    <slot name="header"/>

    <div class="carousel__utils" v-if="bullets || showNavigation">
      <ul v-if="bullets && nbPage > 1" class="carousel__bullets" role="none">
        <li class="carousel__bullets-item"
            v-for="index in nbPage"
            v-on="navigateWithBullets ? { click: () => setActive(index - 1) } : {}"
            :key="index"
            :class="{'active': activePage === index - 1}"
            :id="index"
            @click="setCurrent(index - 1);navTagging('dot','dot',index - 1);"/>
      </ul>
      <div class="flex--space"/>
      <div v-if="showNavigation" class="carousel__nav">
        <button class="carousel__arrow carousel__arrow--left"
                :class="activePage <= 0 ? 'disabled' : ''"
                ref="buttonPrev"
                @click="setPrev();navTagging('prev','arrow');">
          <span class="is-sr-only">{{ previousPanelText }}</span>
        </button>
        <button class="carousel__arrow carousel__arrow--right"
                :class="!(activePage < nbPage - 1) ? 'disabled' : ''"
                ref="buttonNext"
                @click="setNext();navTagging('next','arrow');">
          <span class="is-sr-only">{{ nextPanelText }}</span>
        </button>
      </div>
    </div>

    <ul class="carousel__wrapper" ref="carouselWrapper">
      <slot/>
    </ul>

    <slot name="footer"/>
  </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import { eventBus } from '@EventBus';

export default {
  name: 'carousel',

  props: {
    itemPerPageDesktop: { type: Number, required: true },
    navigation: { type: Boolean, required: false, default: true },
    bullets: { type: Boolean, required: false, default: true },
    navigateWithBullets: { type: Boolean, required: false, default: false },
    showCounter: { type: Boolean, required: false, default: false },
    screenMedia: { type: String, required: false, default: '(min-width: 1024px)' },
    pageCategory: { type: String, required: false, default: 'page category' },
    nextPanelText: { type: String, required: false, default: 'next panel' },
    previousPanelText: { type: String, required: false, default: 'previous panel' },
    navTaggingLabel: { type: [String, Array], required: false, default: '' },
    carouselName: { type: String, required: false, default: 'carousel' },
    beforeAfter: { type: Boolean, required: false, default: false },
    productName: { type: String, required: false, default: '' },
    sliderTitle: { type: Array/String, required: false, default: '' },
    imageVideoSlider: { type: Boolean, required: false, default: false },
    carouselType: { type: String, required: false, default: '' },
    carouselTitle: { type: String, required: false, default: '' },
  },

  data() {
    return {
      activePage: 0,
      //beforeAfter: false,
      nbPage: 0,
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      showNavigation: null,
      location: window.document.URL
    };
  },

  computed: {
    isRtl() {
      let elem = document.getElementById('main-container');
      let style = getComputedStyle(elem);
      if(style.direction === 'rtl')
        return true
      else
        return false
    },
    seoLabel() {
      if (typeof this.navTaggingLabel === 'string') {
        return this.navTaggingLabel.toLowerCase();
      } else {
        const slidePerPage = this.smallScreen ? 1 : this.itemPerPageDesktop;
        const start = this.activePage * slidePerPage;
        const end = start + slidePerPage;
        return `${this.carouselName}::${this.navTaggingLabel.slice(start, end).join('::')}`;
      }
    }
  },

  watch: {
    smallScreen() {
      this.nSlides = this.oSlides.length;

      if (window.matchMedia(this.screenMedia).matches) {
        this.nbPage = Math.ceil(this.nSlides / this.itemPerPageDesktop);
      } else {
        this.nbPage = this.nSlides;
      }

      this.calculateNavigationDisplay();
      this.setActive(0);
    }
  },

  mounted() {
    this.addSlideWrapper();
    this.swipeDetection();
    this.calculateNavigationDisplay();
    this.screenMobile();
    this.$refs.carouselWrapper.classList.add(`carousel__wrapper--${this.itemPerPageDesktop}-col`);

    /** no need to test the functionality of eventBus in the scope of carousel */
    /* istanbul ignore next */
    eventBus.$on('mediaquery::changed', mq => {
      this.smallScreen = !window.matchMedia('(min-width: 1024px)').matches;
    });
  },

  methods: {
    /**
     * Create a wrapper around each slides to avoid overwriting styling on elements
     * @constructor
     * @param slidesItems
     */
    decodeHtml(html) {
      let div = document.createElement("div");
      div.innerHTML = html;
      let datas=div.innerText.replaceAll('\n', ' ');
      return datas;
    },
    addSlideWrapper() {
      const slideItems = this.$refs.carouselWrapper.querySelectorAll('.carousel__wrapper > *');
      this.oSlides = [];

      slideItems.forEach((slide) => {
        const liWrapper = document.createElement('li');
        liWrapper.classList.add('carousel__slide');
        liWrapper.setAttribute('aria-hidden', true);
        liWrapper.appendChild(slide);
        this.$refs.carouselWrapper.appendChild(liWrapper);

        this.oSlides.push({ slide: liWrapper });
      });

      this.nSlides = this.oSlides.length;

      if (window.matchMedia(this.screenMedia).matches) {
        this.nbPage = Math.ceil(this.nSlides / this.itemPerPageDesktop);
      } else {
        this.nbPage = this.nSlides;
      }

      this.setActive(0);
    },

    pushAnalytics(direction = '') {
      /** no need to test the functionality of AnalyticsHandler in the scope of carousel */
      /* istanbul ignore next */
      const eventactionValue = this.navTaggingLabel !== '' ? this.seoLabel : this.decodeHtml(this.navTaggingLabel);
      var newSliderTitle=eventactionValue;
      if(this.sliderTitle[direction-1] || this.sliderTitle[direction-1]==null){
        newSliderTitle = this.decodeHtml(this.sliderTitle[direction-1]);
        if(this.sliderTitle[direction-1] == null){
          newSliderTitle =='';
        }
      }
      let carouselName=this.decodeHtml(this.carouselTitle).toLowerCase();
      if(this.carouselType == 'image-video') {
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name': 'slider_button_click',
          'eventCategory': this.pageCategory,
          'eventAction': 'select::slider navigation elements',
          'eventLabel': `${eventactionValue.toLowerCase()}::${newSliderTitle.toLowerCase()}::${direction}`,
          'cta_name': `${eventactionValue.toLowerCase()}::${newSliderTitle.toLowerCase()}::${direction}`,
          'module_name': `slider navigate::${eventactionValue.toLowerCase()}`,
        });
      } else if(this.carouselType == 'flipcard') {
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name': 'slider_button_click',
          'eventCategory': this.pageCategory,
          'eventAction': `select::${carouselName}`,
          'eventLabel': `${eventactionValue.toLowerCase()}::${direction}`,
          'cta_name': 'slider navigate::flip card',
          'module_name': `${eventactionValue.toLowerCase()}::${direction}`,
        });
      }
      else {

        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'eventCategory': this.pageCategory,
          'eventAction': `navigate::${eventactionValue}`.toLowerCase(),
          'eventLabel': `${direction}::${newSliderTitle}`.toLowerCase(),
          'event_name': 'slider_button_click',
          'module_name': `${eventactionValue}`.toLowerCase(),
          'cta_name': `${newSliderTitle}`.toLowerCase(),
          'link_url': `${this.location}`
        });
      }
    },

    /**
     * search for inframe and video to pause them
     */
    pauseVideo() {
      Array.prototype.forEach.call(this.$el.querySelectorAll('iframe'), player => {
        player.contentWindow.postMessage('{"event":"command", "func":"pauseVideo", "args":""}', '*');
      });
    },

    /**
     * Set the active slide and bullet
     * @constructor
     */
    setActive(activePage) {
      document.dispatchEvent(new CustomEvent('carouselLazyImg'));
      this.pauseVideo();

      const currentSlide = this.oSlides[activePage];
      const index = this.itemPerPageDesktop * activePage;
      this.activePage = activePage;

      /* istanbul ignore else */
      if (this.oSlides.length) {
        this.resetActiveSlide();
        if (window.matchMedia(this.screenMedia).matches) {
          this.oSlides
          .slice(index, index + this.itemPerPageDesktop)
          .forEach(item => this.setFocus(item.slide));
        } else {
          this.setFocus(currentSlide.slide);
        }
        this.$refs.carouselWrapper.style.transform = `translate3d(${this.isRtl ? '' : '-'}${this.activePage}00%, 0, 0)`;
      }
      if(currentSlide && currentSlide.slide ) {
        currentSlide.slide.classList.add('active');
      }
    },

    setFocus(slide) {
      slide.setAttribute('aria-hidden', false);

      slide.querySelectorAll('[tabindex]:not(button), a').forEach((item) => {
        item.setAttribute('tabindex', 0);
      });
    },

    resetActiveSlide() {
      this.oSlides.forEach((item) => {
        item.slide.setAttribute('aria-hidden', true);
        item.slide.classList.remove('active');

        item.slide.querySelectorAll('[tabindex]:not(button), a').forEach((item) => {
          item.setAttribute('tabindex', -1);
        });
      });
    },

    setNext() {
      if (this.beforeAfter == false) {
        if(this.navTaggingLabel === undefined){
          this.pushAnalytics(this.nbPage);
        }

      } else {
        var slideNumber = this.activePage + 2;
        this.beforeAfterTag(slideNumber);
      }
      //this.$emit('next', this.activePage);
      /* istanbul ignore else */
      if (this.activePage < this.nbPage - 1) {
        this.setActive(this.activePage + 1);
      }
    },

    setPrev() {
      if (this.beforeAfter == false) {
        if(this.navTaggingLabel === undefined){
          this.pushAnalytics(this.nbPage - 1);
        }
      } else {
        var slideNumber = this.activePage;
        this.beforeAfterTag(slideNumber);
      }
      //this.$emit('prev', this.activePage);
      /* istanbul ignore else */
      if (this.activePage > 0) {
        this.setActive(this.activePage - 1);
      }
    },

    setCurrent(slide) {
      this.setActive(slide);
      if(this.navTaggingLabel === undefined){
        this.pushAnalytics(this.activePage + 1);
      }
    },

    beforeAfterTag(slideNumber) {
      AnalyticsHandler.pushDataLayer({
        'event': 'uaevent',
        'event_name': 'module_before_after',
        'ecommerce': undefined,
        'eventCategory': 'product detail page',
        'eventAction': 'select::before after',
        'eventLabel': `${slideNumber}::${[this.productName]}`,
        'click_action': `${slideNumber}::${[this.productName]}`
      });
    },

    /**
     * Swipe detectionn
     * @constructor
     */
    swipeDetection() {
      this.touchstartX = 0;
      this.touchendX = 0;

      const gestureZone = this.$refs.carousel;

      gestureZone.addEventListener('touchstart', (e) => {
        this.touchstartX = e.changedTouches[0].screenX;
      }, { passive: true });

      gestureZone.addEventListener('touchend', (e) => {
        this.touchendX = e.changedTouches[0].screenX;
        this.handleGesture();
      }, { passive: true });
    },

    /**
     * Handle swipe left or right
     * @constructor
     */
    handleGesture() {
      let bSwipeLeft;
      const nDistance = Math.abs(this.touchendX - this.touchstartX);
      const bEnoughDist = nDistance > 30;

      if (this.isRtl) {
        bSwipeLeft = this.touchstartX < this.touchendX;
      } else {
        bSwipeLeft = this.touchendX < this.touchstartX;
      }

      /* istanbul ignore else */
      if (!bEnoughDist) {
        return;
      }

      /* istanbul ignore else */
      if (bSwipeLeft) {
        this.setNext();
      } else if (!bSwipeLeft) {
        this.setPrev();
      }
    },

    /**
     * Decide whether to show or hide navigation arrows
     * @constructor
     */
    calculateNavigationDisplay() {
      /* istanbul ignore next */
      if (!this.navigation) {
        this.showNavigation = false;
      } else if (this.navigation && !this.smallScreen) {
        this.showNavigation = this.nSlides > this.itemPerPageDesktop;
      } else {
        this.showNavigation = this.nSlides > 1;
      }
    },
    screenMobile() {
      eventBus.$on('mediaquery::changed', (mq) => {
        this.smallScreen = !!(mq.size === 'small' || mq.size === 'medium');
      });
    },
    generateSequence(start, end){
      var sequence = "";

      // Loop from start to end and push each number to the sequence array
      for (var i = start+1; i <= end; i++) {
        sequence += i+'-//-';
      }

      return sequence.slice(0,-4);;
    },
    navTagging(param,navigationEvent,index=null){
      const currentElemnt = this.$el;
      let self = currentElemnt.querySelector('.carousel__bullets-item.active').id;
      let activeSliderNumber = 0;
      if(param !=='dot'){
        if(currentElemnt.querySelector(".carousel__bullets-item.active").id){
          activeSliderNumber=currentElemnt.querySelector(".carousel__bullets-item.active").id;
        }
      }
      let start =1;
      let end =1;
      let sliderValue =parseInt(activeSliderNumber);
      const slideNum = param === 'next' ? 1 : -1
      sliderValue +=  slideNum;
      let newvalue='';
      let sliderNumber =''
      if(this.smallScreen){
        start = sliderValue-1;
        end = sliderValue;
        if(param ==='dot'){
          start = parseInt(index);
          end = start +1;
        }
        sliderNumber =end;
        newvalue= `${this.navTaggingLabel.slice(start, end)}`;
      }
      else{
        start = sliderValue *this.itemPerPageDesktop;
        end = start - this.itemPerPageDesktop;
        if(param ==='dot'){
          end = parseInt(index) *this.itemPerPageDesktop;
          start = end +this.itemPerPageDesktop;
        }
        sliderNumber =end+1;
        newvalue= `${this.navTaggingLabel.slice(end, start).join('-//-')}`;
      }
      let arrayLenth = this.navTaggingLabel.slice(end, start).length;
      if(arrayLenth >1){
        sliderNumber=this.generateSequence(end, end+arrayLenth);
      }
      AnalyticsHandler.pushDataLayer({
        event: "uaevent",
        ecommerce: "undefined",
        event_name: "slider_button_click",
        eventCategory: this.pageCategory,
        eventAction: "select::slider navigation elements",
        eventLabel: `${navigationEvent}::${newvalue}::${sliderNumber}`,
        cta_name:  `${navigationEvent}::${newvalue}::${sliderNumber}`,
        module_name: `slider navigate::${navigationEvent}` });
    },
  },
};
</script>

<style lang="scss" src="./carousel.scss"></style>
