// import Vue from 'vue';
import { getConcerns } from '../services/concern-service';
import { toQueryString } from '../services/to-querystring';
import { getHighlight } from '../services/get-highlight';

export const concernsStore = {
  state: {
    loading: false,
    filterConcerns: [],
    filterId: '',
    initialConcernData: [],
    filterDropdownData: [],
    noMoreConcerns: false,
    totalConcernCount: 0,
    initialConcernCount: 0,
  },

  mutations: {
    loading(state, value) {
      state.loading = value;
    },
    initialConcernData(state, value) {
      state.filterConcerns = [];
      state.initialConcernData = [];
      state.noMoreConcerns = false;
      value.forEach((data) => {
        state.initialConcernData.push(data);
      });
      state.initialConcernData.forEach((data) => {
        state.filterConcerns.push(data);
      });
    },
    // resetConcernData(state) {
    //   state.filterConcerns = [];
    //   state.noMoreConcerns = false;
    //   state.initialConcernData.forEach((data) => {
    //     state.filterConcerns.push(data);
    //   });
    // },
    filterDropdownData(state, value) {
      value.forEach((data) => {
        state.filterDropdownData.push(data);
      });
    },
    filterId(state, value) {
      state.filterId = value;
    },
    filterConcerns(state, payload) {
      let concernlist = payload;
      concernlist.forEach((value) => {
        state.filterConcerns.push(value);
      });
    },
    totalConcernCount(state, value) {
      state.totalConcernCount = value;
    },
    noMoreConcerns(state, payload) {
      state.noMoreConcerns = payload;
    },
    clearConcerns(state) {
      state.filterConcerns = [];
      state.initialConcernData.forEach((data) => {
        state.filterConcerns.push(data);
      });
      state.totalConcernCount = state.initialConcernCount;
    },
  },

  actions: {
    clearConcerns({ commit }) {
      commit('clearConcerns');
    },
    storeInitialConcernData({ commit, state }, initialConcernData) {
      if (state.initialConcernData && state.initialConcernData.length) {
        const concernData = getHighlight(state.initialConcernData);
        commit('initialConcernData', concernData);
      } else {
        const concernData = getHighlight(initialConcernData);
        commit('initialConcernData', concernData);
      }
    },
    storeResetConcernData({ commit }) {
      commit('resetConcernData');
    },
    storeInitialFilterData({ commit }, initialFilterData) {
      commit('filterDropdownData', initialFilterData);
    },
    storeTotalConcernCount({ commit, state }, concerncount) {
      state.initialConcernCount = concerncount;
      commit('totalConcernCount', concerncount);
    },
    async fetchConcerns({ commit, state }, filters) {
      let filterId = '';
      if (filters.isFilter) {
        filterId = filters.filterListId;
        state.filterConcerns = [];
        const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${toQueryString({ q: filters.filterLabel.toLowerCase() })}`;
        window.history.pushState({}, '', newurl);
      } else {
        filterId = state.filterDropdownData[state.totalConcernCount + 1] ? state.filterDropdownData[state.totalConcernCount + 1].TerritoryItemid : '';
      }
      if (filterId) {
        commit('loading', true);
        const concerns = await getConcerns(filterId);
        if (concerns.success && concerns.data.length !== 0) {
          commit('noMoreConcerns', false);
          commit('totalConcernCount', state.filterConcerns.length + 1);
          const dataList = getHighlight([concerns.data]);
          commit('filterConcerns', dataList);
        } else if (!concerns.success && concerns.data.length === 0) {
          commit('noMoreConcerns', true);
        }
        commit('loading', false);
      }
    },
  },
  getters: {
    getFilterConcerns: (state) => state.filterConcerns,
    getNoMoreConcerns: (state) => state.noMoreConcerns,
    getTotalConcernsCount: (state) => state.totalConcernCount,
    concernLoading: (state) => state.loading,
  },
};
