import { AnalyticsPromotionImpressionObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';
import PromotionObject from './PromotionObject';

export default class PromotionImpressionObject extends DataObject {
  constructor(data) {
    super('nievent', data);

    this.category = 'Ecommerce';
    if (data.category) {
      Logger.warn(
        `[AnalyticsPromotionImpressionObjectException] Category always defaults to "Ecommerce" (L'Oréal guideline), currently [${
          data.category
        }] was provided`,
        data,
        false
      );
    }

    this.action = 'Promotion Impressions';
    if (data.action) {
      Logger.warn(
        `[AnalyticsPromotionImpressionObjectException] Action always defaults to "Promotion Impressions" (L'Oréal guideline), currently [${
          data.action
        }] was provided`,
        data,
        false
      );
    }

    this.label = 'Promotion Impressions';
    if (data.label) {
      this.label = data.label;
    }

    if (!data.promotions || data.promotions.length === 0) {
      Logger.error('[AnalyticsPromotionImpressionObjectException] Promotion(s) are required for a promotionImpression', data);
      throw new AnalyticsPromotionImpressionObjectException('Promotion(s) are required for a promotionImpression');
    }
  }

  initEcommerce() {
    this.result.ecommerce = this.result.ecommerce || {};
    this.result.ecommerce.promoView = this.result.ecommerce.promoView || {};
    this.result.event_name = 'view_promotion' || {};
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category, false);
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action, false);
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label, false);
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set promotions(promotions) {
    this.initEcommerce();
    this.result.ecommerce.promoView.promotions = promotions.map(promotion => new PromotionObject(promotion).toObject());
  }
}
