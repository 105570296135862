/* eslint-disable no-new,dot-notation,no-console */
import { debug } from '../env/env';
import { eventBus } from '../eventbus';

// Export globally the content of this modules
/* istanbul ignore next */
try {
  /**
   * @deprecated
   * @type {any}
   */
  window.wsf = Object.assign(window.wsf || {}, {
    debug,

    /**
     * @deprecated
     */
    eventBus
  });
} catch (er) {
  console.warn('unable to load wsf variable');
}
