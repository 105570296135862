<script>
  import { tag, AnalyticsHandler } from '@Foundation/analyticsHandler';
  import { getVotes, postVotes } from '../services/voting-service';

  export default {
    name: 'smiley-vote',

    directives: {
      tag
    },

    props: {
      updateVoteUrl: { type: String, required: true, default: undefined },
      getVotesUrl: { type: String, required: true, default: undefined },
      answerId: { type: String, required: true, default: undefined },
      pageId: { type: String, required: true, default: undefined },
      value: { type: String, default: undefined },
      tagCategory: { type: String, default: undefined },
      tagLabel: { type: String, default: undefined },
      tagActionDownvote: { type: String, default: undefined },
      tagActionUpvote: { type: String, default: undefined }
    },

    data() {
      return {
        checked: this.value,
        initialUpvote: null,
        initialDownvote: null,
        positiveVote: null,
        negativeVote: null,
        isVotesUpdated: null
      };
    },

    mounted() {
      this.getVotes();
      this.checkStorage();
    },

    methods: {
      checkStorage() {
        /* istanbul ignore else */
        if (sessionStorage.getItem(this.answerId)) {
          this.checked = sessionStorage.getItem(this.answerId);
        }
      },
      addToStorage() {
        /* istanbul ignore else */
        if (sessionStorage.getItem(this.answerId)) {
          this.checked = sessionStorage.getItem(this.answerId);
        }
      },
      addActive(state) {

        if (state === this.checked) {
          this.checked = undefined;
          this.$emit('change', undefined);
        } else {
          this.checked = state;
          this.$emit('change', this.checked);
        }

        // save user choise

        this.callApi()
          .then(() => {
            sessionStorage.setItem(this.answerId, this.checked);
          });
      },

      getVotes() {
        return getVotes(`${window.location.origin}${this.getVotesUrl}`, this.answerId)
          .then((votes) => {
            this.initialUpvote = votes.PositiveVotes;
            this.initialDownvote = votes.NegativeVotes;
            this.positiveVote = votes.PositiveVotes;
            this.negativeVote = votes.NegativeVotes;
          });
      },

      sendVotes(votes) {
        return postVotes(`${window.location.origin}${this.updateVoteUrl}`, votes)
          .then((data) => {
            this.isVotesUpdated = data;
          })
          /* istanbul ignore next */
          .catch((error) => {
            /* istanbul ignore next */
            this.isVotesUpdated = false;
          });
      },

      callApi() {
        return new Promise((resolve) => {
          if (this.checked === 'yes') {
            this.sendVotes({
              'PositiveVote': 1,
              'NegativeVote': this.initialDownvote - this.negativeVote,
              'answerId': this.answerId,
              'pageId': this.pageId
            });

            this.positiveVote += 1;

            this.initialDownvote < this.negativeVote ? this.negativeVote -= 1 : false;

          } else if (this.checked === 'no') {
            this.sendVotes({
              'PositiveVote': this.initialUpvote - this.positiveVote,
              'NegativeVote': 1,
              'answerId': this.answerId,
              'pageId': this.pageId
            });

            this.negativeVote += 1;

            this.initialUpvote < this.positiveVote ? this.positiveVote -= 1 : false;

          } else {
            /* istanbul ignore else */
            if (sessionStorage.getItem(this.answerId)) {
              const status = sessionStorage.getItem(this.answerId);

              if (status === 'yes') {
                this.sendVotes({
                  'PositiveVote': -1,
                  'NegativeVote': 0,
                  'answerId': this.answerId,
                  'pageId': this.pageId
                });

                this.positiveVote -= 1;

              } else if (status === 'no') {
                this.sendVotes({
                  'PositiveVote': 0,
                  'NegativeVote': -1,
                  'answerId': this.answerId,
                  'pageId': this.pageId
                });

                this.negativeVote -= 1;
              }
            }
          }

          resolve();
        });
      }
    }
  };
  window.onload = function(e){
	    var element = document.querySelector('.derm-answer__text strong');
      var numberOfChildren = element && element.getElementsByTagName('a').length;
      if (numberOfChildren > 0) {
        document.querySelectorAll('.derm-answer__text strong a').forEach(item => {
          item.addEventListener('click', event => {
            AnalyticsHandler.pushDataLayer({
              'event': 'uaevent',
              'event_name': 'body_button_click',
              'ecommerce': 'undefined',
              'eventCategory': event.target.closest('.derm-answer__text').attributes["data-pagecategory"].value,
              'eventAction': `select::instant answer`,
              'eventLabel': `${event.target.closest('.derm-answer__text').attributes["data-moduleTitle"].value.toLowerCase()}`,
              'module_name': `instant answer`,
              'cta_name': `${event.target.closest('.derm-answer__text').attributes["data-moduleTitle"].value.toLowerCase()}`,
              'link_url': element.lastChild.href
            });
          });
        });
      }
}
</script>
<style lang='scss' src="./smiley-vote.scss"></style>
