<template>
<div>
<!-- Filter-->
<div class="article-filters products-filters container container--primary-color">
    <div class="container--is-maxwidth products-filters__wrapper" style="height: auto;">
       <div class="products-filters-sticky">
          <div class="products-filters__container container--is-maxwidth">
             <div class="products-filters__select-wrapper">
                <div>

    <div class="article-filter-list control">

      <div class="select-custom">
         <div class="select-custom__btn">

            <select id="concern" name="concern" @change="filterapi">
              <option>All Categories </option>
              <option :key="index" v-for="(articleCategory, index) in articleCategoryList" :value="articleCategory">{{articleCategory}}</option>
            </select>
            <svg width="16" height="8" aria-hidden="true">
               <path fill-rule="nonzero" d="M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"></path>
            </svg>
         </div>
      </div>
   </div>

                </div>


          </div>
         </div>
       </div>
    </div>
 </div>



  <!-- Filter-->

  <div class="article-list" id="articleListId">
      <ul class="article-list-container container--is-maxwidth" >
        <li class="article-list__item" v-for="(article, index) in initialArticles" :key="index">
          <div class="article-push">
            <div class="article-push__content">
              <responsive-image aspect-ratio="69" :srcset="article.ArticleImageUrl">
                <img src="/frontend/master/images/placeholder.png" alt="placeholder">

                <noscript>
                  <img :src="article.ArticleImageUrl" alt="">
                </noscript>
              </responsive-image>

                <h4><span v-html="article.ArticleTitle"/> </h4>

             <a :href="article.ArticleURL"> <div class="btn">  <span class="btn__wrapper">
                  Learn More
                </span>
              </div> </a>


            </div>
          </div>
        </li>
      </ul>
     <div class="article-load-more">

      <div class="btn" @click="loadmoredata()"  v-if="loadmore">
         <span class="btn__wrapper">
             Load More
         </span>
      </div>

     </div>
    </div>
    <div class="loader-page" v-if="showLoader">
      <div class="loader-item">
            <em class="custom-loader --1"></em>
        </div>
    </div>

  </div>
</template>
<script>
  // remove when implement cards for list types
  import Loader from '@Feature/Loader/code/Scripts/components/loader';
  import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';
  import { postData } from '@Foundation/services';

  export default {
    name: 'article-list',
    components: {
      ResponsiveImage,
      Loader
    },
    props: {
      listItems: {
        type: Array,
        default() {
          return undefined;
        }
      },
      dataLoadmoreApi: { type: String},
      dataFilterApi: { type: String},
      dataArticelCount: { type: Number},
      articleCategoryList : { type: Array,
        default() {
          return undefined;
        }
      },
      articleConcernList : { type: Array,
        default() {
          return undefined;
        }
      },


    },
    data() {
      return {
        showLoader:false,
        loadmore: true,
        articleSize: 12,
        articleLength: null,
        articleCount: 1,
        initialArticles: []
      };
    },
    mounted() {
      this.initialArticles= this.listItems;

    },
    methods: {
      filterapi(e){
        const val = e.target.value
        this.showLoader=true;
         postData(`${window.location.origin}/${this.dataFilterApi}`, {
          'concern': val
         })
         .then((response) => {
            this.initialArticles =response;
            this.showLoader=false;
            this.loadmore=false;
          })
          .catch(() => {
            this.serverError = true;
          });
      },
      loadmoredata(){
        this.articleCount=this.articleCount+1;
        let url = window.location.orgin;
        this.loadmore=false;
        this.showLoader=true;
        postData(`${window.location.origin}/${this.dataLoadmoreApi}`, {
          'pageNumber': this.articleCount
        })
          .then((response) => {
            this.initialArticles =response;
            this.showLoader=false;
            this.loadmore=true;
          })
          .catch(() => {
            this.serverError = true;
          });


      }

    },


  };
</script>

<style lang="scss" src="./article-list.scss"></style>
