<template>
  <div>
    <div :class="cssClasses">

      <load-script src="https://www.youtube.com/iframe_api" v-if="playing" @loaded="onYouTubeIframeAPIReady"/>

      <div v-if="!!poster" class="youtube-player__poster" :style="'background-image: ' + posterBackground" ref="poster"/>

      <button v-if="!!poster && !newplayButton" class="video-player__btn-play-pause youtube-player__btn"
              @click="playPause"
              ref="playBtn">
        <span class="btn-bar-1"/>
        <span class="btn-bar-2"/>
        <span class="btn-bar-3"/>
        <span class="is-sr-only">{{ ariaLabel }}</span>
      </button>
      <button v-else-if="!!poster && newplayButton" class="play-button"
              @click="playPause"
              ref="playBtn">
        <span class="play-icon"/>
        <span class="play-text" v-html="playText"/>
        <span class="is-sr-only">{{ ariaLabel }}</span>
      </button>


      <div class="youtube-player__wrapper" v-show="playing && !!poster || !poster && loaded">
        <div class="youtube-player__item" :id="playerId"/>
      </div>

    </div>

    <slot :videoTitle="videoTitle" />
  </div>
</template>

<script>
  /* eslint-disable no-undef*/
  import LoadScript from '@Foundation/loadscript/loadscript';
  import Collapse from '@Feature/Collapse/code/Scripts/components/collapse.vue';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';

  export default {
    name: 'youtube-player',

    components: {
      LoadScript,
      Collapse
    },

    props: {
      youtubeId: { type: String, required: false, default: undefined },
      src: { type: String, required: false, default: undefined },
      autoplay: { type: Boolean, required: false, default: undefined },
      list: { type: String, required: false, default: undefined },
      preload: { type: String, default: 'metadata' },
      poster: { type: String, default: undefined },
      loop: { type: Boolean, default: false },
      newplayButton: { type: Boolean, required: false },
      playText: { type: String, default: 'PLAY' },
      ariaLabel: { type: String, default: 'play pause', required: false }
    },

    data() {
      return {
        player: null,
        loaded: false,
        playing: false,
        videoTitle: '',
        transcriptLabel: ''
      };
    },

    computed: {
      cssClasses() {
        return [
          'youtube-player',
          this.loaded ? 'loaded' : '',
          this.playing && 'play'
        ];
      },

      playerId() {
        return `player-${this.youtubeId}-${Math.floor(Math.random() * 1000) + 1}`;
      },

      posterBackground() {
        return this.poster && 'url(' + this.poster + ')';
      }
    },

    methods: {
      playPause() {
        this.playing = true;
        this.$refs.poster.style.display = 'none';
        this.$refs.playBtn.style.display = 'none';

        if (this.autoplay) {
          try {
            this.player.playVideo();
          } catch (e) {
            /* istanbul ignore next */
            setTimeout(() => {
              this.player.playVideo();
            }, 500);
          }
        }
      },

      onYouTubeIframeAPIReady() {
        this.loaded = true;

        return this.setupYT().then(() => this.onPlayerLoaded());
      },

      onPlayerLoaded() {
        return new Promise((resolve) => {
          this.player = new YT.Player(this.playerId, {
            playerVars: {
              showinfo: 1,
              rel: 0,
              cc_load_policy: 1,
              color: 'white',
              autoplay: 1,
              controls: 2,
              enablejsapi: 1,
              modestbranding: 1,
              playsinline: 1
            },
            videoId: this.youtubeId,
            events: {
              onReady: () => {
                const iframe = this.player.getIframe();

                iframe.removeAttribute('height');
                iframe.removeAttribute('width');
                iframe.removeAttribute('frameborder');
                this.videoTitle = this.player.getVideoData().title;
                iframe.setAttribute('title', `YouTube video: ${this.videoTitle}`);
                resolve();
              },
              onStateChange: () => {
                this.onPlayerStateChange(event);
                resolve();
              }
            }
          });
        });
      },
      onPlayerStateChange(event) {
        const k = JSON.parse(event.data);
        if (k.info === 0) {
          this.setAnalytics('video completed');
          this.setAnalytics(`${Math.floor(this.getPercentage())}% viewed`);
        } else if (k.info === 1) {
          this.setAnalytics('play');
          this.setAnalytics(`${Math.floor(this.getPercentage())}% viewed`);
        } else if (k.info === 2) {
          this.setAnalytics('pause');
          this.setAnalytics(`${Math.floor(this.getPercentage())}% viewed`);
        } else if (k.info === 3) {
          this.setAnalytics('buffering');
        }
      },
      getPercentage() {
        var c = this.player.getCurrentTime() / this.player.getDuration() * 100; //calculate % complete
        return Math.round(c); //round to a whole number
      },
      setAnalytics(action) {
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'eventCategory': window.wsf.AnalyticsVariables.pageCategory,
          'eventAction': this.player.getVideoData().title,
          'eventLabel': action,
          'event_name': 'video_interation',
          'video_title': this.player.getVideoData().title,
          'video_action': action
        });
      },
      setupYT() {
        return new Promise((resolve) => {
          let interval = false;
          interval = setInterval(() => {
            if (typeof(YT) !== 'undefined' && YT.loaded === 1 && typeof(YT.Player) !== 'undefined') {
              clearInterval(interval);
              resolve(YT);
            }
          }, 100);
        });
      }
    }
  };
</script>
<style lang="scss" src="./youtube-player.scss"></style>
