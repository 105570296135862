<template>
  <div v-if="initialData.IsEnable" class="lead-iframe">
      <modal class="lead-iframe__container--modal" ref="modal">      
      <div class="lead-iframe__container__wrapper">
           <button class="modal__close-btn"
                  @click="closeModal"             
                  type="button">
          <div class="btn_close close" id="btn_close"/>
        </button>
          <div class="lead-iframe__content" v-html="initialData.HtmlDiv"></div> 
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@Feature/Modal/code/Scripts/components/modal/';

export default {
  name: 'lead-iframe',
  components: { 
    Modal
    },
  props: {   
    initialData: { type: Object, default: () => ({ IsEnable: false, HtmlDiv: '', Script: '' })}, 
  },
  mounted() {    
    if (this.initialData.IsEnable) {
      this.showModal();
    }
    this.insertScript();
  },
 methods: {
    showModal() {
      this.$nextTick(() => {
        this.$refs.modal.$data.showModal = true;
      });
    },
    closeModal() {
      this.$refs.modal.$data.showModal = false;
    },
    insertScript() {
      if (this.initialData.Script) {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'text/javascript';
        scriptTag.text = this.initialData.Script;
        document.head.appendChild(scriptTag);
      }
    }
  }
};
</script>

<style lang="scss" src="./lead-iframe-popup.scss"></style>