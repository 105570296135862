export * from './AnalyticsHandler';
export * from './models';
export * from './directives/tag';
export * from './directives/newsletterclick';
import { tag } from './directives/tag';
import { newsletterclick } from './directives/newsletterclick';

export default {
  directives: {
    tag,
    newsletterclick
  }
};
