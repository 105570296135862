import { AnalyticsNonInteractiveEventObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class NonInteractiveEventObject extends DataObject {
  constructor(data) {
    super('nievent', data);

    if (!data.category) {
      Logger.error("[AnalyticsNonInteractiveEventObjectException] Category is required for nievent (L'Oréal guideline)", data);
      throw new AnalyticsNonInteractiveEventObjectException("Category is required for nievent (L'Oréal guideline)");
    }

    if (!data.action) {
      Logger.error("[AnalyticsNonInteractiveEventObjectException] Action is required for nievent (L'Oréal guideline)", data);
      throw new AnalyticsNonInteractiveEventObjectException("Action is required for nievent (L'Oréal guideline)");
    }

    if (!data.label) {
      Logger.error("[AnalyticsNonInteractiveEventObjectException] Label is required for nievent (L'Oréal guideline)", data);
      throw new AnalyticsNonInteractiveEventObjectException("Label is required for nievent (L'Oréal guideline)");
    }
  }

  set noninteractivecategory(category) {
    this.result.eventCategory = this.setProperty(category);
  }

  get noninteractivecategory() {
    return this.result.eventCategory;
  }

  set noninteractiveaction(action) {
    this.result.eventAction = this.setProperty(action);
  }

  get noninteractiveaction() {
    return this.result.eventAction;
  }

  set noninteractivelabel(label) {
    this.result.eventLabel = this.setProperty(label);
  }

  get noninteractivelabel() {
    return this.result.eventLabel;
  }

  set noninteractivevalue(value) {
    this.result.eventValue = this.setProperty(value);
  }
}
