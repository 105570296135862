<template>
   <div class="its_proven">
    <div class="container--is-maxwidth its_proven-container">
     <div class="its_proven__component" >
       <div class="title" v-html="title"></div>
       <div  class="its_proven__component_content" v-html="description" v-if="description"></div>
     </div>
     <div class="its_proven__card-wrapper" :class="{'two-cards' : cardsData.length <= 2}">
      <div class="card-item" v-for="(item, index) in cardsData" :key="index" :class="cardsData.length > 2 ? '' : 'two-card-item'">
         <div  class="card-item__image-wrapper">
           <img  :src="item.CardImage" :alt="item.ImgAlt || item.CardDescription" loading="lazy" />
         </div>
        
         <div class="card-item__content">
           <div class="card-item__content__title">
             <span v-html="item.CardDescription "></span>
             <a v-if="item.CtaLabel && item.CtaLink" :href="item.CtaLink" class="cta--secondary cta--light">{{ item.CtaLabel }}</a>
           </div>
         </div>
       </div>
      
     </div>
 <div class="its_proven__componentcta" v-if="componentCtaLink && componentCtaText">
       <a :href="componentCtaLink" class="cta--secondary cta--light" v-if="componentCtaLink && componentCtaText">{{ componentCtaText }}</a>
     </div>
     <div class="its_proven__disclamier" v-html="componentDisclamier"  v-if="componentDisclamier && componentDisclamier !==''"></div>
   </div>
   </div>
 </template>
 
<script>
  export default {
    name: 'article-proven',
    props: {
      title: { type: String, default: 'it’s proven' },
      description:{ type: String, default: '' },
      componentDisclamier: { type: String, default: '' },
      componentCtaLink: { type: String, default: '' },
      componentCtaText: { type: String, default: '' },
      cardsData: {
        type: [Array, String],
        required: false,
        default: undefined
      },
    }
  };
</script>
<style lang="scss" src="./article-proven.scss"></style>
