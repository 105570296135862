
<script>
  import { throttle } from '@Foundation/utilities';
  import { tag } from '@Foundation/analyticsHandler';
  import Modal from '@Feature/Modal/code/Scripts/components/modal';

  export default {
    name: 'diagnosis-trigger',

    components: {
      Modal
    },

    directives: {
      tag
    },

    props: {
      firstTimeData: { type: Object, required: false, default: undefined },
      nextTimeData: { type: Object, required: false, default: undefined },
      cookieName: { type: String, required: false, default: undefined }
    },

    data() {
      return {
        position: 0
      };
    },

    computed: {
      positionStyle() {
        return {
          btn: `padding-bottom: ${this.position}px`
        };
      },
      modalContent() {
        return !!this.getCookie(this.cookieName) ? this.nextTimeData : this.firstTimeData;
      },
      resultPageUrl() {
        return this.getCookie(this.cookieName);
      }
    },

    mounted() {
      this.position = this.getPosition();

      window.addEventListener('scroll', throttle(() => {
        window.pageYOffset + window.innerHeight > document.body.clientHeight - 10 ? this.position = 0 : this.position = this.getPosition();
      }, 160));
      this.setFingerPrintPos();
      window.addEventListener('resize', this.setFingerPrintPos);      
    },

    methods: {
      getCookie(name) {
        const matches = document.cookie.match(new RegExp(
          '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
      },
      setFingerPrintPos(){
        let calc =  document.querySelector('#main-container').getBoundingClientRect();
        document.querySelector('.diagnosis-trigger').style.right = `${calc.x}px`;
      },
      getPosition() {
        let result = 0;
        /*istanbul ignore else */
        if (!!document.querySelector('.sticky-wrapper')) {
          result = document.querySelector('.sticky-wrapper').clientHeight;
        } else if (!!document.querySelector('.products-filters-sticky.is-sticky')) {
          result = document.querySelector('.products-filters-sticky.is-sticky').clientHeight;
        }
        return result;
      },

      SetPreviousPageName() {
        sessionStorage.setItem('lrp-previous-page-name', document.title);
      }
    }
  };
</script>

<style lang="scss" src="./diagnosis-trigger.scss">

</style>
