<template>
  <div class="language-switcher">
    <div class="main-menu">
       <div class="main-menu-bar__languages-new" 
              v-clickoutside="languagesClose">
           
              <button :aria-label="a11yAriaLabelLanguage"
                  :aria-expanded="languagesShow ? 'true' : 'false'"
                  :class="{ 'is-active': languagesShow }"
                  @click="languagesToggle()"
                  @blur="languageOnBlur()"
                  ref="languageBtn"
                  v-tag:useractionevent="{ label: 'language', category: 'header', action: 'display::language' }">
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M5.33366 8.6665L12.0005 15.3332L18.667 8.6665"  stroke-width="1.2"/>
            </svg> 
             {{ currentLanguage }}     
          </button>
            
          <transition name="main-menu-languages">
            <ul v-show="languagesShow"
                @keyup.esc="languagesToggle()">
                <li v-for="(itemDatavalue, index) in itemData" v-if="itemDatavalue.CustomLanguageCode !== currentLanguage">
                   <a :href="itemDatavalue.Link" :aria-label="itemDatavalue.NativeName" class="js-main-menu-language-new">{{itemDatavalue.CustomLanguageCode}}</a>
                   </li>
              
            </ul>
          </transition>
        </div>
    </div>
  </div>

</template>
<script>
  import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';
  import { tag } from '@Foundation/analyticsHandler';
 
export default {
  name: 'language-switcher',
  directives: {
      clickoutside,
      tag,
    },
  props: {
      currentLanguage: { type: String, required: false, default: 'EN' },
      a11yAriaLabelLanguage: {
        type: String,
        default: 'English (change language)'
      },
      itemData:{
        type: [Array, Object], required: false,default:''
      }
      
    },
    data() {
      return {
        languagesShow: false,
        displayLanguageSwitcher: !!this.$slots.mainMenuBarLanguages,
        currentLanguageAssign:this.currentLanguage,
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
        lastScrollPosition: 0,
        scrollOffset: 0,
      };
    },
  
    mounted() { 
      this.lastScrollPosition = window.pageYOffset;
      this.languagesAddBlurEvent();
      window.addEventListener('scroll', this.scrollEventHandle);
      if(document.getElementsByClassName('js-main-menu-language-new')){
        document.querySelector('.main-menu').classList.add('language__active');
      }
           
    },
    methods: { 
     languagesAddBlurEvent() {
        const slotLanguages = document.getElementsByClassName('js-main-menu-language-new');
        for (let i = 0; i < slotLanguages.length; i++) {
          slotLanguages[i].addEventListener('blur', this.languageOnBlur);
        }
      },
      scrollEventHandle() {
        if (window.pageYOffset < 0) {
          return;
        }
        if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
          return;
        }
        this.lastScrollPosition = window.pageYOffset;
      },

      /**
       * Setup event listener for scrolling (required for sticky bar)
       * @returns {undefined}
       */
      

      /**
       * Closes languages
       * @returns {undefined}
       */
      languagesClose() {
        this.languagesShow = false;
      },

      /**
       * Checks if any languages are still focused, if not closes the submenu
       * A11Y requirement
       * @returns {undefined}
       */
      languageOnBlur() {
        const slotLanguages = document.getElementsByClassName('js-main-menu-language-new');
        let focus = false;
        setTimeout(() => {
          if (document.activeElement === this.$refs.languageBtn) {
            focus = true;
          }

          if (!focus) {
            for (let i = 0; i < slotLanguages.length; i++) {
              if (document.activeElement === slotLanguages[i] && document.hasFocus()) {
                focus = true;
              }
            }
          }

          if (!focus) {
            this.languagesShow = false;
          }
        }, 10);
      },

      /**
       * Toggles the languages
       * @returns {undefined}
       */
      languagesToggle() {
        this.languagesShow =!this.languagesShow;
      },
    }
   
  
}
</script>
<style lang="scss" src="./language-switcher.scss"></style>