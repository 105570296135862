<template>
  <div class="ss-carousel-card">
    <a
      :href="articleItem.Link"
      class="ss-carousel-card__link"
      @click="tagging(articleItem.Title, articleItem.Link)"
    >
      <div class="ss-carousel-card__image">
        <img :src="articleItem.Image" :alt="articleItem.Title" loading="lazy" />
        <div class="ss-carousel-card__category" :class="[isRtl ? 'rtl-category' : '']">
          <svg
            class="ss-carousel-card__category--icon"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.2 16H5.8C4.80589 16 4 15.1941 4 14.2V4.6C4 4.26863 4.26863 4 4.6 4H13C13.3314 4 13.6 4.26863 13.6 4.6V11.8H16V14.2C16 15.1941 15.1941 16 14.2 16ZM13.6 13V14.2C13.6 14.5314 13.8686 14.8 14.2 14.8C14.5314 14.8 14.8 14.5314 14.8 14.2V13H13.6ZM12.4 14.8V5.2H5.2V14.2C5.2 14.5314 5.46863 14.8 5.8 14.8H12.4ZM6.4 7H11.2V8.2H6.4V7ZM6.4 9.4H11.2V10.6H6.4V9.4ZM6.4 11.8H9.4V13H6.4V11.8Z"
              fill="white"
            />
          </svg>
          {{ articleItem.PageType }}
        </div>
      </div>
      <div
        v-if="articleItem.Title"
        class="ss-carousel-card__title"
        v-html="truncate(articleItem.Title)"
      ></div>
      <div class="ss-carousel-card__read-text">
        <svg
          class="ss-carousel-card__read-text--icon"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="9.99976" cy="10" r="6.11654" stroke="#767676" stroke-width="1.35923" />
          <path d="M10 6.60193V10.5664H12.8317" stroke="#767676" stroke-width="1.35923" />
        </svg>
        {{ articleItem.Duration }}
      </div>
    </a>
  </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import { trimRichText } from '@Foundation/utilities';
import { uppercasepunctuation } from '@Foundation/utilities/uppercasepunctuation';

export default {
  name: 'safe-space-carousel-card',
  props: {
    articleItem: {
      type: Object,
      default: undefined,
    },
    titleLength: {
      type: Number,
      default: 50,
    },
    isTitleUppercase: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRtl() {
      let elem = document.getElementById('main-container');
      let style = getComputedStyle(elem);
      return style.direction === 'rtl';
    },
  },
  methods: {
    tagging(title, link) {
      const dataLayer = {
        event: 'uaevent',
        ecommerce: 'undefined',
        event_name: 'body_button_click',
        eventCategory: '',
        eventAction: '',
        eventLabel: '',
        cta_name: this.removeHtmlAndSpecialChars(title).toLowerCase(),
        link_url: `${window.location.origin + link}`,
        module_name: 'article carousel component',
      };

      AnalyticsHandler.pushDataLayer(dataLayer);
    },
    removeHtmlAndSpecialChars(inputString) {
      // Create a DOMParser to parse the inputString as HTML
      const parser = new DOMParser();
      const doc = parser.parseFromString(inputString, 'text/html');
      // Get the plain text without HTML tags
      const plainText = doc.body.textContent || '';
      return plainText.replaceAll('\n', '');
    },
    truncate(text) {
      const textTrim = trimRichText(text, this.titleLength);
      return this.isTitleUppercase ? uppercasepunctuation(textTrim.toLowerCase()) : textTrim;
    },
  },
};
</script>
<style lang="scss" src="./safe-space-carousel-card.scss"></style>
