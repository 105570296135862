<template> 
 <div id="accessibility-popup" class="accessibility-wrapper">
    <div class="modal--a11y accessibility-container" analytics="select::accessibility layer">
      <template> 
        <div role="heading" aria-level="1" id="" class="modal__heading">
            <div class="icon-acessibility">
              <img :src="iconSrc" :alt="iconAlt">
              <div>{{ title }}</div>
            </div>
            <div class="close-icon" @click="close()">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.9901 1L9 9.00016L17 17" stroke="white"/>
                <path d="M1.00994 1L9 9.00016L1 17" stroke="white"/>
              </svg>
            </div>
        </div>
        <div class="modal__body">
          <div class="fieldset" v-if="enableAnimation">
            <legend class="sub_title">{{animationLabel}}</legend>
            <div class="toggle_text">{{isAnimationMode ? enableToggleText : disableToggleText}}</div>
            <label class="switch">
              <input type="checkbox" @click="toggleAnimationCheckbox" id="animation">
              <div class="slider round"></div>
            </label>
          </div>
          <div class="fieldset" v-if="enableContrast">
            <legend class="sub_title">{{contrastLabel}}</legend>
            <div class="toggle_text">{{isContrastMode ? enableToggleText : disableToggleText}}</div>
            <label class="switch">
              <input type="checkbox" @click="toggleContrastCheckbox" id="contrast">
              <div class="slider round"></div>
            </label>
          </div>
        </div>
      </template>
    </div>
 </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
  name:"accessibility-modal-window",
  props: {
      title: {
        type: String, required: false, default: ''
      },
      iconSrc: {
        type: String, required: false, default: ''
      },
      iconAlt: {
        type: String, required: false, default: ''
      },
      animationLabel: {
        type: String, required: false, default: ''
      },
      contrastLabel: {
        type: String, required: false, default: ''
      },
      enableAnimation: {
        type: Boolean, required: false, default: ''
      },
      enableContrast: {
        type: Boolean, required: false, default: ''
      },
      enableToggleText: {
        type: String, required: false, default: ''
      },
      disableToggleText: {
        type: String, required: false, default: ''
      },
      pageCategory: {
        type: String, required: false, default: ''
      },
      eventLabel: {
        type: String, required: false, default: ''
      }
  },
  data(){
    return {
      isAnimationMode:true,
      isContrastMode:false
    }
  },
  mounted() {
    
     window.eventBus.$on('accessibility-click', payload => {
        if (payload !== 'header') {
          this.footerTagging();
        }
      });
    const userPreference = localStorage.getItem('contrastMode');
    this.isContrastMode = userPreference ? JSON.parse(userPreference) : this.isContrastMode;
    this.isContrastMode && document.getElementsByTagName('body')[0].classList.add('contrast-mode');
    const userAnimationPreference = localStorage.getItem('animationMode');
    this.isAnimationMode = userAnimationPreference ? JSON.parse(userAnimationPreference) : this.isAnimationMode;
    if(!this.isAnimationMode) {
      document.getElementsByTagName('body')[0].classList.add('not-animation-mode');
    }
    this.setContrastMode('isMount');
    this.$el.addEventListener('click', this.outsideClickListener);

  },
  methods: {
    close() {
      document.getElementById('accessibility-popup').classList.remove('show');
    },
    toggleAnimationCheckbox() {
      this.isAnimationMode = !this.isAnimationMode
      document.getElementsByTagName('body')[0].classList.toggle('not-animation-mode');
      this.setContrastMode('animation');
      this.tagging(this.isAnimationMode);
    },
    toggleContrastCheckbox() {
      this.isContrastMode = !this.isContrastMode;
      document.getElementsByTagName('body')[0].classList.toggle('contrast-mode');
      this.setContrastMode('contrast');
      this.tagging(this.isContrastMode);
    },
    setContrastMode(value) {
      if(value == 'animation' || value == 'isMount') {
        this.$el.querySelectorAll('#animation')[0].checked = this.isAnimationMode;
        document.cookie = "animationMode="+this.isAnimationMode+";samesite=strict"
        localStorage.setItem('animationMode', this.isAnimationMode);
      }
      if(value == 'contrast' || value == 'isMount'){
        this.$el.querySelectorAll('#contrast')[0].checked = this.isContrastMode;
		    document.cookie = "contrastMode="+this.isContrastMode+";samesite=strict"
        localStorage.setItem('contrastMode', this.isContrastMode);
      }
    },
    tagging(data) {
      let ctaname = data == true ? this.enableToggleText : this.disableToggleText
      AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': undefined,
          'event_name': 'body_button_click',
          'eventCategory': this.pageCategory.toLowerCase(),
          'eventAction': 'select::'+this.title.toLowerCase(),
          'eventLabel': ctaname.toLowerCase()+'::none',
          'cta_name': ctaname.toLowerCase(),
          'link_url': 'none',
          'module_name': this.title.toLowerCase(),
      });
    },
    footerTagging(){
       AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': undefined,
          'event_name': 'menu_click',
          'eventCategory': 'main menu navigation',
          'eventAction': 'select::footer',
          'eventLabel': this.eventLabel.toLowerCase(),
          'click_area': 'footer',
          'breadcrumb': this.eventLabel.toLowerCase()
       });
    },
    outsideClickListener(event) {
        if(!event.target.closest('.accessibility-container')){
        this.close() 
      }
    }
  },
  destroyed() {
    this.$el.removeEventListener('click', this.outsideClickListener);
  }
  
}
</script>

<style lang="scss" src="./accessibility.scss"></style>