export function productsMapper(products) {
  console.log(products.map(productMapper));
  return products.map(productMapper);
}

export function productMapper(product, index) {
  const {
    ProductID,
    ProductItemID,
    ProductUrl,
    ProductName,
    ProductNameHtml,
    MainImage,
    Brand,
    ProductList,
    ShortDescription,
    Currency,
    Category,
    Dimension35 = '',
    Dimension36 = '',
    Dimension37 = '',
    Dimension38 = '',
    Dimension39 = '',
    Dimension40 = '',
    StarRatingText,
    AverageRating,
    InactiveColor,
    ActiveColor,
    IsRatingEnabled,
    isBazaarvoiceActivated
  } = product;

  return {
    productId: ProductID,
    productItemId: ProductItemID,
    productName: ProductName,
    productNameHtml: ProductNameHtml,
    productUrl: ProductUrl,
    starRatingText: StarRatingText,
    averageRating: AverageRating,
    inactiveColor: InactiveColor,
    activeColor: ActiveColor,
    isRatingEnabled: IsRatingEnabled,
    IsBazaarvoiceActivated:isBazaarvoiceActivated,

    packshot: {
      src: MainImage,
      alt: ''
    },

    productShortDescription: ShortDescription,

    tagging: {
      productId: ProductID,
      productName: ProductName,
      productPosition: index,
      productBrand: Brand,
      productList: ProductList,
      productCurrency: Currency,
      productCategory: Category,
      productDimension35: Dimension35,
      productDimension36: Dimension36,
      productDimension37: Dimension37,
      productDimension38: Dimension38,
      productDimension39: Dimension39,
      productDimension40: Dimension40
    }
  };
}
