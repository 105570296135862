<template>
  <div class="success-modal-popin" v-if="showModal">
    <div class="modal__backface" @click="closeModal"/>
    <div class="modal__wrapper modal_popup">
      <button class="modal__close-btn success-modal-close"
              @click="closeModal"
              @keyup.esc="closeModal"
              type="button">
        <div class="btn_close close" id="btn_close"/>
      </button>
      <slot name="title"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'success-modal',
    data() {
      return {
        showModal: false,
      };
    },
    created() {
      window.eventBus.$on('clickandEnabledPopup', payload => {
        this.showModal = payload;
      });
    },
    methods: {
      closeModal() {
        this.showModal = false;
      },
    }
  };
</script>

<style lang="scss" src="./success-modal.scss"></style>
