import { VueModule } from '@Foundation/utilities';
import SafeSpaceCarousel from './code/Scripts/components/carousel/safe-space-carousel';
import SafeSpaceCarouselCard from './code/Scripts/components/carousel-card/safe-space-carousel-card';

export default VueModule({
  components: {
    SafeSpaceCarousel,
    SafeSpaceCarouselCard,
  },
});
