<template>
  <li class="select-option__item" :class="{'-checked': isChecked}" @click.prevent="setSelection">
    <input type="checkbox"
           :id="uniqueId"
           v-model="isChecked"
           :disabled="isDisabled"
           class="select-option__input"
           panel-focusable>
    <label :for="uniqueId" ref="text" class="select-option__label">
      <span><slot/></span>
    </label>
  </li>
</template>
<script>
  import { randomId } from '@Foundation/utilities/randomId';

  export default {
    name: 'select-option',

    props: {
      value: {
        type: [String, Number, Boolean],
        default: undefined,
        required: false
      },
      disabled: {
        type: Boolean,
        default: false,
        required: false
      }
    },

    inject: {
      selectMultiple: {}
    },

    data() {
      return {
        uniqueId: 'select-option-' + randomId(),
        isChecked: false,
        isSelected: false
      };
    },

    computed: {

      selectValue() {
        return this.selectMultiple.modelValue;
      },

      isDisabled() {
        return this.disabled;
      },

      key() {
        const isSet = (this.value || this.value === 0);
        return isSet ? this.value : this.uniqueId;
      }

    },
    watch: {

      selectValue() {
        this.setIsSelected();
      },

      isChecked(val) {
        if (val === this.isSelected) {
          return;
        }
        //this.setSelection();
      },

      isSelected(val) {
        this.isChecked = val;
      }

    },

    created() {
      this.setItem();
      this.setIsSelected();
    },

    updated() {
      this.setItem();
    },

    methods: {

      getTextContent() {
        if (this.$el) {
          return this.$el.textContent.trim();
        }
        const slot = this.$slots.default;
        return slot ? slot[0].text.trim() : '';
      },

      setIsSelected() {
        if (this.selectValue === undefined) {
          this.isSelected = false;
          return;
        }

        this.isSelected = this.selectValue.includes(this.value);
      },

      setItem() {
        this.$set(this.selectMultiple.items, this.key, this.getTextContent());
      },

      setMultipleSelection() {
        this.selectMultiple.setMultipleValue(this.value);
      },

      setSelection() {
         window.eventBus.$emit('quicksortTrigger', false);
        if (!this.isDisabled) {
          this.setMultipleSelection();
        }
      }

    }
  };
</script>
<style lang="scss" src="./select-option.scss"/>
