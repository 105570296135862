var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showJump),expression:"showJump"}],ref:"relSticky",class:{ relsticky: !_vm.active, 'header-active': _vm.headerActive }},[_c('nav',{ref:"sticky",staticClass:"jump-navbar",class:{ 'stickyblock': _vm.active, 'mobile-sticky': _vm.listItemActive },attrs:{"role":"navigation"}},[_c('div',{staticClass:"jump-content"},[(!_vm.mobileScreen)?_c('swiper',{ref:"swiper",staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_c('div',{staticClass:"swiper-button-prev",class:{ 'swiper-button-disabled': _vm.swiperAtBegain },attrs:{"slot":"button-prev"},on:{"click":function($event){return _vm.prev()}},slot:"button-prev"}),_vm._v(" "),_vm._l((_vm.Navlinks),function(item,index){return _c('swiper-slide',{directives:[{name:"tag",rawName:"v-tag:useractionevent",value:({
            action: `select::${index + 1}::${item.title.toLowerCase()}`,
            category: _vm.pageCategory,
            label: _vm.articleName(),
            event_name: 'body_button_click',
            module_name: 'article anchor click',
            cta_name: `${index + 1}::${item.title.toLowerCase()}`,
            link_url: _vm.location,
            article_name: _vm.articleName()
          }),expression:"{\n            action: `select::${index + 1}::${item.title.toLowerCase()}`,\n            category: pageCategory,\n            label: articleName(),\n            event_name: 'body_button_click',\n            module_name: 'article anchor click',\n            cta_name: `${index + 1}::${item.title.toLowerCase()}`,\n            link_url: location,\n            article_name: articleName()\n          }",arg:"useractionevent"}],key:index + 0,class:{ 'swiper-highligher': _vm.currentLink === index },attrs:{"id":item.target,"data-index":index},nativeOn:{"click":function($event){return _vm.slideClicked($event, index)}}},[_c('h3',{staticClass:"is-4"},[_c('span'),_vm._v(" "),_c('div',{class:_vm.checkStringCountText(item.title),domProps:{"innerHTML":_vm._s(item.title)}})])])}),_vm._v(" "),_c('div',{staticClass:"swiper-button-next",class:{ 'swiper-button-disabled': _vm.swiperAtEnd },attrs:{"slot":"button-next"},on:{"click":function($event){return _vm.next()}},slot:"button-next"})],2):_vm._e(),_vm._v(" "),(_vm.mobileScreen)?_c('div',{staticClass:"swiper-mobile",class:{ 'swiper-arrow-active': _vm.listItemActive }},[_c('div',{staticClass:"swiper-mobile-wrapper"}),_vm._v(" "),_c('div',{staticClass:"select-custom",on:{"click":function($event){return _vm.activeListItem(_vm.listItemActive)}}},[_c('button',{staticClass:"select-custom__btn",attrs:{"id":"current-volume","aria-label":"current-volume","aria-expanded":"false"}},[_c('svg',{attrs:{"width":"16","height":"8","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"nonzero","d":"M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"}})])])]),_vm._v(" "),_c('swiper',{directives:[{name:"show",rawName:"v-show",value:(_vm.listItemActive),expression:"listItemActive"}],ref:"swiper",staticClass:"swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.Navlinks),function(item,index){return _c('swiper-slide',{directives:[{name:"tag",rawName:"v-tag:useractionevent",value:({
              action: `select::${index + 1}::${item.title.toLowerCase()}`,
              category: _vm.pageCategory,
              label: _vm.articleName(),
              event_name: 'body_button_click',
              module_name: 'article anchor click',
              cta_name: `${index + 1}::${item.title.toLowerCase()}`,
              link_url: _vm.location,
              article_name: _vm.articleName()
            }),expression:"{\n              action: `select::${index + 1}::${item.title.toLowerCase()}`,\n              category: pageCategory,\n              label: articleName(),\n              event_name: 'body_button_click',\n              module_name: 'article anchor click',\n              cta_name: `${index + 1}::${item.title.toLowerCase()}`,\n              link_url: location,\n              article_name: articleName()\n            }",arg:"useractionevent"}],key:index + 0,class:{ 'swiper-highligher swiper-highligher-blue ': _vm.currentLink === index },attrs:{"id":item.target,"data-index":index},nativeOn:{"click":function($event){return _vm.slideClicked($event, index, true)}}},[_c('h3',{staticClass:"is-4"},[_c('span'),_vm._v(" "),_c('div',{class:_vm.checkStringCountText(item.title),domProps:{"innerHTML":_vm._s(item.title)}})])])}),1)],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }