var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"field field--select"},[_c('div',{staticClass:"control"},[_c('div',{staticClass:"select-custom",class:{
        'disabled': _vm.isDisabled
      }},[(!!_vm.v && !_vm.customValidation)?[_c('div',{staticClass:"select-custom__btn",class:{
            'has-error': _vm.v.$error
          }},[_c('label',{staticClass:"placeholder",class:{
              'is-fixed': _vm.inputValue !== ''
            },attrs:{"for":_vm.field.id}},[_vm._v(_vm._s(_vm.field.label))]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model.trim.lazy",value:(_vm.inputValue),expression:"inputValue",modifiers:{"trim":true,"lazy":true}}],attrs:{"aria-describedby":_vm.fieldDescribedby,"id":_vm.field.id,"name":_vm.field.id,"disabled":_vm.isDisabled,"required":_vm.v.required},on:{"blur":function($event){return _vm.v.$touch()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.inputValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.field.listValue),function(option,index){return _c('option',{key:index,domProps:{"value":option,"selected":option === _vm.inputValue ? 'true' : 'false'}},[_vm._v("\n              "+_vm._s(option)+"\n            ")])}),0),_vm._v(" "),_c('svg',{attrs:{"width":"16","height":"8","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"nonzero","d":"M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"}})])])]:(_vm.customValidation)?[_c('div',{staticClass:"select-custom__btn",class:{
            'has-error': _vm.v.error
          }},[_c('label',{staticClass:"placeholder",class:{
              'is-fixed': _vm.inputValue !== ''
            },attrs:{"for":_vm.field.id}},[_vm._v(_vm._s(_vm.field.label))]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model.trim.lazy",value:(_vm.inputValue),expression:"inputValue",modifiers:{"trim":true,"lazy":true}}],attrs:{"aria-describedby":_vm.fieldDescribedby,"id":_vm.field.id,"name":_vm.field.id,"disabled":_vm.isDisabled,"required":_vm.v.required},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.inputValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.field.listValue),function(option,index){return _c('option',{key:index,domProps:{"value":option,"selected":option === _vm.inputValue ? 'true' : 'false'}},[_vm._v("\n              "+_vm._s(option)+"\n            ")])}),0),_vm._v(" "),_c('svg',{attrs:{"width":"16","height":"8","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"nonzero","d":"M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"}})])])]:[_c('div',{staticClass:"select-custom__btn"},[_c('label',{staticClass:"placeholder",class:{
              'is-fixed': _vm.inputValue !== ''
            },attrs:{"for":_vm.field.id}},[_vm._v(_vm._s(_vm.field.label))]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model.trim.lazy",value:(_vm.inputValue),expression:"inputValue",modifiers:{"trim":true,"lazy":true}}],attrs:{"id":_vm.field.id,"name":_vm.field.id,"disabled":_vm.isDisabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.inputValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.field.listValue),function(option,index){return _c('option',{key:index,domProps:{"value":option,"selected":option === _vm.inputValue ? 'true' : 'false'}},[_vm._v("\n              "+_vm._s(option)+"\n            ")])}),0),_vm._v(" "),_c('svg',{attrs:{"width":"16","height":"8","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"nonzero","d":"M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"}})])])]],2)]),_vm._v(" "),(!!_vm.v)?[(_vm.v.$error)?_c('p',{staticClass:"field--error",attrs:{"id":_vm.field.idError}},_vm._l((_vm.field.errors),function(error,index){return _c('field-help',{key:index,attrs:{"v":_vm.v,"error":error}})}),1):_vm._e(),_vm._v(" "),(_vm.v.error)?_c('p',{staticClass:"field--error",attrs:{"id":_vm.field.idError}},_vm._l((_vm.field.errors),function(error,index){return _c('field-help',{key:index,attrs:{"v":_vm.v,"error":error}})}),1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }