<template>
  <fieldset class="volume-variant-v2 volume-variant1"  :class="[volumes.length == 1 ? 'singleVolume' :'' ]">
    <legend class="is-sr-only" id="legend-volume">{{ legendName }}</legend>
    <div class="productItemId">{{productid.length >= 1 ? productid[volumeIndex].id : '' }}</div>
    <div class="productItemActiveId">{{ sfccvolumesdetails.lenght >= 1 ? sfccvolumesdetails[volumeIndex].ean : '' }}</div>
    <div class="volume-variant__wrapper" v-if="volumes.length > 1">

      <div
        class="select-custom"
        v-clickoutside="DropdownClose">

        <button
          class="select-custom__btn"
          :class="{
            'is-active': isDropdownOpened
          }"
          id="current-volume"
          :aria-labelby="a11yAriaLabel"
          :aria-expanded="isDropdownOpened ? 'true' : 'false'"
          @click.prevent="dropdownToggle()"
          @blur="dropdownOnBlur(activeVolume)"
          ref="dropdownBtn">
          <span>{{ activeVolume }}</span>
          <svg width="16" height="8" aria-hidden="true">
            <path fill-rule="nonzero" d="M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"/>
          </svg>
        </button>

        <transition name="main-menu-languages">
          <ul
            v-show="isDropdownOpened"
            @keyup.esc="dropdownToggle()">
            <li v-for="(item,index) in volumes" :key="item.id" @click="dropdownOnBlur(item.size), setAnalytics(item.tagging)">
              <label
                :for="`volumes-shade-${index}`"
                :id="item.id"
                @click="DropdownClose(),selectedSize(item.variantEANId), selectedVariant(item.variantEANId)"
                 class="volume-variant__label"
                :key="index">
                {{ item.size }}
              </label>
            </li>
          </ul>
        </transition>
      </div>

    </div>
    <p v-else class="volume-variant__single-el">{{ volumes.length >= 1 ?  volumes[0].size : ""}}
      <ul style="display:none">
        <li v-for="item in volumes" :key="item.id">
          <input
            type="radio"
            name="volume"
            checked
            class="js-select-option"
            :id="item.id"
          >
        </li>
      </ul>
    </p>
  </fieldset>
</template>
<script>
  import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';

  export default {
    name: 'volume-variant-v2',
    directives: {
      clickoutside,
    },
    props: {
      productid: { type: Array, required: true },
      volumes: { type: Array, required: true },
      legendName: { type: String, required: true, default: 'Volume' },
      a11yAriaLabel: { type: String, required: false, default: 'legend-volume current-volume' },
      sfccvolumesdetails: { type: Array, required: true },
      popupDropdown: { type: Boolean,require: false,default: false }
    },

    data() {
      return {
        isDropdownOpened: false,
        activeVolume: this.volumes.length >= 1 ?  this.volumes[0].size : "",
        volumeIndex: 0,
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      };
    },

    mounted() {
      window.eventBus.$on('showVolumeValue', payload => {
        if (payload) {
          this.activeVolume = payload;
        }
      });
      this.dropdownOnBlur(this.activeVolume);
    },

    methods: {
      DropdownClose() {
        /* istanbul ignore else */
        if (this.isDropdownOpened) {
          this.isDropdownOpened = false;
        }
      },

      setAnalytics(tagging) {
        AnalyticsHandler.pushDataLayer(tagging);
      },
      selectedVariant(variant){     
        window.eventBus.$emit('selectedVarientSize', variant);
      },
      selectedSize(variant){
        window.eventBus.$emit('SelectVarientPdpChangedAction', variant);       
      },
      dropdownToggle() {
        if (!this.isDropdownOpened) {
          setTimeout(() => {
            this.isDropdownOpened = true;
            setTimeout(() => {
              /* istanbul ignore else */
              if (this.$el.querySelector('input[type="radio"]:checked')) {
                this.$el.querySelector('input[type="radio"]:checked').focus();
              }
            }, 50);
          }, 100);
        } else {
          this.DropdownClose();
        }
      },

      dropdownOnBlur(data) {
        setTimeout(() => {
          let activeElementClass;

          /* istanbul ignore next */
          if (document.documentMode || /Edge/.test(navigator.userAgent)) {
            /* eslint-disable prefer-destructuring */
            activeElementClass = document.activeElement.classList[0] || null;
          } else {
            activeElementClass = document.activeElement.classList.value;
            this.activeVolume = data;
            window.eventBus.$emit('showVolumeValue', data);
            this.volumes.forEach((element,index)=>{
              if (data === element.size) {
                this.volumeIndex = index;
              }
            });

          }
          /* istanbul ignore else */
          if (!(activeElementClass === 'js-select-option') && this.isDropdownOpened === true) {
            this.DropdownClose();
          }
        }, 300);
      },
    },
  };
</script>
<style lang='scss' src="./volume-variant-v2.scss"></style>
