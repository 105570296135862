<template>
  <div class="safe_space_concerns">
    <div
      v-if="getFilterConcerns && getFilterConcerns.length && showConcerns"
      class="safe_space_concerns__container"
    >
      <div v-for="(concern, index) in getFilterConcerns" :key="index + 1">
        <safe-space-articles
          :safe-space-title="concern.Title"
          :highlighted-article="concern.HighlightedItems"
          :article-list="concern.ArticleItems"
          :count-text="concern.CountText"
          :is-concern-data="true"
          :is-title-uppercase="isTitleUppercase"
        >
        </safe-space-articles>
      </div>
    </div>
    <div v-if="!getNoMoreConcerns" class="loader-container">
      <div ref="loadMore" class="loadMore-trigger"></div>
      <transition name="fade">
        <div v-show="concernLoading" class="loader-css">Loading</div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { QueryToObject } from '../../services/query-to-object';
import SafeSpaceArticles from '@Feature/SafeSpaceArticles/code/Scripts/components/articles/safe-space-articles.vue';

export default {
  name: 'safe-space-concerns',
  components: {
    SafeSpaceArticles,
  },
  props: {
    concernList: {
      type: Array,
      default: undefined,
    },
    isTitleUppercase: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stop: false,
      filterSelected: false,
      listArticleItem: this.concernList && this.concernList.length ? this.concernList : [],
      showConcerns: true,
    };
  },
  computed: {
    ...mapGetters(['getFilterConcerns']),
    ...mapGetters(['getNoMoreConcerns', 'getTotalConcernsCount', 'concernLoading']),
    isFilterEnabled() {
      let isFitlerEnabled = false;
      let queries = QueryToObject(window.location.search);
      let queryParamKeys = Object.keys(queries);
      for (let i = 0; i < queryParamKeys.length; i++) {
        if (queries[queryParamKeys[i].length]) {
          isFitlerEnabled = true;
          break;
        }
      }
      return isFitlerEnabled;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.getInitialConcernData();
    window.eventBus.$on('safeSpaceFilterSelected', (val) => {
      this.filterSelected = !val;
      this.showConcerns = false;
      this.getInitialConcernData();
    });
  },
  methods: {
    async handleScroll() {
      const payload = {
        isFilter: false,
        filterId: '',
      };
      const rect = this.$refs.loadMore.getBoundingClientRect();
      if (rect.top <= window.innerHeight && !this.stop && !this.getNoMoreConcerns && !this.filterSelected) {
        this.stop = true;
        this.getFilterConcerns.length >= 3 && await this.$store.dispatch('fetchConcerns', payload);
        this.stop = false;
      }
    },
    getInitialConcernData() {
      if (this.listArticleItem) {
        this.$nextTick(() => {
          if (!this.filterSelected) {
            this.$store.dispatch('storeInitialConcernData', this.listArticleItem);
            this.$store.dispatch('storeTotalConcernCount', this.listArticleItem.length);
          }
          this.showConcerns = true;
        });
      }
    },
  },
};
</script>
<style lang="scss" src="./safe-space-concerns.scss"></style>
