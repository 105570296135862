<template>
  <div class="products-filters" v-show="showFilter">
    <div class="products-filters__wrapper">
      <div class="products-filters-sticky">
        <div class="products-filters__container">
        
          <div class="products-filters__select-wrapper">
            <button class="products-filters__close" @click="closeFilter">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.9636L12.9636 -1.50258e-06L15.4385 2.47487L2.47487 15.4385L0 12.9636Z" fill="black"/>
              <rect x="2.47485" width="18.3333" height="3.5" transform="rotate(45 2.47485 0)" fill="black"/>
              </svg>
            </button>
            <slot/>
            
          </div>
        </div>
        <loader class="products-filters__loader-wrapper" :show="productsLoading">
          <slot name="loading" />
        </loader>
      </div>
      <div class="products-filters__cta-wrapper">
         <slot name="viewresult" :fetch-products="fetchProducts" v-if="viewresults || getFilterSortCta"/>

          <slot name="clear" :clear-filters="clearFiltersProducts" v-if="viewresults || getFilterSortCta"/>

          <slot name="close" :close-filters="closeFilter" v-else/>

       </div>
    </div>
  </div>
</template>

<script>
import Loader from '@Feature/Loader/code/Scripts/components/loader';
import { sticky } from '@Foundation/directives/sticky/sticky';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'product-filters',
  components: {
    Loader,
  },

  directives: {
    sticky
  },

  data() {
    return {
      showFilter: false,
      containerElement: null
    }
  },

  computed: {
    ...mapGetters(['productsLoading','getFilterSortCta']),
    viewresults() {
      let filters = this.$store.getters.getToltalFilter;
      let count = 0;
      Object.keys(filters).forEach((key,ele) => {
        if(key != "sortBy" && filters[key].length >= 1 ) {
          count = count + 1;
        }
      });
      return ( (count >= 1) || this.$store.getters.getSortBy);
    },
  },
  props: {
    taggingCategory: {
        type: String,
        required: false,
        default: undefined
    },
    isSortAvailable :{
      default: true,
      type: Boolean
    }
  },
  beforeMount() {
    window.eventBus.$on('toggleProductFilter', () => {
      setTimeout(() => {
        this.handleClassOnScroll();
      }, 500)
    });

    document.addEventListener('click', (e) => {
      if(e.target.className == 'products-filters' || e.target.className == 'products-filters noSort') {  
        let body = document.getElementsByTagName("body");
        body && body[0].classList.remove("filterPopup");
        this.showFilter = false;
      }
    })
  },

  mounted() {
    this.popHistoryListener = (event) => this.setState(event.state);
    window.addEventListener('popstate', this.popHistoryListener);
    window.eventBus.$on('openFilterModel', payload => {
      if (payload) {
        this.showFilter = payload;
      }
    });

    this.containerElement = document.querySelector('.products-filters__select-wrapper');
  },

  destroyed() {
    window.removeEventListener('popstate', this.popHistoryListener);
  },

  methods: {
    gaTagging(){
      let filters = this.$store.getters.getToltalFilter;
      let sortBy = this.$store.getters.getSortBy;
      let data  = "";
      let taggingEventAction = "";
      let sortEventAction;
      let filterEventAction;
      if(sortBy != '') {
        data +=  "sort by::" + sortBy.toLowerCase() + "-//-" ;
        sortEventAction = true;
      }
      for(let itemName in filters) {
        let filterName;
        if(filters[itemName].length > 0 && itemName != "sortBy") {
            filterName = itemName.toLowerCase();
            filters[itemName].forEach((filterValue,index) => {
            filterName += "::" + filterValue.toLowerCase();
          });
          data +=  filterName + "-//-" ;
          filterEventAction = true;
        }
      }
      if(filterEventAction && sortEventAction) {
        taggingEventAction = 'filter-//-sort';
      }else if(filterEventAction) {
        taggingEventAction = 'filter'
      }else {
        taggingEventAction = 'sort'
      }
      let selectedFilter = data.slice(0,-4);
      window.dataLayer.push({
        event:	'uaevent',
        ecommerce: 'undefined',
        eventLabel: selectedFilter,
        eventAction:	taggingEventAction,
        eventCategory:	this.taggingCategory,
        event_name: 'use_filters',
        filter_type : taggingEventAction,
        filter_value : selectedFilter
      });  
      this.eventAction =[];
   },
    handleClassOnScroll() {
      let addonElem = document.querySelector('.products-filters__wrapper');
      if(this.containerElement) {
        if((this.containerElement.scrollHeight == 0 && this.containerElement.clientHeight == 0) || (this.containerElement.scrollHeight == this.containerElement.clientHeight)) {
          !addonElem.classList.contains('overflow') && addonElem.classList.add('overflow')
        }
        if(this.containerElement.scrollHeight > this.containerElement.clientHeight) {
          addonElem.classList.contains('overflow') && addonElem.classList.remove('overflow')
        } 
      } 
    },

    async fetchProducts () {
      this.gaTagging();
      this.$store.commit('filterSortCta',true);
      await this.$store.dispatch('fetchFilterProducts',true);
      this.showFilter = !this.showFilter;
      window.eventBus.$emit('quicksort');
      window.eventBus.$emit('closefilter',this.showFilter);
      let body = document.getElementsByTagName("body");
      if(body) {
        body[0].classList.remove("filterPopup");
      }
    },  
    
    closeFilter () {
      this.showFilter = !this.showFilter;
      //window.eventBus.$emit('quicksort');
      window.eventBus.$emit('closefilter',this.showFilter);
      let body = document.getElementsByTagName("body");
      if(body) {
        body[0].classList.remove("filterPopup");
      }
    },
    async clearFiltersProducts () {
      await this.$store.dispatch('clearFiltersProducts',true);
      window.eventBus.$emit('quicksort');
    },
    ...mapActions(['setState', 'formHistory'])
  },

  watch: {
    showFilter: {
      handler() {
        this.showFilter && this.handleClassOnScroll()
      }
    }
  }
};
</script>
<style lang="scss" src="./product-filters.scss"></style>
