import { AnalyticsProductImpressionObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';
import ProductObject from './ProductObject';

export default class ProductImpressionObject extends DataObject {
  constructor(data) {
    super('nievent', data);

    this.category = 'Ecommerce';
    if (data.category) {
      Logger.warn(
        `[AnalyticsProductImpressionObjectException] Category always defaults to "Ecommerce" (L'Oréal guideline), currently [${
          data.category
        }] was provided`,
        data,
        false
      );
    }

    this.action = 'Product Impressions';
    if (data.action) {
      Logger.warn(
        `[AnalyticsProductImpressionObjectException] Action always defaults to "Product Impressions" (L'Oréal guideline), currently [${
          data.action
        }] was provided`,
        data,
        false
      );
    }

    this.action = 'Product Impressions';
    if (data.label) {
      this.label = data.label;
    }

    if (!data.products || data.products.length === 0) {
      Logger.error('[AnalyticsProductImpressionObjectException] Product(s) are required for a productImpression', data);
      throw new AnalyticsProductImpressionObjectException('Product(s) are required for a productImpression');
    }
  }

  initEcommerceProductImp() {
    this.result.ecommerce = this.result.ecommerce || {};
    this.result.ecommerce.impressions = this.result.ecommerce.impressions || {};
    this.result.event_name = 'view_item_list' || {};

  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category, false);
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action, false);
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label, false);
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set currency(currency) {
    this.initEcommerceProductImp();
    this.result.ecommerce.currencyCode = (currency === 'none') ? this.setProperty(currency):this.setProperty(currency).toUpperCase();
  }

  set products(products) {
    this.initEcommerceProductImp();
    this.result.ecommerce.impressions = products.map(product => new ProductObject(product).toObject());
  }

  get products() {
    return (this.result.ecommerce && this.result.ecommerce.impressions) || [];
  }
}
