<template>
  <div/>
</template>
<script>
  export default {
    name: 'article-tagging',
    props: {
      dataAritlcleTitle: { type: String, required: false },
    },
    data() {
      return {
        taggingCount: 0,
        passedTimer: false,
        articleHeight: 0,
        distFromTop: 0, 
      };
    },
    created() {
      const tagTimer = setTimeout(()=> { this.passedTimer = true }, 30000);
      const cookiename = 'article_tagging';
      const value = '200';
      var date = new Date();
      date.setTime(date.getTime() + (30 * 1000));
      document.cookie = cookiename + '=' + value + '; expires=' + date.toString() + '; path=/; SameSite=Strict';
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll());
    },
    mounted() {
      const sessionTimer = this.getCookie('article_tagging');
      document.addEventListener("DOMContentLoaded", () => {    
        this.distFromTop = document.querySelector('html').scrollTop;
        this.articleHeight = document.querySelector('#content').clientHeight; 
              window.addEventListener('scroll', () => {  
                this.scrollToTop();
                this.handleScroll();
            });
        });
  },
    methods: {
      getCookie(cookieName) {
        var name = cookieName + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      },
      scrollToTop() {
       this.distFromTop = document.querySelector('html').scrollTop;
      },
      viewedArticlePercentage() {
        return (this.distFromTop*100)/this.articleHeight
      },
      handleScroll() {
        if (this.dataAritlcleTitle) {
           if ((this.viewedArticlePercentage() >= 75) && (this.taggingCount < 1)&&(this.passedTimer)) {
                window.dataLayer.push({
                    'event': 'uaevent',
                    'event_name':'read_article',
                    'ecommerce': 'undefined',
                    'eventCategory': 'content page::article',
                    'eventAction': 'read article',
                    'eventLabel': this.dataAritlcleTitle,
                    'article_name': this.dataAritlcleTitle
                });
                this.taggingCount++;
                window.removeEventListener('scroll', this.handleScroll());
            }
        }
      }
  },
}
</script>
