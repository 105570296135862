import { srcset } from '../srcset/srcset';

export * from '../srcset/srcset';

/**
 *
 * @deprecated Use srcset instead
 * @alias srcset
 * @type {{name, bind, unbind}}
 */
export const background = {
  ...srcset,
  name: 'background'
};
