<template>
  <div @click.prevent="() => toggleContrastMode()"
       :class="isContrastMode && 'on'"
       class="footer_contrast_switcher_block">
    <slot />
  </div>
</template>
<script>
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';
  export default {
    name: 'contrast-switcher-button',
    props: {
      styleLinkId: { type: String, required: true, default: undefined },
      regularStyleBundle: { type: String, required: true, default: undefined },
      contrastStyleBundle: { type: String, required: true, default: undefined },
    },
    data() {
      return {
        isContrastMode: false
      };
    },
    mounted() {
      const userPreference = localStorage.getItem('contrastMode');
      this.isContrastMode = userPreference ? JSON.parse(userPreference) : this.$el.querySelectorAll('input')[0].checked;
      this.isContrastMode && document.getElementsByTagName('body')[0].classList.add('contrast-mode');
      this.$el.querySelectorAll('input')[0].classList.add(this.isContrastMode ? 'on' : 'off');
      this.setContrastMode(true);
    },
    methods: {
      toggleContrastMode() {
        this.isContrastMode = !this.isContrastMode;
        document.getElementsByTagName('body')[0].classList.toggle('contrast-mode');
        this.$el.querySelectorAll('input')[0].classList.toggle('on');
        this.$el.querySelectorAll('input')[0].classList.toggle('off');
        this.setContrastMode();
      },
      setContrastMode(onMount) {
        this.$el.querySelectorAll('input')[0].checked = this.isContrastMode;
        const preferredStylesMode = this.isContrastMode ? this.contrastStyleBundle : this.regularStyleBundle;
        /* istanbul ignore else */
        const styleLink = document.getElementById(this.styleLinkId);
        if (styleLink?.href && styleLink.href !== preferredStylesMode) { // prevent default styles override upon load
          styleLink.href = preferredStylesMode;
          !onMount && AnalyticsHandler.pushDataLayer({
            'event': 'uaevent',
            'ecommerce': 'undefined',
            'eventCategory': 'footer',
            'eventAction': 'select::high contrast mode',
            'eventLabel': this.isContrastMode ? 'enable' : 'unable'
          });
        }

		document.cookie = "contrastMode="+this.isContrastMode+";samesite=strict"
        localStorage.setItem('contrastMode', this.isContrastMode);
      }
    },
  };
</script>
