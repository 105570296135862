<template>
  <div class="select-multiple">
    <panel class="panel--field" ref="dropdown">

      <button slot="toggleButton"
              slot-scope="scope"
              class="select-multiple__toggle"
              @click="scope.panelToggler"
              :class="{ '-is-selected': localValue && localValue.length }"
              :aria-expanded="scope.expanded ? 'true': 'false'">
        {{ placeholder }}<span>{{count}}</span>
      </button>

      <fieldset class="select-multiple__fieldset" v-clickoutside="closeDropdown">
        <legend class="select-multiple__label">{{ placeholder }}</legend>

        <ul class="select-multiple__list">
          <slot/>
        </ul>
      </fieldset>
    </panel>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Panel from '@Feature/Panel/code/Scripts/components/panel.vue';
  import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';

  export default {
    name: 'select-multiple',

    components: {
      Panel
    },
    directives: {
      clickoutside
    },

    props: {
      value: {
        type: undefined,
        required: false,
        default: undefined
      },
      placeholder: {
        type: String,
        required: true
      },
      count: {
        type: String,
        required: false
      }
    },

    data() {
      return {
        localValue: this.value,
        selectMultiple: {
          items: {},
          label: null,
          modelValue: this.localValue,
          setMultipleValue: this.setMultipleValue
        }
      };
    },

    provide() {
      const selectMultiple = this.selectMultiple;
      return { selectMultiple };
    },

    watch: {
      value(val) {
        this.localValue = val;
      },
      localValue: {
        immediate: true,
        handler(val) {
          this.$emit('input', val);
          this.selectMultiple.modelValue = this.localValue;
        }
      }
    },

    mounted() {
      if (!this.localValue) {
        this.initialLocalValueByDefault();
      }
    },

    methods: {
      closeDropdown() {
        //Prevent the initial calls in looping from select-multiple component .cshtml file.
        setTimeout(() => {
          if(this.getDropdownRefState) {
            return;
          }
        this.$refs.dropdown.closePanel();
        }, 50)
      },

      setMultipleValue(value) {
        this.toggleArrayValue(value);
      },

      toggleArrayValue(value) {
        const index = this.localValue.indexOf(value);
        const includes = index > -1;
        if (!includes) {
          this.localValue = this.localValue.concat([value]);
        } else {
          this.localValue = this.arrayAccessorRemove(this.localValue, index);
        }
      },

      arrayAccessorRemove(arr, index) {
        const before = arr.slice(0, index);
        const after = arr.slice(index + 1, arr.length);
        return before.concat(after);
      },

      initialLocalValueByDefault() {
        this.localValue = this.setLocalValueIfMultiple();
      },

      setLocalValueIfMultiple() {
        this.localValue = this.isLocalValueSet() ? [this.localValue] : [];
      },

      isLocalValueSet() {
        return this.localValue !== undefined && this.localValue !== null;
      }
    },

    computed: {
      ...mapGetters(['getDropdownRefState'])
    }
  };
</script>
<style lang="scss" src="./select-multiple.scss"/>
