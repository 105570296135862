import { productsMapper } from './products-mapper';
import { toQueryString } from './to-querystring';

const CACHE = {};

/**
 * Fetch products list according to the filters values.
 * @param productListId
 * @param options - Product filters options
 * @returns {Promise<Product[]>}
 */
export async function getProducts(productListId, options = {}) {
  const params = toQueryString({ ...options, productListId });
  const url = `/api/lrpproductservice/getpagefilteredproducts`;
  const cacheKey = `${url}${params}`;

  if (CACHE[cacheKey]) {
    return await CACHE[cacheKey];
  }

  const response = await fetch(url, {
    method: 'POST',
    body: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
  });

  let products = await response.json();
  products = productsMapper(products.Products);

  CACHE[cacheKey] = products;

  return products;
}

/**
 * Fetch products list according to the filters values.
 * @param productListId 
 * @param options - Product filters options
 * @returns {Promise<Product[]>}
 */
export async function getFilterProducts(options = {},sortData,productlistdata,skipcountdata,takecountdata) {
  let { age, skinBenefit, skinType, concern, sunProtectionType, format, ppd, productType, spf, subRange, texture, zone} = options;
  let params = {
    Age:  (age && age.toString()) || '',
    SkinBenefit: (skinBenefit && skinBenefit.toString()) || '',
    SkinType: (skinType && skinType.toString()) || '',
    Concern: (concern && concern.toString()) || '',
    SunProtectionType: (sunProtectionType && sunProtectionType.toString()) || '', // Future case
    Format: (format && format.toString()) || '',
    PPD: (ppd && ppd.toString()) || '',
    ProductType: (productType && productType.toString()) || '',
    SPF: (spf && spf.toString()) || '',
    SubRange: (subRange && subRange.toString()) || '',
    Texture: (texture && texture.toString()) || '',
    ValidityAfterOpening: '',
    Zone: (zone && zone.toString()) || '',
    NewOrBestseller: '',
    ProductListId: productlistdata,
    skipCount: skipcountdata,
    takeCount: takecountdata,
    SortBy: sortData,
    filter: 'true',
  }
  const url = `${window.location.origin}/api/lrpproductlist/ProductListDetailsLoadMore`;
  const cacheKey = `${url}${params}`;
  let data;
  if (CACHE[cacheKey]) {
    return await CACHE[cacheKey]; 
  }
  try {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    });
    if (response.ok) {
      // Request was successful
      data = await response.json();
    } else {
      // Request failed
      console.error('Request failed');
    }
   // CACHE[cacheKey] = data;
    return data;
  }catch (error) {
    console.error('Error:', error);
  }
}
