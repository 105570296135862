import { elmYPosition } from './elmYPosition';
import { scrollToPoint } from './scrollToPoint';
import { scrollPosition } from './scrollPosition';

export function smoothScroll(eID) {
  const startY = scrollPosition();
  const stopY = elmYPosition(eID);
  const distance = Math.abs(stopY - startY);

  if (distance < 100) {
    window.scrollTo(0, stopY);
    return;
  }

  let speed = Math.round(distance / 100);
  if (speed >= 20) {
    speed = 20;
  }

  const step = Math.round(distance / 25);

  let leapY = stopY > startY ? startY + step : startY - step;
  let timer = 0;

  if (stopY > startY) {
    for (let i = startY; i < stopY; i += step) {
      scrollToPoint(leapY, timer * speed);
      leapY += step;
      if (leapY > stopY) {
        leapY = stopY;
      }
      timer += 1;
    }
    return;
  }
  for (let i = startY; i > stopY; i -= step) {
    scrollToPoint(leapY, timer * speed);
    leapY -= step;
    if (leapY < stopY) {
      leapY = stopY;
    }
    timer += 1;
  }
}
