var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slider",class:{ 'slider-vertical': _vm.vertical, 'slider-text-selectable': !_vm.swipeOnDesktop },attrs:{"id":_vm.carouselIdentifier}},[(_vm.navigationEnabled)?_c('button',_vm._g({staticClass:"slider__navigation-button slider-navigation-prev",class:{ 'slider-navigation--disabled': !_vm.canAdvanceBackward },attrs:{"aria-disabled":!_vm.canAdvanceBackward,"disabled":!_vm.canAdvanceBackward},on:{"click":function($event){$event.preventDefault();_vm.advancePage('backward', 'navigation'); _vm.navTagging('prev','arrow');}}},!_vm.canAdvanceForward ? { blur: _vm.blurEvent, 'keyup.shift.tab': _vm.shiftTabKeyupEvent } : {}),[_c('span',{staticClass:"is-sr-only"},[_vm._v(_vm._s(_vm.previousPanelText))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"slider__wrapper"},[_c('ol',{staticClass:"slider__inner",style:({
        transform: `translate${this.vertical ? 'Y' : 'X'}(${this.currentOffset}px)`,
        transition: this.transitionStyle,
        flexBasis: `${this.slideWidth}px`,
        msFlexPreferredSize: `${this.slideWidth}px`,
        visibility: `${this.slideWidth ? 'visible' : 'hidden'}`
      })},[_vm._t("default")],2)]),_vm._v(" "),(_vm.navigationEnabled)?_c('button',{staticClass:"slider__navigation-button slider-navigation-next",class:{ 'slider-navigation--disabled': !_vm.canAdvanceForward },attrs:{"aria-disabled":!_vm.canAdvanceForward,"disabled":!_vm.canAdvanceForward},on:{"click":function($event){$event.preventDefault();_vm.advancePage('forward', 'navigation'); _vm.navTagging('next','arrow');},"blur":function($event){return _vm.blurEvent()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;if(!$event.shiftKey)return null;return _vm.shiftTabKeyupEvent.apply(null, arguments)}}},[_c('span',{staticClass:"is-sr-only"},[_vm._v(_vm._s(_vm.nextPanelText))])]):_vm._e(),_vm._v(" "),(_vm.paginationEnabled && _vm.pageCount > 0)?_c('pagination',{attrs:{"current-page":_vm.currentPage,"page-count":_vm.pageCount,"labels":_vm.labels,"tagging-labels":_vm.taggingLabels,"is-clickable":_vm.isPaginationClickable,"nav-tagging-label":_vm.navTaggingLabel,"item-per-page-desktop":3,"page-category":_vm.pageCategory},on:{"paginationClick":(index) => this.goToPage(index, 'pagination')}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }