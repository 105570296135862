<template>
  <div class="product-popin__container" v-if="showProductPopin && showProduct || shopRetailers || isSingleVariant" :class="{'no-color-theme' : disableColorTheme}">
    <template :data-product-data="checkProductData" :data-popin-show="showProductPopin">
      <template v-if="checkProductData && shopRetailers">
        <div class="popupContainerOuter" id="popupContainerOuter" >
          <div class="popupContainer" >
            <div class="popupContainer__close" @click="closeSingleVarient()">&#10006;</div>
            <div class="container" id="app">
              <div class="popupContainer__inner" id="commerceConnectWrapper">
                <div class="container--is-maxwidth container--padded-hor" v-if="productData.ProductRetailerDetails.AllRetailersList && productData.ProductRetailerDetails.AllRetailersList.length > 0">
                  <slider :per-page-custom="[[320, 1], [1024, 4]]" :is-pagination-clickable="false" :loop="false"
                    :with-focusable-items="true" :scroll-per-page="true" :start-at="0" :navigation-enabled="true"
                    :pagination-enabled="true" :swipe-on-desktop="false" ref="slider">
                    <slide v-for="(retailer, index) in productData.ProductRetailerDetails.AllRetailersList" :key="index">
                      <a :href="retailer.RetailerLink"
                        @click="gatagging(retailer.AddToCartTags)"
                        target="_blank" class="card-shop" panel-focusable="">
                        <div class="card-shop__img-wrapper">
                          <img :src="retailer.RetailerImage" :alt="retailer.ProductRetailerItemName" class="card-shop__img"
                            width="200" height="65">
                        </div>
                        <div class="btn btn--black btn--buy btn--icon">
                          <img :src="retailer.CTAIcon" class="btn__icon" aria-hidden="true" :alt="retailer.CTALabel">
                          <span class="btn__wrapper">
                            <span class="btn__text">
                              {{ retailer.CTALabel }}
                            </span>
                          </span>
                        </div>
                      </a>
                    </slide>
                  </slider>
                </div>
                <div v-else>
                  <p class="no-product" style="text-align: center;">{{ productData.RetailerErrorMessage }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="checkProductData && showProduct">
        <div class="product-popin">
          <button @click="closePopin()" class="product-popin__closecta">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0 12.9636L12.9636 -1.50258e-06L15.4385 2.47487L2.47487 15.4385L0 12.9636Z" fill="black" />
              <rect x="2.4751" width="18.3333" height="3.5" transform="rotate(45 2.4751 0)" fill="black" />
            </svg>
          </button>
          <div class="product-popin__image-holder">
            <img
              :src="productData.ProductPackShotImage"
              :alt="productData > 0 ? productData.ProductName.replace(/<[^>]*>?/gm, '') : ''">
          </div>
          <div class="product-popin__content">
            <div class="product-popin__content--text">
              <h3 class="product-popin__content--title" v-html="decodeHtml(productData.ProductName)"></h3>
              <p class="product-popin__content--Description" v-html="decodeHtml(productData.ProductDescription)"></p>
            </div>

            <div class="product-popin__content--selector" v-if="checkProductData">
              <div class="sticky__select--select_capacity" v-if="checkProductData && variantsDetail">
                <label class="sticky__select--select_capacity-label">{{productData.CapacityText || 'Select a size'}}</label>
                <volume-variant-v2
                  :volumes="productData.VariantsDetailJson"
                  legend-name="INHOUD"
                  a11y-aria-label="legend-volume current-volume"
                  :sfccvolumesdetails="productData.SfccVariantsJson"
                  :popup-dropdown="false"
                  :productid="productData.VariantsItemIdJson"/>
              </div>
              <div class="sticky__select--select_color" v-if="checkProductData && shadesDetail">
                <label class="sticky__select--select_color-label">{{productData.ColorText || 'Select a color'}}</label>
                <color-variant-v2
                  ref="colorVariant"
                  a11y-close-panel-text= "panelText"
                  a11y-next-panel-text= "nextPanelText"
                  a11y-previous-panel-text= "previousPanelText"
                  :colors="productData.ShadeVariants"
                  :legend-name="productData.colorVariantLabelText || 'Select a color'"
                  :productid="productData.VariantsItemIdJson"
                  :sfcccolorsdetails="productData.SfccVariantsJson"
                  :popup-dropdown="false" />
              </div>
            </div>
            <!-- {

              "RetailerIsEnabled": "true", -> show lrp-retailer
              "SFMCIsEnabled": "false",
              "CommerConnectorIsEnabled": "false", -> show buy now
              "IsClickandCollectEnable": "false"
            } -->
            <div class="product-popin__buttons">
              <lrp-retailer
                v-if="productData.RetailerIsEnabled == 'true'"
                :data-variant-ean="productData.RetailerEan"
                :product-ean="productData.RetailerEan"
                :initial-data="productData.ProductRetailerDetails"
                :data-text-lable="productData.RetailerClickLabel"
                class="sticky__buynow-button"
                :data-bynow-tagging="productData.ClickCollectTagging[0]"
                :sticky-buynow-button="false"
                :error-messgage="productData.RetailerErrorMessage"
                :variant-detail="productData.VariantsDetailJson"
                :volumes-detail="productData.VariantsDetailJson"
                :colors-details="productData.ShadeVariants"
                :color-list="productData.ShadeVariants"
                :product-ids="productData.VariantsItemIdJson"
                cta-class="cta cta--light">
              </lrp-retailer>
              <buy-now
                v-if="productData.SFMCIsEnabled !== 'true' && productData.CommerConnectorIsEnabled === 'true'"
                :buynow-text="productData.BuyOnlineButtonText"
                :is-online="true"
                :product-ean="productData.VariantsDetailJson.variantEANId"
                :product-id="productData.ProductID"
                :product-name="productData.ProductName"
                :product-ids="productData.VariantsItemIdJson"
                :variant-detail="productData.VariantsDetailJson"
                :volumes-detail="productData.VariantsDetailJson"
                :colors-details="productData.ShadeVariants"
                :color-list="productData.ShadeVariants"
                :tag-event="true"
                :buynow-eventlabel="productData.BuyOnlineButtonText"
                product-popin-cta-class="cta cta--light"></buy-now>
              <pharmacy-cta-button
                ref="pharmacyCtaButton"
                v-if="productData.IsClickandCollectEnable === 'true'"
                :initial-data="productData.ClickAndCollectJson"
                image-url=""
                :cta-lable="productData.ClickClollectLabel"
                data-alttext="Click - Collect"
                :data-clickand-collect-tagging="productData.ClickCollectTagging">
              </pharmacy-cta-button>
              <a v-if="productData.PharmacyIsEnabled === 'true'" :href="productData.FindTheCLosestPharmacyUrl" class="cta cta--light cta--alt"  @click="findformacyTag"><span v-html="productData.FindTheCLosestPharmacyText"></span></a>
            </div>
          </div>
        </div>
        <div class="product-popin__backdrop" @click="closePopin()"></div>
      </template>
      <template v-if="checkProductData && isSingleVariant">
        <buy-now
          :buynow-text="productData.BuyOnlineButtonText"
          :is-online="true"
          :product-ean="productData.VariantsDetailJson.variantEANId"
          :product-id="productData.ProductID"
          :product-name="productData.ProductName"
          :product-ids="productData.VariantsItemIdJson"
          :variant-detail="productData.VariantsDetailJson"
          :volumes-detail="productData.VariantsDetailJson"
          :colors-details="productData.ShadeVariants"
          :color-list="productData.ShadeVariants"
          :tag-event="true"
          :buynow-eventlabel="productData.BuyOnlineButtonText"
          product-popin-cta-class="cta cta--light"
          :isSingleVariant="isSingleVariant"
          :requestData="requestData"
          @closeProductpopin="closePopin"></buy-now>
      </template>
    </template>
  </div>
</template>
<script>
import BuyNow from '@Feature/buynow/code/Scripts/components/buynow.vue';
import LrpRetailer from '@Feature/LrpRetailer/code/Scripts/components/lrp-retailer.vue';
import ColorVariantV2 from '@Feature/ColorVariant-V2/code/Scripts/components/color-variant-v2';
import VolumeVariantV2 from '@Feature/VolumeVariant-V2/code/Scripts/components/volume-variant-v2';
import { tag,AnalyticsHandler } from '@Foundation/analyticsHandler';
import Slide from '@Feature/Slider/code/Scripts/components/slide/slide';
import Slider from '@Feature/Slider/code/Scripts/components/slider/slider';
import pharmacyCtaButton from '@Feature/PharmacyCtaButton/code/Scripts/components/pharmacy-cta-button.vue';

export default {
  name: 'product-popin',
  directives: {
    tag
  },
  components: {
    ColorVariantV2,
    VolumeVariantV2,
    pharmacyCtaButton,
    BuyNow,
    LrpRetailer,
    Slide,
    Slider,
  },
  data() {
    return {
      showProductPopin: false,
      shopRetailers: false,
      productData: {},
      showProduct: false,
      isSingleVariant: false,
      requestData: '',
      disableColorTheme: false
    }
  },
  mounted() {
    const compThis = this;
    window.eventBus.$on('openProductPopin', payload => {
      if (payload) {
        compThis.disableColorTheme = payload.disableColorTheme
        compThis.productData = {}
        compThis.getProductStores(payload.id)
      }
    });
    window.eventBus.$on('closeProductpopin', () => {
      compThis.showProductPopin = false
      compThis.isSingleVariant = false
    })
  },
  computed: {
    checkProductData() {
      return (this.productData && this.productData.ProductName && this.productData.VariantsDetailJson && this.productData.VariantsDetailJson.length > 0 || this.productData.ShadeVariants.length > 0) ? true : false;
    },
    variantActiveShade() {
      const newValue = this.$refs.colorVariant?.activeShade.ean;
      let phar = this.$refs.pharmacyCtaButton ? this.$refs.pharmacyCtaButton.selectedVariant : '';
      phar = newValue;
      return phar
    },
    variantsDetail() {
      return (this.productData && this.productData.VariantsDetailJson && this.productData.VariantsDetailJson.length > 0 && !(Object.keys(this.productData.VariantsDetailJson[0]).length === 0))
    },
    shadesDetail() {
      return (this.productData && this.productData.ShadeVariants && this.productData.ShadeVariants.length > 0 && !(Object.keys(this.productData.ShadeVariants[0]).length === 0))
    },
  },
  methods: {
    decodeHtml(input) {
      var e = document.createElement('div');
      e.innerHTML = input;
      return e.innerHTML || e.childNodes[0].nodeValue;
    },
    async getProductStores(productId) {
      try {
        const response = await fetch(`${window.location.origin}/api/lrpproductlist/ProductVariantsDetails?productID={${productId}}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json', // Adjust the headers as needed
          },
        });

        if (response.ok) {
          // Request was successful
          const data = await response.json();
          
          // Type check of server response.
          if(typeof data === 'string') {
              // Response data should have atleast more than 1 character.
            if(data !== '') {
                this.productData = JSON.parse(data);
            }
            else {
              console.error('Json parsing issues');
              // Assign Empty object for validation.
              this.productData = {};
            }
          }

          // Null Validation for Object.
          if(Object.keys(this.productData).length) {
            
            this.showProductPopin = true;
            // Multiple variant
            if(this.productData.VariantsDetailJson && this.productData.VariantsDetailJson.length > 1 || this.productData.ShadeVariants && this.productData.ShadeVariants.length > 1) {
              this.isSingleVariant = false;
              this.showProduct = true;
              this.shopRetailers = false;
            }
            else {
              //Single variant
              //Commerece connecter enabled
              this.showProduct = false;
              if(this.productData.CommerConnectorIsEnabled === "true") {
                this.isSingleVariant = true;
                let id; 
                if(this.productData.ShadeVariants && this.productData.ShadeVariants.length === 1) {
                  id =  '{'+ this.productData.ShadeVariants[0].id + '}';
                }else {
                  id =  this.productData.VariantsDetailJson[0].id;
                }
                this.requestData = id;
                //window.eventBus.$emit('commerceConnectorEnable', id);
                this.shopRetailers = false;
              }
              //Retailer enabled
              if(this.productData.RetailerIsEnabled === "true") {
                this.shopRetailers = true;
              }
            }
          }
        } else {
          // Request failed
          // this.productData = this.testDataValue;
          // // Null Validation for Object.
          // if(this.productData && Object.keys(this.productData).length) {
            
          //   this.showProductPopin = true;
          //   // Multiple variant
          //   if(this.productData.VariantsDetailJson && this.productData.VariantsDetailJson.length > 1 || this.productData.ShadeVariants && this.productData.ShadeVariants.length > 1) {
          //     this.isSingleVariant = false;
          //     this.showProduct = true;
          //     this.shopRetailers = false;
          //   }
          //   else {
          //     //Single variant
          //     //Commerece connecter enabled
          //     this.showProduct = false;
          //     if(this.productData.CommerConnectorIsEnabled === "true") {
          //       this.isSingleVariant = true;
          //       let id; 
          //       if(this.productData.ShadeVariants && this.productData.ShadeVariants.length === 1) {
          //         id =  '{'+ this.productData.ShadeVariants[0].id + '}';
          //       }else {
          //         id =  this.productData.VariantsDetailJson[0].id;
          //       }
          //       this.requestData = id;
          //       //window.eventBus.$emit('commerceConnectorEnable', id);
          //       this.shopRetailers = false;
          //     }
          //     //Retailer enabled
          //     if(this.productData.RetailerIsEnabled === "true") {
          //       this.shopRetailers = true;
          //     }
          //   }
          // }
          console.error('Request failed');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    closePopin() {
      this.showProductPopin = false;
      this.showProduct = false;
      document.body.classList.remove('showProductPopin');
    },
    closeSingleVarient() {
      this.shopRetailers = false
      this.showProductPopin = false
    },
    removeHtmlAndSpecialChars(inputString) {
        // Create a DOMParser to parse the inputString as HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(inputString, 'text/html');
      
        // Get the plain text without HTML tags
        const plainText = doc.body.textContent || "";

        return plainText.replaceAll('\n', '');
    },
    findformacyTag() {
      if(this.productData != null && this.productData != undefined ) {
        let productEan = this.productData.ProductRetailerDetails.DefaultEAN;
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'event_name': 'where_to_buy',
          'ecommerce': 'undefined',
          'eventCategory': 'Ecommerce',
          'eventAction': 'where to buy',
          'eventLabel': `${this.removeHtmlAndSpecialChars(this.productData.ProductName).toLowerCase()}::${productEan}`,
          'product_info': `${this.removeHtmlAndSpecialChars(this.productData.ProductName).toLowerCase()}::${productEan}`
        });
      }
    },
    gatagging(data) {
      AnalyticsHandler.pushDataLayer({ ...data});
    }
  }
};
</script>
<style lang="scss" src="./product-popin.scss"></style>
