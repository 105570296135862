<template>
  <div class="component-content">
    <div class="concern-description">
    <div class="container container--is-maxwidth">
      <div class="concern-description__wrapper">
        <div v-if="title" class="concern-description__col">
          <h3 class="heading--style" :class="titleFontSize">
            <strong v-html="title"></strong>
          </h3>
        </div> 
        <div v-if="descripion" class="concern-description__col concern-description__col--right rich-text">
          <p v-html="descripion"></p>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

  export default {
    name: 'text-banner',
    props: {
      title: {
        type: String,
        required: true,
        default: ''
      },
      descripion: {
        type: String,
        required: true,
        default: ''
      },
      titleFontSize :{
        type: String,
        required: true,
        default: 'large'
      }
    },
  };
</script>
