import { VueModule } from '@Foundation/utilities';
import SafeSpaceArticles from './code/Scripts/components/articles/safe-space-articles';
import SafeSpaceFilter from './code/Scripts/components/filter/safe-space-filter';
import SafeSpaceConcerns from './code/Scripts/components/concerns/safe-space-concerns';

export * from './code/Scripts/store/concerns';

export default VueModule({
  components: {
    SafeSpaceArticles,
    SafeSpaceFilter,
    SafeSpaceConcerns,
  },
});
