<template>
  <div class="article-push-v2">
    <carousel :bullets="true" :navigation="true" :item-per-page-desktop="3" page-category="product page" next-panel-text="Next panel" previous-panel-text="previous panel">
      <div slot="header" class="title__wrapper">
        <h3 class="heading" v-if="options.ArticleTitle" v-html="options.ArticleTitle"/>
      </div>
      <div v-for="(list, index) in options.RelatedPages" :key="index" >
        <article-card :src-set="list.SrcSet" :img-src="list.ImgSrc" :img-alt="list.ImageAltText" :item-title="list.Title" :item-url="list.Link"
                      :tagging="list.Tagging" :cta-label="list.CtaLabel"/>
      </div>
    </carousel>
  </div>
</template>

<script>
  import Carousel from '@Feature/Carousel/code/Scripts/components/carousel.vue';
  import ArticleCard from '@Feature/LrpProducts/code/Scripts/components/article-card/article-card.vue';

  export default {
    name: 'article-push-v2',
    components: { Carousel, ArticleCard },

    props: {
      options: { type: Object, required: true, default() {
        return undefined;
      } },
    },
  };
</script>
