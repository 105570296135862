<template>
  <section class="datapush-main">
    <div class="datapush-wrapper">
      <div class="section-heading">
        <h2 class="section-heading__title" v-html="dataPush.Title"/>
        <div class="section-heading__sub-title" v-html="dataPush.SubTitle"/>
      </div>
      <div class="section-carousel">
        <div class="keydata">
          <ul>
            <li class="content" v-for="(item,index) in dataPush.KeyData" :key="index">
              <div class="title" v-html="item.KeyDataTitle"/>
              <div class="description" v-html="item.KeyDataDescription"/>
              <div class="disclaimer" v-html="item.Disclaimer"/>
            </li>
          </ul>
        </div>
        <div class="datapush-carousel">
          <div class="carousel-container">
            <swiper :options="swiperOption" class="swiper" ref="swiper" @slideChange="onTagging">
              <swiper-slide v-for="(item, index) in dataPush.CarouselItem" :data-index="index" :key="index + 0"
                            :class="{ 'swiper-highligher': currentLink === index }">
                <div class="slide-section">
                  <div v-if="item.YoutubeId" class="image-contianer">
                    <template v-if="item.Popup">
                      <div class="youtube-player">
                        <div class="youtube-player__poster" :style="'background-image:url(' + item.Src + ')'" ref="poster"/>
                        <button class="play-button"
                                @click="showVideo(item)"
                                ref="playBtn">
                          <span class="play-icon"/>
                          <span class="play-text">{{ item.PlaybuttonText }}</span>
                          <span class="is-sr-only">{{ item.PlaybuttonText }}</span>
                        </button>
                      </div>
                    </template>
                    <template v-else>
                      <youtube-player :youtube-id="item.YoutubeId"
                                      :poster="item.Src"
                                      :popup="item.Popup"
                                      :autoplay="true"
                                      :aria-label="item.PlaybuttonText"
                                      :newplay-button="true" :play-text="item.PlaybuttonText"/>
                    </template>
                  </div>
                  <div v-else class="image-contianer">
                    <img :src="item.Src" alt="data-push-image">
                  </div>
                </div>
                <collapse v-if="item.YoutubeId && item.Transcript"
                          class="transcript__collapse"
                          :aria-label="'show transcript'">
                  <template slot="title" slot-scope="scoped">
                    <template v-if="scoped.collapsed">
                      {{ item.TranscriptShowText }} <span class="arrow down"/>
                    </template>
                    <template v-else>
                      {{ item.TranscriptHiddenText }} <span class="arrow up"/>
                    </template>
                  </template>
                  <!-- <button class="no-js collapse-title-btn" aria-label="">
                        {{ item.TranscriptShowText }}
                      </button> -->
                  <span v-html="item.Transcript"/>
                </collapse>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <div class="navigation-wrapper" v-if="!hidenavigation">
      <div class="carousel-navigation">
        <div class="carousel__arrow carousel__arrow--left" slot="button-prev" @click="prev()" :class="{ 'disabled': swiperAtBegain }"/>
        <div class="carousel__arrow carousel__arrow--right" slot="button-next" @click="next()" :class="{ 'disabled': swiperAtEnd }"/>
      </div>
    </div>
    <div class="generic-banner">
      <generic-banner-youtube-player :poster="poster" :youtube-id="youtubeId" v-if="showGenericComponent" :autoplay="carouselautoplay" :carousel-popup="true"
                                     @closeGenericComponent="closeGenericComponent()"/>
    </div>
  </section>
</template>
<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import GenericBannerYoutubePlayer from '@Feature/GenericBannerYoutubePlayer/code/Scripts/components/generic-banner-youtube-player.vue';
  import YoutubePlayer from '@Feature/YoutubePlayer/code/Scripts/components/youtube-player.vue';
  import collapse from '@Feature/Collapse/code/Scripts/components/collapse.vue';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';

  export default {
    name: 'data-push',
    components: { Swiper, SwiperSlide,GenericBannerYoutubePlayer, YoutubePlayer,collapse },
    props: {
      dataPush: {
        type: Array / Object,
        default: undefined
      },
      dataPushTitleForTagging: {
        type: String, required: false, default: ''
      },
      pageCategory: {
        type: String, required: false, default: ''
      }
    },
    data() {
      return {
        currentLink: undefined,
        swiperAtEnd: false,
        swiperAtBegain: true,
        showGenericComponent: false,
        poster: undefined,
        carouselautoplay: true,
        swiperOption: {
          pagination: '.swiper-pagination',
          spaceBetween: 15,
          navigation: {
            nextEl: '.carousel__arrow carousel__arrow--left',
            prevEl: '.carousel__arrow carousel__arrow--right',
            hiddenClass: '.swiper-button-hidden',
          },
          breakpoints: {
            768: {
              slidesPerView: 'auto',
            },
            320: {
              slidesPerView: 1,
            },
          },
        },

      };
    },

    computed: {
      posterBackground() {
        return this.poster && 'url(' + this.poster + ')';
      },
      hidenavigation() {
        if (this.dataPush.CarouselItem.length <= 1) {
          return true;
        } else {
          return false;
        }
      }
    },

    created() {
      document.documentElement.style.setProperty('--theme-color', this.dataPush.ColorTheme);
    },

    beforeUpdate() {
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
    },

    destroyed() {
      document.documentElement.style.removeProperty('---theme-color');
    },

    methods: {
      showVideo(item) {
        this.youtubeId = item.YoutubeId;
        this.showGenericComponent = true;
        this.poster = item.Src;
        this.carouselautoplay = item.AutoPlay;
      },

      closeGenericComponent() {
        this.showGenericComponent = false;
      },

      prev() {
        this.$refs.swiper.$swiper.slidePrev();
        this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
        this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      },

      next() {
        this.$refs.swiper.$swiper.slideNext();
        this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
        this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      },

      onTagging() {
        let indexValue = this.$refs.swiper.$swiper.activeIndex +1 ;
        let maintitle = this.decodeHtmlstring(this.dataPush.Title).toLowerCase();
        let visualName = this.dataPush.CarouselItem[indexValue-1].YoutubeId ? this.dataPush.CarouselItem[indexValue-1].VideoName : this.dataPush.CarouselItem[indexValue - 1].ImageName;
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name': 'slider_button_click',
          'eventCategory': this.pageCategory,  
          'eventAction': 'select::slider navigation elements',
          'eventLabel': `${maintitle}::none::${[indexValue]}`, 
          'cta_name': `${maintitle}::none::${[indexValue]}`, 
          'module_name': `slider navigate::${maintitle}`,
        });
      },
      decodeHtmlstring(html) {
        let div = document.createElement("div");
        div.innerHTML = html;
        let datas=div.innerText.replaceAll('\n', ' ');
        return datas;
      },
    }
  };
</script>
<style lang="scss" src="./data-push.scss"></style>
