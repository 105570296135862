<template>
  <div class="product-card"
       :class="trackClass" 
       data-tag-wrapper
       v-tag:productimpression.dom="{label: `${pageCategory}`}"
      v-tag:productclick.dom='{ label: `${taggingProductName}::${tagging.productid}` }'>

    <div class="product-card__content" data-tag-product-identifier v-bind="dataTagging">
     <a :href="productUrl" class="product-card__link">
        <div class="product-card__img-wrapper">
          <div class="product-card_tags" v-if="productTag">{{productTag}}</div>
          <img v-if="packshot"
              :src="packshot.MainImage ? packshot.MainImage : packshot.src"
              :alt="packshot.ImageAlt || decodeHtml(productName.html).replace( /(<([^>]+)>)/ig, '')"
              class="product-card__img" loading="lazy"
            />
        </div>
        <component :is="titleTagName" class="product-card__name" v-html="decodeHtml(productName.html || productName)"></component>
        </a>
        <a :href="productUrl + '#ratings'" class="star-rating">
          <div v-if="isBazaarvoiceActivated === 'true'" >
            <div data-bv-show="inline_rating" :data-bv-product-id="productDataId"></div>
          </div>
          <div v-else-if="isRatingEnabled === 'true'" class="rfp-rr-star-rating-product-173-or">
            <StarRatingWrapper
              :increment="1"
              :max-rating="5"
              :inactive-color="inactiveColor"
              :active-color="activeColor"
              :star-size="28"
              :rating="overallRating"
              :total-rating-count="totalRatingCount"
              :read-only="true"
              :show-rating="false"
              border-color="#000"
              :border-width="1"
              :is-count-in-brackets="isCountInBrackets"
            />
          </div>
      </a>
      <p class="product-card__desc" v-html="decodeHtml(productShortDescription)" />   
      <a :href="productUrl" v-if="isCheckitoutEnabled === 'true'" class="cta cta--light cta--alt">
        <span v-html="ctaLabel" />
      </a>
      <button @click="showProductPopin()" class="cta cta--light" v-if="isBuynowEnabled === 'true'"><span v-html="buynowCtaText"></span></button>
    </div>
  </div>
</template>
<script>
 import { tag } from '@Foundation/analyticsHandler';
 import StarRatingWrapper from '../../../../../../../fractal/components/Atoms/star-rating/star-rating-wrapper.vue';
  export default {
    name: 'product-card',
    directives: {
      tag
    },
    components: {
      StarRatingWrapper
    },
    props: {
      productName: {
        type: [String, Object],
        required: false
      },
      inactiveColor: {
        type: String,
        default: '#fff',
        required: false
      },
      activeColor: {
        type: String,
        default: '#000',
        required: false
      },
      isRatingEnabled: {
        type: String,
        required: false
      },
      AverageRating: {
        type: Number,
      },
      productShortDescription: {
        type: String,
        default: ""
      },
      productUrl: {
        type: String,
        required: false,
        default: ""
      },
      tagging: {
        type: Object,
        required: false,
        default: () => ({})
      },
      packshot: {
        type: Object,
        required: false,
        default: () => ({})
      },
      ctaLabel: {
        type: String,
        required: false,
        default: ""
      },
      isBazaarvoiceActivated: {
        type: String,
        required: false,
      },
      productTag: {
        type: String,
        required: false,
        default: ""
      },
      buynowCtaText: {
        type: String,
        required: false,
        default: ""
      },
      borderColor: {
        type: String,
        required: false
      },
      totalRatingCount: {
        type: String
      },
      isBuynowEnabled: {
        type: String
      },
      productId: {
        type: String
      },
      productItemId: {
        type: String,
        default: ''
      },
      dataBynowTagging: {
        type: Object
      },
      initialData: {
        type: Object,
      },
      dataVariantEan: {
        type: String,
        required: false
      },
      errorMessgage: {
        type: String,
        required: false,
        default: ''
      },
      position: {
        type: Number
      },
      productDataId:{
        type: String
      },
      titleTagName: {
         type: String,
         default: 'h2'
      },
      pageCategory: {
        type: String,
        default:'product selector page'
      },
      isCountInBrackets: {
        type: Boolean,
        default: true
      },
      isCheckitoutEnabled:{
        type: String
      },
     trackClass: {
        type: String,
        default: 'trackImpfour'
      }
    },
    data() {
      return {
        overallRating: this.AverageRating,
        buyData: {}
      };
    },
    computed: {
      dataTagging() {
        const attributes = Object.keys(this.tagging).reduce((acc, key) => {
        let subKey;
        if(key != "product_label" && key != "product_franchise" && key != "product_quantity_range") {
          if(key == "ProductLabel") {
            let data = "product_label";
            subKey = data.toLocaleLowerCase()
          }
          else if(key == "ProductQuantityRange") {
            let data = "product_quantity_range";
            subKey = data.toLocaleLowerCase()
          }
          else if(key ==  "ProductFranchise") {
            let data = "product_franchise";
            subKey = data.toLocaleLowerCase()
          }else {
            subKey = key.toLocaleLowerCase().replace('product', 'product-');
          }
        }else{
          subKey = key;
        }
        acc[`data-tag-${subKey}`] = this.tagging[key];
        if(subKey == "price" || subKey == "name" || subKey == "dimension48" || subKey == "dimension49" 
        || subKey == "dimension91" || subKey == "brand" || subKey == "category" || subKey == "dimension59" || subKey == "dimension38") {
          if(subKey == "dimension59" && this.tagging[key] == "") {
            acc[`data-tag-product-${subKey}`] = "none";
          }else {
            acc[`data-tag-product-${subKey}`] = this.tagging[key];
          }
        }else if(subKey == "position"){
          acc[`data-tag-product-${subKey}`] = this.position + 1;
        }else if(subKey == "product_label" || subKey == "product_franchise" || subKey == "product_quantity_range"){
          acc[`data-tag-${subKey}`] = this.tagging[key];
        }else {
          acc[`data-tag-${subKey}`] = this.tagging[key];
        }
        return acc;
      }, {});
        return attributes;
      },
      taggingProductName() {
        return (this.tagging?.name?.toLowerCase() || this.tagging?.Name?.toLowerCase());
      }
    },
    mounted(){
      if (document.querySelectorAll(`.${this.trackClass}`).length) {
        var rx = document.querySelector(`.${this.trackClass}`).getBoundingClientRect().x;
        var impclassName = 'impression';
        var m = 0;
        document.querySelectorAll(`.${this.trackClass}`).forEach((element,index) => {
          const rect = element.getBoundingClientRect();
          if (rx != rect.x) {
            element.classList.add(impclassName);
          } else {
            impclassName = 'impression-' + m;
            element.classList.add(impclassName);
            m++;
          }
        });
      }
    },
    methods: {
      decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      },
      validateID(itemId) {
        return itemId.split('-').length == 5 ? true : false
      },
      showProductPopin() {
        var productNameInfo = this.decodeHtml(this.productName.html || this.productName);
        var parser = new DOMParser();
        var parsedHtml = parser.parseFromString(productNameInfo, 'text/html');
        var productName = parsedHtml.body.textContent || "";
        window.dataLayer.push({
          event: 'uaevent',
          ecommerce: 'undefined',
          event_name: 'buy_now',
          eventCategory: 'Ecommerce',
          eventAction: 'click to buy',
          eventLabel: `${productName.replace(/<\/?[^>]+(>|$)/g, '').toLowerCase()}::${this.tagging.productid}`,
          product_info: `${productName.replace(/<\/?[^>]+(>|$)/g, '').toLowerCase()}::${this.tagging.productid}`,
        });
        const ProductInfo = {
          id: this.validateID(this.productItemId) ? this.productItemId : this.productId,
          disableColorTheme: this.$parent.$options.name === 'SwiperSlide'
        }
        window.eventBus.$emit('openProductPopin', ProductInfo)
      },
    },
  };
</script>
