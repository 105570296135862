import OverlayModule from '@Feature/Overlay';
import { VueModule } from '@Foundation/utilities';
import MainMenu from './code/Scripts/components/main-menu.vue';

import { addActive, addClassOnScroll, addSubActive, goBack } from './code/Scripts/directives';

export default VueModule({
  imports: [
    OverlayModule
  ],
  directives: {
    addActive,
    goBack,
    addSubActive,
    addClassOnScroll
  },
  components: {
    MainMenu
  }
});
