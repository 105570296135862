import { eventBus } from '../../eventbus/eventbus';

/* istanbul ignore next */
document.addEventListener('lazybeforeunveil', event => eventBus.$emit('image.lazybeforeunveil', event));

/**
 *
 * @deprecated
 * @type {{name: string, bind: imageloaded.bind}}
 */
export const imageloaded = {
  name: 'imageloaded',
  bind: (el) => {
    const lazyImage = el.querySelector('.lazyload');

    eventBus.$on('image.lazybeforeunveil', (event) => {
      if (event.target === lazyImage) {
        el.classList.remove('image__loading');
      }
    });
  }
};
