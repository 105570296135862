<template>
   <div class="medicalpopin" v-if="showMedicalPopin">
    <div class="medicalpopin__container">
      <modal class="medicalpopin__container--modal" ref="modal">      
        <div class="medicalpopin__container__wrapper">
          <div class="medicalpopin__title" v-html="initialData.Title">
          </div>   
            <div class="medicalpopin__description" v-html="initialData.Description">
            </div>
           <div class="medicalpopin__footer--cta">
            <a class="cta cta--light" @click="closeMedicalpopup(true)">
              {{ initialData.OkButton }}
            </a>          
            <a class="cta cta--light cta--alt" @click="closeMedicalpopup(false)">
              {{ initialData.CancelButton }}
            </a>
          </div>
        </div>
      
      </modal>
    </div>
  </div>
</template>
<script>
import Modal from '@Feature/Modal/code/Scripts/components/modal/';

export default {
  name: 'medical-popin',
 
  components: {
   Modal
  },
  props: {
    initialData: { type: Object, default: () => ({}) },
    productShow:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      showMedicalPopin: false,  
    }
  },
  mounted() {  
    if (this.productShow) { 
      this.showMedicalPopin=true;
      setTimeout(() => {
            this.$children[0].$data.showModal = true;
          }, 100);       
      document.body.style.overflow = 'hidden';
    }      
  },
  
   methods: {
      closeMedicalpopup(isConfirmed) {
        this.showMedicalPopin = false;
        document.body.style.overflow = 'auto';
          if (!isConfirmed) {
            this.navigateBack();
          }
        },
        navigateBack() {
         if (document.referrer) {
            window.location.href = document.referrer;
         } else {
            window.location.href = '/';
         }
        }
   
  }
};
</script>
<style lang="scss" src="./medical-popin.scss"></style>
