<template>
  <div class="safe-space-carousel">
    <div v-if="articleListItem && articleListItem.length" class="safe-space-carousel__container">
      <swiper ref="swiper" class="swiper" :options="safesapceswiperOptions">
        <swiper-slide
          v-for="(item, index) in articleListItem"
          :key="index + 0"
          class="safe-space__carousel"
          :data-index="index"
        >
          <safe-space-carousel-card :article-item="item" :is-title-uppercase="titleUppercase">
          </safe-space-carousel-card>
        </swiper-slide>
      </swiper>
      <div v-if="desktopScreen && !isRtl" class="safe-space-carousel__navigation">
        <div
          class="safe-space-carousel__arrow safe-space-carousel__arrow--left right-navigation"
          :class="{ disabled: swiperAtBegain }"
          @click="prev()"
        >
          <svg width="42" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.99996L3.85762 0.146484L4.56547 0.853599L1.91619 3.50002L42 3.50003V4.50003L1.91628 4.50002L4.56548 7.14649L3.85762 7.85359L0 3.99996Z" fill="black"/>
          </svg>
        </div>
        <div
          class="safe-space-carousel__arrow safe-space-carousel__arrow--right left-navigation"
          :class="[(swiperAtEnd || articleListItem.length <= 3) ? 'disabled' : '']"
          @click="next()"
        >
          <svg width="42" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42 3.99996L38.1424 0.146484L37.4345 0.853599L40.0838 3.50002L1.90735e-05 3.50003V4.50003L40.0837 4.50002L37.4345 7.14649L38.1424 7.85359L42 3.99996Z" fill="black"/>
          </svg>
        </div>
      </div>
      <div v-if="desktopScreen && isRtl" class="safe-space-carousel__navigation">
        <div
          class="safe-space-carousel__arrow safe-space-carousel__arrow--right right-navigation"
          :class="{ disabled: swiperAtBegain }"
          @click="prev()"
        >
          <svg width="42" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M42 3.99996L38.1424 0.146484L37.4345 0.853599L40.0838 3.50002L1.90735e-05 3.50003V4.50003L40.0837 4.50002L37.4345 7.14649L38.1424 7.85359L42 3.99996Z" fill="black"/>
          </svg>
        </div>
        <div
          class="safe-space-carousel__arrow safe-space-carousel__arrow--left left-navigation"
          :class="[(swiperAtEnd || articleListItem.length <= 3) ? 'disabled' : '']"
          @click="next()"
        >
          <svg width="42" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.99996L3.85762 0.146484L4.56547 0.853599L1.91619 3.50002L42 3.50003V4.50003L1.91628 4.50002L4.56548 7.14649L3.85762 7.85359L0 3.99996Z" fill="black"/>
          </svg>
        </div>
      </div>
      <div class="safe-space-carousel--count">
        {{ articleCountText }}
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import SafeSpaceCarouselCard from '../carousel-card/safe-space-carousel-card.vue';

SwiperClass.use([Pagination, Navigation]);
const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  name: 'safe-space-carousel',
  components: {
    SafeSpaceCarouselCard,
    Swiper,
    SwiperSlide,
  },
  props: {
    articleListItem: {
      type: Array,
      default: undefined,
    },
    countText: {
      type: String,
      default: 'of articles',
    },
    titleUppercase: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      desktopScreen: window.matchMedia('(min-width: 768px)').matches,
      isMobile: window.innerWidth <= 768,
      swiperAtEnd: false,
      swiperAtBegain: true,
      sliderIndex: window.innerWidth <= 768 ? 2 : 3,
      safesapceswiperOptions: {
        loop: false,
        initialSlide: 0,
        speed: 500,
        centeredSlides: false,
        slidesPerView: 3,
        spaceBetween: 16,
        navigation: {
          nextEl: '.left-navigation',
          prevEl: '.right-navigation',
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 16,
          },
          360: {
            slidesPerView: 2.2,
            spaceBetween: 16,
          },
          320: {
            slidesPerView: 2.15,
            spaceBetween: 16,
          },
        },
      },
      ofText: '',
      articleText: '',
    };
  },
  computed: {
    isRtl() {
      let elem = document.getElementById('main-container');
      let style = getComputedStyle(elem);
      return style.direction === 'rtl';
    },
    articleCountText() {
      const cText = this.countText ? this.countText : 'of articles';
      const text = cText.split(' ');
      const currentItem =
        this.articleListItem.length > this.sliderIndex ? this.sliderIndex : this.articleListItem.length;
      if (this.isRtl) {
        return `${text[1]} ${this.articleListItem.length} ${text[0]} ${currentItem}`;
      } else {
        return `${currentItem} ${text[0]} ${this.articleListItem.length} ${text[1]}`;
      }
    },
  },
  mounted() {
    this.$refs.swiper.swiperInstance.on('slideChange', () => {
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      if (this.touchEnd && this.swipeMethod != 'arrow') {
        this.swipeMethod = 'swipe';
        this.sliderIndex = !this.$refs.swiper.$swiper.isEnd ? this.$refs.swiper.$swiper.activeIndex + 2 : this.articleListItem.length;
      }
    });
    this.$refs.swiper.swiperInstance.on('touchEnd', () => {
      this.touchEnd = true;
    });
    this.$refs.swiper.swiperInstance.on('reachEnd', () => {
      this.sliderIndex = this.articleListItem.length;
    });
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 768;
    });
  },
  methods: {
    prev() {
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      this.swipeMethod = 'arrow';
      this.sliderIndex = this.sliderIndex - 1;
    },
    next() {
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      this.swipeMethod = 'arrow';
      this.sliderIndex = this.sliderIndex + 1;
    },
  },
};
</script>
<style lang="scss" src="./safe-space-carousel.scss"></style>
