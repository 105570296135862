import { throttle } from '@Foundation/utilities/timing';
import Vue from 'vue';

/**
 * Function that hides and shows the menu depending on scroll direction and position
 * @param {object} el - The element clicked
 * @param {number} nElmH - Height of nav for the offset on mobile
 * @param {object} oOffsetElm - Element that will get the offset
 * @param {object} oBody - Body element to add the padding top
 * @param {boolean} bScrollDown - To know if the user is scrolling up or down
 * @param {number} nScrollTop - Number of pixels scrolled
 */

export const addClassOnScroll = { // eslint-disable-line
  name: 'add-class-on-scroll',
  bind(el) {
    Vue.nextTick(() => {
      const nElmH = el.getBoundingClientRect().height;
      const oBody = document.querySelector('body');
      let nLastPos = addClassOnScroll.scrollPosition();

      const oOffsetElm = document.querySelector('.nav__list');
      oOffsetElm.style.top = `${nElmH}px`;

      /**
       * Add and remove class depending on scroll direction and position
       */
      window.addEventListener(
        'scroll',
        throttle(() => {
          nLastPos = addClassOnScroll.handleScroll(el, oBody, nElmH, nLastPos);
        }, 160)
      );
    });
  },

  scrollPosition() {
    return (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
  },

  handleScroll(el, oBody, nElmH, nLastPos) {
    const nScrollTop = addClassOnScroll.scrollPosition();
    const bScrollDown = nScrollTop > nLastPos;

    if (!bScrollDown && nScrollTop > nElmH) {
      el.classList.add('sticky');
      el.classList.remove('sticky--hidden');
      oBody.style.paddingTop = `${nElmH}px`;
    } else if (bScrollDown && nScrollTop > nElmH) {
      el.classList.add('sticky--hidden');
    } else {
      el.classList.remove('sticky');
      el.classList.remove('sticky--hidden');
      oBody.style.paddingTop = 0;
    }

    return nScrollTop;
  }
};
