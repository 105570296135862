<template>
  <div>
    <select-multiple v-model="localValue"
                     :placeholder="label" :count="count">
      <slot/>
    </select-multiple>
  </div>
</template>
<script>
  import SelectMultiple from '@Feature/SelectMultiple/code/Scripts/components/select-multiple';
  import { mapActions } from 'vuex';
  import { QueryToObject } from '../../services/query-to-object';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';

  export default {
    name: 'product-filter',
    components: { SelectMultiple },
    props: {
      taggingCategory: {
        type: String,
        required: false,
        default: undefined
      },
      type: { // SkinType
        type: String,
        required: true,
        default: undefined
      },
      value: {
        type: Array,
        required: false,
        default: () => []
      },
      placeholder: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        mounting: false,
        selectedFiltersCount: 0,
        quickSort:false
      };
    },
    computed: {
      localValue: {
        get() {
          return this.$store.getters.getFilter(this.type);
        },
        set(value) {
          if (this.mounting) {// avoid first maj of localValue
            this.setFilterValue({ type: this.type, value });
            let filtervalue = value[value.length - 1];
            /* istanbule ignore else */
            if (this.selectedFiltersCount < value.length && this.quickSort) {
               AnalyticsHandler.pushDataLayer({
                'event': 'uaevent',
                'event_name': 'use_filters',
                'ecommerce': 'undefined',
                'eventCategory': this.taggingCategory,
                'eventAction': 'filter',
                'eventLabel': `${'filter_tags'}::${filtervalue.toLowerCase()}`,
                'filter_type': 'filter',
                'filter_value': `${'filter_tags'}::${filtervalue.toLowerCase()}`
              });
            }
            this.selectedFiltersCount = value.length;
          }
        }
      },

      label() {
        return this.placeholder;
      },
      count() {
        const filter = this.$store.getters.getFilter(this.type);
        return filter.length >= 1 ? `(${filter.length})` : '';
      }
    },

    watch: {
      value(v) {
        this.$store.commit('filters', { type: this.type, value: v });
      }
    },

    created() {
      this.mounting = false;
      const queries = QueryToObject(window.location.search);

      this.$store.commit('filters', { type: this.type, value: queries[this.type] || this.value });
    },

    mounted() {
      this.mounting = true;
      window.eventBus.$on('quicksortTrigger', (payload) => {
         this.quickSort=payload;      
      });
      this.$nextTick(() => {
        this.selectedFiltersCount =  this.localValue;
      });
    },

    destroyed() {
      this.$store.commit('deleteFilter', this.type);
    },

    methods: {
      ...mapActions(['setFilterValue'])
    }
  };
</script>
