<template>
  <div class="safe_space_article">
    <h2 class="safe_space_article__title" v-if="safeSpaceTitle">{{ safeSpaceTitle }}</h2>
    <highlighted-article
      v-if="highlightList"
      :highlighted-items="highlightList"
      :title-uppercase="isTitleUppercase"
    />
    <div v-if="articleList && articleList.length" class="safe_space_article__carousel">
      <safe-space-carousel
        :article-list-item="articleCarouselList"
        :count-text="countText"
        :title-uppercase="isTitleUppercase"
      ></safe-space-carousel>
    </div>
  </div>
</template>

<script>
import HighlightedArticle from '@Feature/HighlightedArticle/code/Scripts/components/highlighted-article.vue';
import SafeSpaceCarousel from '@Feature/SafeSpaceCarousel/code/Scripts/components/carousel/safe-space-carousel.vue';

export default {
  name: 'safe-space-articles',
  components: {
    HighlightedArticle,
    SafeSpaceCarousel,
  },
  props: {
    safeSpaceTitle: {
      type: String,
      default: '',
    },
    highlightedArticle: {
      type: Array,
      default: undefined,
    },
    articleList: {
      type: Array,
      default: undefined,
    },
    isConcernData: {
      type: Boolean,
      default: false,
    },
    countText: {
      type: String,
      default: 'of articles',
    },
    isTitleUppercase: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      highlightList: {},
      articleCarouselList: this.articleList ? this.articleList : [],
    };
  },
  mounted() {
    this.initialData();
  },
  methods: {
    initialData() {
      const articleItems = this.articleCarouselList;
      if (this.highlightedArticle && this.highlightedArticle.length) {
        this.highlightList = this.highlightedArticle[0];
      } else {
        this.highlightList = articleItems ? articleItems.shift() : [];
        this.articleCarouselList = articleItems;
      }
    },
  },
};
</script>
<style lang="scss" src="./safe-space-articles.scss"></style>
