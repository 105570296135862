<template>
  <div class="select-multiple">
    <panel class="panel--field" 
      ref="sortdropdown" 
      :defaultOpen="true" 
      :individualInstance="true"
    >
      <button 
        slot="toggleButton"
        slot-scope="scope"
        class="select-multiple__toggle"
        @click="scope.panelToggler"
        :aria-expanded="scope.expanded ? 'true': 'false'"
      >
        {{ sortLabel }}
      </button>
      <slot></slot>
    </panel>
  </div>
</template>

<script>
import Panel from '../../../../../Panel/code/Scripts/components/panel.vue';
export default {
  name: 'product-sort',

  data() {
    return {
    }
  },

  components: { 
    Panel 
  },
  props: {
      sortLabel: {
        type: String,
        default: "Sort by"
      },
  }
  
};
</script>

<style lang="scss" src="./product-sort.scss"></style>
