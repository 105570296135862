import { AnalyticsSocialActionEventObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class SocialEventObject extends DataObject {
  constructor(data) {
    super('socialInt', data);

    if (!data.socialNetwork) {
      Logger.error("[AnalyticsSocialActionEventObjectException] socialNetwork is required for socialInt (L'Oréal guideline)", data);
      throw new AnalyticsSocialActionEventObjectException("socialNetwork is required for socialInt (L'Oréal guideline)");
    }

    if (!data.socialTarget) {
      Logger.error("[AnalyticsSocialActionEventObjectException] socialTarget is required for socialInt (L'Oréal guideline)", data);
      throw new AnalyticsSocialActionEventObjectException("socialTarget is required for socialInt (L'Oréal guideline)");
    }

    this.result.ecommerce = undefined;
    this.result.socialAction = 'share';
    this.result.social_action = 'share';
  }

  set socialNetwork(network) {
    this.result.socialNetwork = this.setProperty(network);
  }

  get socialNetwork() {
    return this.result.socialNetwork;
  }

  set socialTarget(url) {
    this.result.socialTarget = this.setProperty(url);
  }

  get social_network() {
    return this.result.socialTarget;
  }

  set social_network(url) {
    this.result.social_network = this.setProperty(url);
  }
  
}
