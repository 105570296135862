// eslint-disable-next-line no-console
import { register } from 'register-service-worker';

// Get current date and week for customize the chunk filename 
const currentDate = new Date();
const currentWeek = Math.ceil((currentDate.getUTCDate()) / 7);
// Set releaseNumber as an environment variable based on current week
const RELEASE_NUMBER = `${currentDate.getFullYear()}${(currentDate.getMonth() + 1).toString().padStart(2, '0')}_${currentWeek}`;

if (process.env.NODE_ENV === 'production') {
  const currentWebsite = process.env.VUE_APP_CURRENT_WEBSITE || 'LRP';

  register(`/service-worker.${currentWebsite}.js?v=${RELEASE_NUMBER}`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      );
      if ('serviceWorker' in navigator) {
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            command: 'updateCacheVersion'
          });
          console.log('Cache Version updated for offline use.');
        }
        if ('PeriodicSyncManager' in window) {
          navigator.serviceWorker.ready.then(async (registration) => {
            try {
              await registration.periodicSync.register({
                tag: 'lrp-periodic-sync',
                minInterval: 24 * 60 * 60 * 1000, // 1 day
              });
              console.log('Periodic sync registered!');
            } catch (error) {
              console.log('Periodic sync could not be registered!', error.message);
              console.log('Please check browser permissions and compatibility.');
            }
          });
        } else {
          console.log('Periodic Sync API not supported.');
        }
      }
    },
    cached () {
      console.log('Content has been cached for offline use.');
    },
    updated(registration) {
      console.log('New content is available; please refresh.');
      caches.keys().then(function(names) {
        for (const name of names) {
          caches.delete(name);
        }
      });
      registration.unregister().then(() => {
        window.location.reload();
      });
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
    }
  });
  // Add event listener to handle messages from service worker
  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('message', (event) => {
      console.log('Message from service worker:', event.data);
    });
  }
}
