import { toQueryString } from './to-querystring';
const CACHE = {};

export async function getConcerns(ItemId = '') {
  const params = toQueryString({ ItemId });
  const url = `/api/dermadvicefilter/loadmorearticle`;
  const cacheKey = `${url}${params}`;

  if (CACHE[cacheKey]) {
    return await CACHE[cacheKey];
  }

  const response = await fetch(url, {
    method: 'POST',
    body: params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  });

  let concerns = await response.json();

  CACHE[cacheKey] = concerns;

  return concerns;
}
