<template>
  <div class="footer-banner">
    <picture class="footer-banner__image">
      <source :srcset="initialData.Image" media="(min-width: 48em)">
      <source :srcset="initialData.MobileImage" media="(max-width: 48em)">
      <img :src="initialData.MobileImage" :alt="!smallScreen ? initialData.ImageAlt : initialData.MobileImageAlt" width="300" height="200" loading="lazy" fetchpriority="low">
    </picture>
    <div class="footer-banner__content">
      <div class="footer-banner__content-title">
        <h2 v-html="initialData.Title"></h2>
      </div>
      <div class="footer-banner__content-content">
        <div v-html="initialData.Text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footer-banner',
  components: {
    'responsive-image': () => import('@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue')
  },
  props: {
    initialData: {
      type: Object,
      default() {
        return undefined;
      }
    },
    pageCategory: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches
    }
  },

}
</script>
<style lang="scss" src="./footer-banner.scss"></style>
