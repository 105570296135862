<template>
<div class='brand-hub'>
  <vue-scroll-snap :fullscreen="true"   ref="brandhubref">
    <div id="brandhubscroll">
      <div
        class="item"
        :key="index"
        v-for="(brandhub, index) in initialData.BrandHubSectionList"
        :id="'index'+index"
        :data-index="index"
        :data-tagging="scrollTagging(brandhub.ScrollEventTagging)">
        <div class="brand_content__hub brand_content__hub__section"  >
            <div class="brand_content__hub__section__item" >
                  <div class="brand_content__hub__image " :style="bgimage(brandhub.DesktopBackgroundImageSrc, brandhub.MobileBackgroundImageSrc)">
                      <img :src="brandhub.DesktopBackgroundImageSrc" class="desktop" :alt="brandhub.DesktopBackgroundImageAlt" v-if="!smallScreen"  />
                      <img :src="brandhub.MobileBackgroundImageSrc" class="mobile" :alt="brandhub.DesktopBackgroundImageAlt" v-if="smallScreen" />
                      <div class="brand_hub_svg_top">
                        <svg width="2px" height="219px" viewBox="0 0 2 219" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g id="DESKTOP-&amp;-MOBILE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,12" stroke-linecap="round" stroke-linejoin="round">
                            <g id="BRAND-HUB_V4.3" transform="translate(-719.000000, -870.000000)" stroke="#FFFFFF" stroke-width="2">
                                <g id="IMG" transform="translate(0.000000, 864.000000)">
                                    <line x1="720" y1="-5" x2="720" y2="224" id="Line-2-Copy"></line>
                                </g>
                            </g>
                        </g>
                        </svg>
                      </div>
                        <div class="brand_content__hub__text" :class="'index--'+index" data-aos="fade-down" data-aos-duration="1000"  >
                          <div class="brand_content__hub__text__title" v-html="brandhub.Title" >{{smallScreen}}
                          </div>
                          <div class="brand_content__hub__text__description"   v-html="brandhub.Description">
                          </div>
                          <div class="brand_content__hub__text__cta btn" @click="addclass(index), tagging(brandhub.CTAEventTagging)"  v-if="brandhub.IsSlider && brandhub.CTALabel!=''">
                          <span class="btn__wrapper" v-html="ltrim(brandhub.CTALabel)" >

                          </span>
                          </div>
                          <div class="brand_content__hub__text__cta btn" v-if="brandhub.EnableNewsletter && brandhub.CTALabel!='' ">
                          <a :href="brandhub.NewsletterLink" @click="tagging(brandhub.CTAEventTagging)" target="_blank"><span class="btn__wrapper">
                          {{brandhub.CTALabel}}
                          </span></a>
                          </div>
                        </div>

                        <div class="brand_hub_svg_bottom">
                          <svg width="2px" height="62px" viewBox="0 0 2 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <title>Line</title>
                              <g id="DESKTOP-&amp;-MOBILE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,12" stroke-linecap="round" stroke-linejoin="round">
                                  <g id="BRAND-HUB_V4.3" transform="translate(-719.000000, -2221.000000)" stroke="#FFFFFF" stroke-width="2">
                                      <g id="IMG-Copy" transform="translate(0.000000, 2221.000000)">
                                          <line x1="720" y1="1.13686838e-13" x2="720" y2="71" id="Line"></line>
                                      </g>
                                  </g>
                              </g>
                          </svg>
                        </div>
                   </div>

               </div>


         <!--slider-->
         <div class="brand_content__hub__slider" style="display:none" :style="bgimage(brandhub.DesktopBackgroundImageSrc, brandhub.MobileBackgroundImageSrc)"   v-if="brandhub.IsSlider"  >
            <div>
              <div class="brand_hub_svg_slider">
                <svg width="2px" height="20px" viewBox="0 0 2 62" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Line</title>
                    <g id="DESKTOP-&amp;-MOBILE" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,12" stroke-linecap="round" stroke-linejoin="round">
                        <g id="BRAND-HUB_V4.3" transform="translate(-719.000000, -2221.000000)" stroke="#FFFFFF" stroke-width="2">
                            <g id="IMG-Copy" transform="translate(0.000000, 2221.000000)">
                                <line x1="720" y1="1.13686838e-13" x2="720" y2="71" id="Line"></line>
                            </g>
                        </g>
                    </g>
                </svg>
              </div>
             <div class="brand_slider_close" @click="removeExstingclass"> <span>X</span> </div>


            <div class="brand_content__hub__text__slider_title" v-html="brandhub.Title" ></div>
               <slider @pageChange="whenPageChange($event,brandhub.Title,brandhub.BrandHubSliderList,brandhub.CTAEventTagging)" :per-page-custom="[[320, 1],[390, 1], [1024, 3]]" :is-pagination-clickable="false" :loop="false" :with-focusable-items="true" :scroll-per-page="true" :start-at="0" :navigation-enabled="true" :pagination-enabled="true" :swipe-on-desktop="false" :class="getlength(brandhub.BrandHubSliderList)" ref="slider">
                      <slide v-for="(brandhubcarousel, index) in brandhub.BrandHubSliderList" :key="index" >
                        <a :href="brandhubcarousel.SliderCTALink" @click="gatagging(brandhubcarousel,brandhub.CTAEventTagging)" target="_blank" class="card-shop" panel-focusable="">
                                    <div class="card-shop__img-wrapper ">
                                          <img :src="brandhubcarousel.SliderDesktopBackgroundImageSrc"  class="card-shop__img">
                                    </div>
                                    <div class="brand_content__hub__slider_title" v-html="titleWrap(brandhubcarousel.SliderTitle)">
                                    </div>
                                    <div class="brand_content__hub__slider_des" v-html="descriptionWrap(brandhubcarousel.SliderDescription)">
                                    </div>

                                    <div class="btn">

                                        <span class="btn__wrapper">
                                              <span class="btn__text">
                                              {{brandhubcarousel.SliderCTALabel}}
                                              </span>
                                          </span>

                                    </div>
                              </a>
                       </slide>

                </slider>
             </div>
        </div>
        </div>
      </div>
    </div>
  </vue-scroll-snap>
</div>
</template>
<script>

import VueScrollSnap from "@Feature/VueScrollSnap/code/Scripts/components/vue-scroll-snap.vue";
import { tag,AnalyticsHandler } from '@Foundation/analyticsHandler';
import Panel from '@Feature/Panel/code/Scripts/components/panel.vue';
import Slide from '@Feature/Slider/code/Scripts/components/slide/slide';
import Slider from '@Feature/Slider/code/Scripts/components/slider/slider';
import AOS from 'aos';


export default {
  name: 'brand-content-hub',
  components: { VueScrollSnap, Panel,Slide,Slider,AOS},
  directives: { tag },
  props: {
      initialData: { type: [Object,Array], required: false },
      a11yNextPanelText: { type: String, required: false, default: 'Next panel' },
    a11yPreviousPanelText: { type: String, required: false, default: 'Previous panel' },
    },
   data() {
      return {
       smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
        currentElementId: "",
        isTrigger: true,
        indexArray: [],
        scrollUp: false
      };
    },
     created() {
        AOS.init();
    },
    mounted() {
      this.data = this.initialData.BrandHubSectionList;
      window.dataLayer.push({ ...this.data[0].ScrollEventTagging});
      document.querySelector('.breadcrumb')?.classList.add('bredcrumb-hide');
      var lastScrollTop1 = 0;
      window.addEventListener('touchstart', (event) => {
         var myElement = document.getElementById('index0');
         var bounding = myElement.getBoundingClientRect();


         var st1 = window.pageYOffset || document.documentElement.scrollTop;
         if (st1 > lastScrollTop1){
           document.querySelector('.main-menu').classList.remove('main-menu-hide');
          }
         else {
              document.querySelector('.main-menu').classList.add('main-menu-hide');
          }
         if (bounding.top <= 10 && bounding.top >= 0 ) { document.querySelector('.main-menu').classList.remove('main-menu-hide'); }

        lastScrollTop1 = st1 <= 0 ? 0 : st1;
      },false);
      var lastScrollTop = 0;
       window.addEventListener('wheel', event => {
         var myElement = document.getElementById('index0');
         var bounding = myElement.getBoundingClientRect();

          if (bounding.top <= 10 && bounding.top >= 0 ) { document.querySelector('.main-menu')?.classList.remove('main-menu-hide'); }


         var st = window.pageYOffset || document.documentElement.scrollTop;
         if (st > lastScrollTop){

            document.querySelector('.main-menu')?.classList.remove('main-menu-hide');

          } else {

              document.querySelector('.main-menu')?.classList.add('main-menu-hide');

          }
          if (bounding.top <= 10 && bounding.top >= 0 ) { document.querySelector('.main-menu')?.classList.remove('main-menu-hide'); }
         lastScrollTop = st <= 0 ? 0 : st;
         //this.scrollUp = event?.wheelDelta > 0 || event?.deltaY < 0
        }, false);
      this.$refs.brandhubref.$el.addEventListener('scroll', this.handleScroll);


    },
    methods: {
      whenPageChange(currentPage,sliderTitle,data,ctatagging) {
        let slidenumber = currentPage + 1;
        let title = [];
        let maintitle = "none";
        let slidetitle = "none";
        if(this.smallScreen) {
          title = data[currentPage];
        }else {
          if(slidenumber == 1) {
            title = data[0]         
          }else {
            let index = (slidenumber * 3) - 2;
            title = data[index-1]; 
          }
        }
        if(this.decodeHtmlstring(sliderTitle) !=''){
            maintitle = this.decodeHtmlstring(sliderTitle).toLowerCase();
            maintitle.replace(/\s+/g, ' ').trim();
        }        
        slidetitle = this.decodeHtmlstring(title?.SliderTitle).toLowerCase();
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name': 'slider_button_click',
          'eventCategory': ctatagging.eventCategory.toLowerCase(),  
          'eventAction': 'select::slider navigation elements',
          'eventLabel': `${maintitle}::${slidetitle}::${slidenumber}`, 
          'cta_name': `${maintitle}::${slidetitle}::${slidenumber}`, 
          'module_name': `slider navigate::${maintitle}`,
        });
      },
      decodeHtmlstring(html) {
        let div = document.createElement("div");
        div.innerHTML = html;
        let datas=div.innerText.replaceAll('\n', ' ');
        return datas;
      },
      gatagging(data,ctatagging) {
        let obj = {
            "event": "uaevent",
            "ecommerce": "undefined",
            "eventCategory": ctatagging.eventCategory.toLowerCase(),
            "eventAction": "select::brand content hub",
            "eventLabel": data.SliderCTALabel.toLowerCase()+"|"+data.SliderCTALink
        };
        window.dataLayer.push(obj);
      },
       scrollTagging(tagging){
        return JSON.stringify(tagging);

      },
     ltrim(str) {
      if(!str) return str;
      return str.replace(/^\s+/g, '');
      },
       decodeEntity(inputStr) {
          var textarea = document.createElement("textarea");
          textarea.innerHTML = inputStr;
          return textarea.value;
      },
      getlength(param){
        if(!this.smallScreen){
            if(param){
              if(param.length == 2){
                 return 'slider-two';
              }

            }
        }

      },
      titleWrap(title) {
        const limit = 50;
        if (title.length > limit) {
          return title.substring(0, limit) + '';
        } else {
          return title;
        }

      },
       descriptionWrap(title) {
         var decodedvalue = this.decodeEntity(title);
        const limit = 148;
        if (title.length > limit) {
          return decodedvalue.substring(0, limit) + '...';
        } else {
          return decodedvalue;
        }

      },
      handleScroll(){
       AOS.init();
        var myElement = document.getElementById('index0');
        var bounding = myElement.getBoundingClientRect();

        if (bounding.top <= 10 && bounding.top >= 0 ) {
          document.querySelector('.main-menu')?.classList.remove('main-menu-hide');
        }

        Array.from(document.querySelectorAll('.item')).forEach(function(button) {

            var id = button.id
            var elm =id;
            var elementTop = document.querySelector('#'+elm).getBoundingClientRect().top;
            var elementBottom = elementTop + document.querySelector('#'+elm).clientHeight;
            var viewportTop = Math.round(window.scrollY);
            var viewportBottom = viewportTop + window.screen.height;
            if(elementBottom > viewportTop && elementTop < viewportBottom){

              var tagging = document.getElementById(elm).getAttribute('data-tagging');
              if(tagging){
                /*var scrollTagging =JSON.parse(tagging);

                window.dataLayer.push({ ...JSON.parse(tagging)}); */
              }
              if(elm  <= 1){

                document.querySelector('.main-menu')?.classList.remove('main-menu-hide');

              }
              if(elm  >2){

                document.querySelector('.main-menu')?.classList.add('main-menu-hide');
              }

            }

            });


        if (bounding.top <= 10 && bounding.top >= 0 ) {
          document.querySelector('.main-menu')?.classList.remove('main-menu-hide');
        }
        let allElements = document.querySelector('#brandhubscroll');
        if(this.isTrigger) {
            let _self = this;
            allElements.childNodes.forEach(function(ele, index){
              let elementId = "index"+index;
              function callback(entries) {
                  entries.forEach((entry) => {
                    let str = (index + 1 ).toString();
                    if(_self.currentElementId != elementId && index!=0 && !_self.indexArray.includes(str)) { 
                        if(entry.isIntersecting) {
                          let tagging = ele.getAttribute("data-tagging");
                          window.dataLayer.push(JSON.parse(tagging));
                          _self.isTrigger = false;
                          _self.currentElementId = elementId;
                          let eventlabel = JSON.parse(tagging).eventLabel;
                          var result = eventlabel.split('::');
                          var slidenumber = result[0];
                          _self.indexArray.push(slidenumber);
                        }
                    }
                  });
              };
              function createObserver(ele, callback) {
                const options = {
                    root: null,
                    threshold: 0.25
                };
                const observer = new IntersectionObserver(callback, options);
                observer.observe(ele);
              };
              createObserver(ele, callback);
            });
        }
      },

      taggingScroll(tagging){
        window.dataLayer.push({ ...tagging});

      },
      bgimage(src,mobileimageUrl){
         var imgurl=`background-image:url('${src}')`;
          if(this.smallScreen === true){
            imgurl=`background-image:url('${mobileimageUrl}')`;
          }
          return imgurl;
      },
      tagging(tagging){
        window.dataLayer.push({ ...tagging});

      },
      removeExstingclass(){

        var allElements = document.querySelectorAll(".deactive-current-card");
        let i=0;
        for(i=0; i<allElements.length; i++)
        {
          allElements[i].classList.remove('deactive-current-card');
        }
        var allElementslider = document.querySelectorAll('.active-current-slider');
        let j=0;
        for(j=0; j<allElementslider.length; j++)
        {
          allElementslider[j].classList.remove('active-current-slider');
        }

      },
      addclass(index){
        // AOS.init();
        this.removeExstingclass();
        document.querySelectorAll('.brand_content__hub__section')[index].classList.add('deactive-current-card--');
        let self = document.querySelectorAll('.brand_content__hub__section')[index]
        self.querySelector('.brand_content__hub__section__item').classList.add('deactive-current-card');
        self.querySelector('.brand_content__hub__slider').classList.add('active-current-slider');
        self.querySelector('.brand_content__hub__slider').classList.remove('hide');

      },
        isUsingKeyboard() {
        return document.body.classList.contains('is-using-keyboard');
      },

      a11ySlider() {
        const slidesOnPage = this.$refs.slider.currentPerPage;
        const currentSlide = this.$refs.slider.currentPage;
        /* istanbul ignore else */
        if (this.isUsingKeyboard()) {
          this.activeShade = currentSlide * slidesOnPage;
          this.$nextTick();
        }
      },


    }

}
</script>
<style lang="scss" src="./brand-content-hub.scss"></style>
<style lang="scss" src="./aos.scss"></style>
