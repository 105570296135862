/**
 * This function add an active class to the menu item clicked
 * @param {object} el - The element clicked (button inside nav__item)
 * @param {object} oPar - Parent of el (nav__item) that needs the active class
 * @param {object} oNav - Nav element that is needed to find active item
 * @param {object} oActive - Item that is active (if any)
 */

export const addActive = { // eslint-disable-line
  name: 'add-active',
  bind: (el) => {
    el.addEventListener('click', () => {
      const oPar = el.parentNode;
      const oActive = document.querySelector('.nav__item.is-active');

      /**
       * If one item is active, we remove the active item
       * And add it to the one click
       */
      if (oActive) {
        oActive.classList.remove('is-active');
        oActive.setAttribute('aria-expanded', false);
        oActive.querySelector('.nav__sublist').setAttribute('aria-hidden', true);
        oPar.classList.add('is-active');
        oPar.setAttribute('aria-expanded', true);
        oPar.querySelector('.nav__sublist').setAttribute('aria-hidden', false);
      } else {
        /**
         * Add active to the item clicked if none is active
         */
        oPar.classList.add('is-active');
        oPar.setAttribute('aria-expanded', true);
        oPar.querySelector('.nav__sublist').setAttribute('aria-hidden', false);
      }
    });
  }
};
