<template>
    <div class="scroll-snap-container" :class="{'fullscreen': fullscreen, 'horizontal': horizontal }">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "VueScrollSnap",
        props: {
            fullscreen: {
                type: Boolean,
                default: false,
                required: false
            },
            horizontal: {
                type: Boolean,
                default: false,
                required: false
            }
        }
    };
</script>
<style lang="scss" src="./vue-scroll-snap.scss"></style>