export function inViewport(element) {
  const rect = element.getBoundingClientRect();

  const isInViewPort =
    rect.left >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth);

  return isInViewPort;
}
