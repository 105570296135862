/** The fallback truncate length */
export const defaultTruncateLength = 150;

/**
 * @desc Returns a string with three dots if it exceeds the limit given
 * @function truncate
 * @param {string} str - The string to truncate
 * @param {number} limit - The limit after the string will be truncated
 * @return {string} The truncated string
 */
export function truncate(str, limit = defaultTruncateLength, dots = '...') {
  return str.length > limit ? str.trim().slice(0, limit) + dots : str;
}

export const ellipsis = {
  name: 'ellipsis',
  bind: (el, binding) => {
    const element = el;
    const truncateLength = binding.expression;

    /** Replace the innerhtml with the truncated string */
    element.innerHTML = truncate(element.textContent, truncateLength);
  },
};
