/**
 * This function add an active class to the submenu item clicked
 * @param {object} el - The element clicked (button inside nav__item)
 * @param {object} oPar - Parent of el (nav__item) that needs the active class
 */

export const addSubActive = { // eslint-disable-line
  name: 'add-sub-active',
  bind: (el) => {
    el.addEventListener('click', () => {
      const oPar = el.parentNode;

      if (oPar.classList.contains('is-active')) {
        oPar.classList.remove('is-active');
        oPar.setAttribute('aria-expanded', false);
        oPar.querySelector('.nav__deeplist').setAttribute('aria-hidden', true);
      } else {
        oPar.classList.add('is-active');
        oPar.setAttribute('aria-expanded', true);
        oPar.querySelector('.nav__deeplist').setAttribute('aria-hidden', false);
      }
    });
  }
};
