<template>
  <div class="banner__big-quote" :class="backgroundColor">
    <div class="banner__big-quote-container">
      <p class="banner__big-quote_content" :class="[!smallScreen ? titleHnTag : '']" v-html="decodeHtml(title)"/>
      <div v-if="authorName || authorIcon" class="banner__big-quote_author">
        <img v-if="authorIcon" :src="authorIcon" :alt="altText">
        <div class="banner__big-quote_author-desc">
          <p v-if="authorName" :style="[authorIcon ? {'text-align': 'inherit'}: {'text-align': 'center'}]" class="banner__big-quote_author-title" v-html="decodeHtml(authorName)"/>
          <p v-if="authorDescription" :style="[authorIcon ? {'text-align': 'inherit'}: {'text-align': 'center'}]" class="banner__big-quote_author-description" v-html="decodeHtml(authorDescription)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'big-quote',

    props: {
      titleHnTag: { type: String, required: true, default: 'is-2' },
      altText: { type: String, required: false, default: 'Big Quote' },
      title: { type: String, required: true, default: 'Combined with a daily SPF lotion' },
      authorIcon: { type: String, required: false, default: '' },
      authorName: { type: String, required: false, default: '' },
      backgroundColor: { type: String, required: false, default: '' },
      authorDescription: { type: String, required: false, default: '' },
    },
    data() {
      return {
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      };
    },
    methods: {
      decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      }
    }
  };
</script>
