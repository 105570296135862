<template>
  <div ref="dropdownFilter" class="safe-space-filter">
    <h1 v-if="mainTitle" class="safe-space__main-title" v-html="mainTitle"></h1>
    <div class="safe-space-filter__container" :class="isSticky ? 'ss-filter_sticky' : ''">
      <button
        class="safe-space-filter__button"
        :class="[isRtl ? 'rtl_button' : '']"
        @click="(isListOpen = !isListOpen), setScroll()"
        ref="filterDropdown"
      >
        {{ truncateString((selectedItem.TerritoryItemid ? selectedItem.TerritoryTittle : filterLabel), 27) }}
        <svg :class="isListOpen && isSticky ? 'arrow--up' : ''" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9248 8.42397L12.5007 16.8482L4.07628 8.42398L4.92479 7.57544L12.5007 15.1512L20.0763 7.57545L20.9248 8.42397Z" fill="black"/>
        </svg>
      </button>
      <div
        v-if="isListOpen"
        class="safe-space-filter__list"
        :class="[isSticky ? 'ss-filter--bottom' : '']"
      >
        <ul
          class="safe-space-filter__list--ul"
          :class="[filterList.length > 4 && isOncology ? 'safe-space-filter__list--four' : 'safe-space-filter__list--five']"
        >
          <template v-for="(filter, index) in fitlerItemList">
            <li
              v-if="filter.show"
              :key="index"
              class="safe-space-filter__list--item"
              :class="[selectedItem.TerritoryItemid === filter.TerritoryItemid ? 'active' : '', isRtl ? 'rtl-list-item' : '']"
              @click="selectFilter(filter)"
            >
              <a href="javascript:void(0);">
                {{ truncateString(filter.TerritoryTittle, 50) }}
                <svg
                  v-if="selectedItem.TerritoryItemid === filter.TerritoryItemid" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.501 6.20605L8.16835 14.5534L3.44824 8.81776L4.22039 8.18232L8.27836 13.1134L16.8343 5.46069L17.501 6.20605Z" fill="black"/>
                </svg>
              </a>
            </li>
          </template>
        </ul>
        <div
          v-if="oncologyDetails && oncologyDetails.Link !== ''"
          class="safe-space-filter__list--sticky"
          :class="isRtl ? 'filter-sticky-rtl' : ''"
        >
          <a
            :href="oncologyDetails.Link"
            :target="oncologyDetails.LinkType === 'external' ? '_blank' : '_self'"
            @click="tagging(oncologyDetails.CtaLabel)"
          >
            {{ oncologyDetails.CtaLabel }}
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M20.7314 11.9999L16.7314 8L15.9975 8.73399L18.7446 11.4811L4.2683 11.4811V12.5191L18.7443 12.5191L15.9975 15.266L16.7314 16L20.7314 11.9999Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';

export default {
  name: 'safe-space-filter',
  directives: {
    clickoutside,
  },
  props: {
    filterList: {
      type: Array,
      default: undefined,
    },
    oncologyDetails: {
      type: Object,
      default: undefined,
    },
    titleLength: {
      type: String,
      default: '45',
    },
    filterLabel: {
      type: String,
      default: 'Search By Your Concern',
    },
    mainTitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isListOpen: false,
      selectedItem: this.filterLabel ? this.filterLabel : '',
      isOncology: this.oncologyDetails && this.oncologyDetails.Link !== '',
      isSticky: false,
      isMobile: window.innerWidth <= 768,
      filterItems: this.filterList,
    };
  },
  computed: {
    fitlerItemList() {
      return this.filterList.map((item, index) => {
        return {
          ...item,
          show: !(index === 0 && this.selectedItem && !this.selectedItem.TerritoryItemid),
        };
      });
    },
    isRtl() {
      let elem = document.getElementById('main-container');
      let style = getComputedStyle(elem);
      return style.direction === 'rtl';
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const queryVal = urlParams.get('q');
    if (queryVal && queryVal !== '') {
      const filterObj = this.filterList.filter((item) => item.TerritoryTittle.toLowerCase() === queryVal)[0];
      this.selectedItem = filterObj;
      this.$nextTick(() => {
        window.eventBus.$emit('safeSpaceFilterSelected', false);
        this.getConcernData();
      });
    } else {
      this.$store.dispatch('storeInitialFilterData', this.filterList);
    }
    document.addEventListener('click', this.closeDropDown);
    if (this.isMobile) {
      const el = this.$refs.dropdownFilter;
      const observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            this.isSticky = false;
          } else {
            this.isSticky = true;
          }
        },
        {
          root: null,
          threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        }
      );
      observer.observe(el);
      document.addEventListener('scroll', this.mobileCloseDropdown);
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.mobileCloseDropdown);
    window.removeEventListener('click', this.closeDropDown);
  },
  methods: {
    selectFilter(item) {
      this.isListOpen = false;
      if (item.TerritoryItemid !== null && item.TerritoryItemid !== this.selectedItem.TerritoryItemid) {
        this.selectedItem = item;
        window.eventBus.$emit('safeSpaceFilterSelected', false);
        this.getConcernData();
      } else {
        this.selectedItem = this.filterList[0];
        window.history.pushState({}, null, window.location.href.split('?')[0]);
        window.eventBus.$emit('safeSpaceFilterSelected', true);
      }
      this.tagging(item.TerritoryTittle);
    },
    async getConcernData() {
      const payload = {
        isFilter: true,
        filterListId: this.selectedItem.TerritoryItemid,
        filterLabel: this.selectedItem.TerritoryTittle,
      };
      await this.$store.dispatch('fetchConcerns', payload);
    },
    truncateString(text, mlength) {
      const length = window.innerWidth <= 500 ? mlength : Number(this.titleLength);
      if (text && text.length >= Number(length) && (mlength != 50 || window.innerWidth <= 500)) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
    setScroll() {
      if (this.isListOpen) {
        this.$nextTick(() => {
          const getListItem = document.querySelectorAll('.safe-space-filter__list--item');
          if (getListItem) {
            let height = 0;
            getListItem.forEach((item, i) => {
              if ((this.isOncology && i < 4) || (!this.isOncology && i < 5)) {
                height = item.offsetHeight + height;
              }
            });
            document.querySelector('.safe-space-filter__list--ul').setAttribute('style', 'max-height:'+ height + 'px');
          }
        });
      }
    },
    closeDropDown(e) {
      if (!this.$refs.filterDropdown.contains(e.target) && this.isListOpen) {
        this.isListOpen = false;
      }
    },
    tagging(val) {
      const dataLayerObj = {
        event: 'uaevent',
        event_name: 'use_filters',
        ecommerce: 'undefined',
        eventCategory: '',
        eventAction: '',
        eventLabel: '',
        filter_type: 'filter',
        filter_value: 'concern::' + val.toLowerCase(),
      };
      AnalyticsHandler.pushDataLayer(dataLayerObj);
    },
    mobileCloseDropdown(e) {
      this.closeDropDown(e);
    },
  },
};
</script>
<style lang="scss" src="./safe-space-filter.scss"></style>
