/**
 * This function remove the active item to go back to nav (mobile)
 * @param {object} el - The element clicked (button inside nav__item)
 * @param {object} oActive - Item that is active (if any)
 */
import closest from '../helpers/closest';

export const goBack = { // eslint-disable-line
  name: 'go-back',
  bind: (el) => {
    el.addEventListener('click', () => {
      const oActive = closest(el, 'is-active');
      const oSublist = oActive.querySelector('.nav__sublist');

      oActive.classList.remove('is-active');
      oActive.setAttribute('aria-expanded', false);

      if (oSublist) {
        oSublist.setAttribute('aria-hidden', true);
      }
    });
  }
};
