<template>
  <fieldset
    class="color-variant-v2" :class="[colors.length == 1 ? 'singleColor' :'' ]">
    <legend
      class="is-sr-only"
      id="legend-shade">
      {{ legendName }}
    </legend>
    <div class="productItemId">{{ productid[activeShadeIndex].id }}</div>
    <div class="productItemActiveId">{{ sfcccolorsdetails[0].ean }}</div>
    <div class="color-variant__wrapper" v-if="colors.length > 1">
      <div
        class="select-custom"
        v-clickoutside="DropdownClose">

        <button
          class="select-custom__btn"
          :class="{
            'is-active': isDropdownOpened
          }"
          id="current-color"
          :aria-labelby="a11yAriaLabel"
          :aria-expanded="isDropdownOpened ? 'true' : 'false'"
          @click.prevent="dropdownToggle()"
          @blur="dropdownOnBlur(activeShade)"
          ref="dropdownBtn">
          <span class="color__shade main-shade" :style="{'background-color': activeShade !== undefined ? activeShade.hex : '' }"/>
          <span class="shade-name"> {{ activeShade.name }} </span>
          <svg width="16" height="8" aria-hidden="true">
            <path fill-rule="nonzero" d="M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z"/>
          </svg>
        </button>

        <transition name="main-menu-languages">
          <ul
            v-show="isDropdownOpened"
            @keyup.esc="dropdownToggle()" v-if="colors.length > 0">
            <li v-for="(color,index) in colors" :key="index" @click="dropdownOnBlur(color),  setAnalytics(color.tagging)">
              <span class="color__shade shade_dropdown" :style="{'background-color': colors.length > 0 ? colors[0].hex : '' }"/>
              <label
                :for="`color-shade-${index}`"
                :id="color.id"
                @click="DropdownClose()"
                class="color-variant__shade"
                :key="index">
                {{ color.name }}
              </label>
            </li>
          </ul>
        </transition>
      </div>
    </div>
    <p v-else class="color-variant__single-el">
      <span class="color__shade main-shade" :style="{'background-color': colors.length > 0 ? colors[0].hex : ''}"/>
      <span v-if="colors.length > 0"> {{ colors[0].name }} </span>
    </p>
  </fieldset>
</template>

<script>
  import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';

  export default {
    name: 'color-variant-v2',

    directives: {
      clickoutside,
    },

    props: {
      a11yClosePanelText: { type: String, required: false, default: 'Close panel' },
      a11yNextPanelText: { type: String, required: false, default: 'Next panel' },
      a11yPreviousPanelText: { type: String, required: false, default: 'Previous panel' },
      colors: { type: Array, required: true },
      productid: { type: Array, required: true },
      sfcccolorsdetails: { type: Array, required: true },
      legendName: { type: String, required: true, default: 'Shade' },
      a11yAriaLabel: { type: String, required: false, default: '' },
      popupDropdown: { type: Boolean,required: false,default: false }
    },

    data() {
      return {
        isDropdownOpened: false,
        activeShade: this.colors[0],
        activeShadeIndex: 0,
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      };
    },

    mounted() {
      window.eventBus.$on('showColorValue', payload => {
        this.activeShade = payload;
      });
      this.dropdownOnBlur(this.activeShade);
    },

    methods: {
      /**
       * Pushes given data to dataLayer
       * @param {object} dataObject - Data object to push
       * @returns {undefined}
       */
      DropdownClose() {
        /* istanbul ignore else */
        if (this.isDropdownOpened) {
          this.isDropdownOpened = false;
        }
      },

      setAnalytics(tagging) {
        if (tagging) {
          AnalyticsHandler.pushDataLayer(tagging);
        }
      },

      dropdownToggle() {
        if (!this.isDropdownOpened) {
          setTimeout(() => {
            this.isDropdownOpened = true;
            setTimeout(() => {
              /* istanbul ignore else */
              if (this.$el.querySelector('input[type="radio"]:checked')) {
                this.$el.querySelector('input[type="radio"]:checked').focus();
              }
            }, 50);
          }, 100);
        } else {
          this.DropdownClose();
        }
      },

      dropdownOnBlur(data) {
        setTimeout(() => {
          let activeElementClass;

          /* istanbul ignore next */
          if (document.documentMode || /Edge/.test(navigator.userAgent)) {
            /* eslint-disable prefer-destructuring */
            activeElementClass = document.activeElement.classList[0] || null;
          } else {
            activeElementClass = document.activeElement.classList.value;
            this.activeShade = data;
            window.eventBus.$emit('showColorValue', data);
            this.colors.forEach((element,index)=>{
              if (data.id == element.id) {
                this.activeShadeIndex = index;
              }
            });

          }
          /* istanbul ignore else */
          if (!(activeElementClass === 'js-select-option') && this.isDropdownOpened === true) {
            this.DropdownClose();
          }
        }, 300);
      },
    },
  };
</script>

<style lang='scss' src="./color-variant-v2.scss"></style>
