import { productsMapper } from './products-mapper';
import { toQueryString } from './to-querystring';

const CACHE = {};

/**
 * Fetch products list.
 * @returns {Promise<Product[]>}
 */
export async function getNewItems(url, query, type) {
  const queryParams = toQueryString(query);
  const apiUrl = `${window.location.origin}${url}?${queryParams}`;
  const cacheKey = `${apiUrl}`;

  if (CACHE[cacheKey]) {
    return await CACHE[cacheKey];
  }

  const response = await fetch(apiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    cache: 'no-store'
  });

  const items = await response.json();
  let itemsData;
  if (type !== 'product') {
    itemsData = items.Data;
  } else {
    itemsData = productsMapper(items.Data);
  }

  CACHE[cacheKey] = { data: itemsData, queryString: items.Query };

  return { data: itemsData, queryString: items.Query };
}
