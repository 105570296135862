<template>
  <div v-show="pageCount > 1" class="slider__pagination">
    <ul class="slider__dot-container" ref="pagList">
      <li class="slider__dot"
          :key="index"
          :class="{ 'slider__dot--active': (index === currentPage), 'clickable': isClickable }"
          :id="index+1" @click="navTagging('dot','dot',index - 1)" v-for="(page, index) in pageCount">
        <button v-if="isClickable"
                class="slider__dot-inner"
                @click="whenClickItem(index)"
                :aria-label="labels.pagination.ariaLabel.replace('{0}', index)"
                :aria-current="(index === currentPage)? true: ''"/>
        <span v-else class="slider__dot-inner"/>

      </li>
    </ul>
  </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
  name: 'pagination',
  props: {
    labels: {
      type: Object,
      required: true,
      default: undefined
    },
    taggingLabels: {
      type: Object,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    isClickable: {
      type: Boolean,
      required: false,
      default: true
    },
    navTaggingLabel: { type: [String, Array], required: false, default: '' },
    itemPerPageDesktop: { type: Number, required: true },
    pageCategory: { type: String, required: false, default: 'page category' },

  },
  data() {
    return {
      shiftAndTabHappened: false
    };
  },
  mounted() {
    this.setRole();
  },
  methods: {
    whenClickItem(index) {
      this.$emit('paginationClick', index);
    },
    setRole() {
      !this.isClickable ? this.$refs.pagList.setAttribute('role', 'none') : false;
    },
    generateSequence(start, end){
      var sequence = "";

      // Loop from start to end and push each number to the sequence array
      for (var i = start+1; i <= end; i++) {
        sequence += i+'-//-';
      }

      return sequence.slice(0,-4);;
    },
    navTagging(param,navigationEvent,index=null){
      const currentElemnt = this.$el;
      let activeSliderNumber = 0;
      let start =1;
      let end =1;
      let sliderValue =parseInt(activeSliderNumber);
      const slideNum = param === 'next' ? 1 : -1
      sliderValue +=  slideNum;
      let newvalue='';
      let sliderNumber =''
      if(this.smallScreen){
        start = parseInt(index);
        end = start +1;
        sliderNumber =end;
        newvalue= `${this.navTaggingLabel.slice(start, end)}`;
      }
      else{
        end = parseInt(index) *this.itemPerPageDesktop;
        start = end +this.itemPerPageDesktop;
        sliderNumber =end+1;
        newvalue= `${this.navTaggingLabel.slice(end, start).join('-//-')}`;
      }
      let arrayLenth = this.navTaggingLabel.slice(end, start).length;
      if(arrayLenth >1){
        sliderNumber=this.generateSequence(end, end+arrayLenth);
      }
      AnalyticsHandler.pushDataLayer({
        event: "uaevent",
        ecommerce: "undefined",
        event_name: "slider_button_click",
        eventCategory: this.pageCategory,
        eventAction: "select::slider navigation elements",
        eventLabel: `${navigationEvent}::${newvalue}::${sliderNumber}`,
        cta_name:  `${navigationEvent}::${newvalue}::${sliderNumber}`,
        module_name: `slider navigate::${navigationEvent}` });
    },
  }
};
</script>
