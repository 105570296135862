<template>
  <div class="collapse"
       :class="{ 'collapse--expanded': !collapsed || (mobileOnly && !smallScreen) }">

    <component :is="titleTagName" class="collapse__title">
      <template v-if="mobileOnly && !smallScreen">
        <slot name="title" :collapsed="collapsed"/>
      </template>

      <template v-else>
        <button @click="toggleCollapse()"
                data-toggle="collapse"
                :data-target="`#${id}`"
                :aria-expanded="`${ collapsed ? 'false' : 'true' }`"
                :aria-controls="id"
                :aria-label="(!collapsed ? ariaLabelClosed : ariaLabelOpened) || ariaLabel">
          <slot name="title" :collapsed="collapsed"/>
        </button>
      </template>
    </component>

    <div class="collapse__content" :id="id" ref="collapseContent">
      <div class="collapse__wrapper" ref="collapseWrapper">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '@EventBus';
  import { AnalyticsHandler, tag } from '@Foundation/analyticsHandler';

  export default {
    name: 'collapse',

    directives: {
      tag
    },

    props: {
      titleTagName: { type: String, required: false, default: 'div' },
      expanded: { type: Boolean, required: false, default: false },
      btnTagging: { type: Object, required: false, default: undefined },
      ariaLabel: { type: String, required: false, default: undefined },
      ariaLabelOpened: { type: String, required: false, default: undefined },
      ariaLabelClosed: { type: String, required: false, default: undefined },
      mobileOnly: { type: Boolean, required: false, default: false }
    },

    data() {
      return {
        collapsed: !this.expanded,
        id: `collapse${Math.floor(Math.random() * 1000) + 1}`,
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches
      };
    },

    watch: {
      collapsed() {
        this.updateContentSize();
      }
    },

    mounted() {
      eventBus.$on('mediaquery::changed', this.whenMediaQueryChange);

      this.updateContentSize();
    },

    methods: {
      whenMediaQueryChange() {
        this.smallScreen = !window.matchMedia('(min-width: 1024px)').matches;
        this.updateContentSize();
      },

      updateContentSize() {
        if ((this.mobileOnly && !this.smallScreen) || !this.$refs.collapseWrapper) {
          this.$refs.collapseContent.style.height = 'auto';
          this.$refs.collapseContent.style.visibility = 'visible';
        } else {
          const nHeight = this.$refs.collapseWrapper.getBoundingClientRect().height;
          this.$refs.collapseContent.style.height = this.collapsed ? 0 : `${nHeight}px`;
          this.$refs.collapseContent.style.visibility = this.collapsed ? 'hidden' : 'visible';
        }
      },

      extractGa4Properties() {
        const ga4_tags= ['event_name', 'link_url', 'cta_name', 'module_name', 'breadcrumb', 'click_area', 'product_tab', 'method', 'step', 'store_name', 'article_name', 'search_keyword', 'video_title', 'form_name'];  
        const o = {};
        for (const [k, v] of Object.entries(this.btnTagging)) {
           if (ga4_tags.includes(k)) {
               Object.assign(o,{[k]: this.btnTagging[k]});
             } 
          }
        return o; 
      }, 

      toggleCollapse() {
        this.collapsed = !this.collapsed;
        this.$emit('collapseClick', this.collapsed);
        if (this.btnTagging) {
          const dataObject = {
                event: 'uaevent',
                ecommerce: 'undefined',
                eventCategory: this.btnTagging.category,
                eventAction: this.btnTagging.action,
                eventLabel: this.btnTagging.label,
            }
            if (this.btnTagging.event_name) {
              const b = this.extractGa4Properties();
              Object.assign(dataObject, b);
              AnalyticsHandler.pushDataLayer(dataObject);
            }
            else {
              dataObject.event_name = 'product_info_click';
              dataObject.product_tab = this.btnTagging.action;
              dataObject.product_info = this.btnTagging.label+':'+this.btnTagging.productid;
              AnalyticsHandler.pushDataLayer(dataObject)
          }
        }
      }
    }
  };
</script>
<style lang="scss" src="./collapse.scss"></style>
