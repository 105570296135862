<template>
  <div ref="headRef" :class="className">
    <button
      :class="[{ 'is-active': active }, { on: clicked }]"
      class="scroll-to-top"
      @click="scrollToTop()"
    >
      <img class="vector default" :src="imageDefault" :alt="imageDefaultAlt" />
      <img class="vector hoverIcon" :src="imageHover" :alt="imageHoverAlt" />
    </button>
  </div>
</template>

<script>
import backtotopWhatsappScroll from '@Foundation/utilities/scroll/backtotopWhatsappScroll.js';
export default {
  name: 'back-to-top',
  mixins: [backtotopWhatsappScroll],
  props: {
    largeScreen: {
      type: String,
      default: undefined,
    },
    smallScreen: {
      type: String,
      default: undefined,
    },
    imageHover: {
      type: String,
      default: undefined,
    },
    imageDefault: {
      type: String,
      default: undefined,
    },
    imageHoverAlt: {
      type: String,
      default: undefined,
    },
    imageDefaultAlt: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      active: false,
      className: '',
      prev: window.pageYOffset,
      clicked: false,
    };
  },
  mounted() {
    if (this.largeScreen == 'true' && this.smallScreen == 'true') {
      this.className = 'bothScreen';
    } else if (this.largeScreen == 'true' && this.smallScreen == 'false') {
      this.className = 'largeScreen';
    } else if (this.largeScreen == 'false' && this.smallScreen == 'true') {
      this.className = 'smallScreen';
    }
    this.setFingerPrintPos();
    window.addEventListener('resize', this.setFingerPrintPos);
  },
  methods: {
    scrollToTop() {
      this.clicked = true;
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      this.clicked = false;
    },
    setFingerPrintPos() {
      let calc = document.querySelector('#main-container').getBoundingClientRect();
      let elem = document.getElementById('main-container');
      let style = getComputedStyle(elem);
      let isRTL = style.direction === 'rtl';
      if (isRTL) {
        document.getElementsByClassName('scroll-to-top')[0].style.left = `${calc.x + 30}px`;
      } else {
        document.getElementsByClassName('scroll-to-top')[0].style.right = `${calc.x + 30}px`;
      }
    },
  },
};
</script>
<style lang="scss" src="./back-to-top.scss"></style>
