import { eventBus } from '@EventBus';
import { MqHandler } from '@Foundation/mqHandler';
import { throttle } from '@Foundation/utilities';

export const sticky = {
  name: 'sticky',
  inserted(el, binding) {
    const stickyElm = el.querySelector(binding.value);

    let elRect;
    let elBottomPos;

    const onChange = () => {
      if (MqHandler.for('large')) {
        elRect = el.getBoundingClientRect();
        elBottomPos = elRect.bottom;

        if (elBottomPos < 0) {
          stickyElm.classList.add('is-sticky');
          el.style.height = `${elRect.height}px`;
        } else {
          stickyElm.classList.remove('is-sticky');
          el.style.height = 'auto';
        }
      } else {
        stickyElm.classList.remove('is-sticky');
      }
    };

    el.handler = throttle(onChange, 160);
    window.addEventListener('scroll', el.handler);
    eventBus.$on('mediaquery::changed', el.handler);
  },

  unbind(el) {
    window.removeEventListener('scroll', el.handler);
    eventBus.$off('mediaquery::changed', el.handler);
  }
};
