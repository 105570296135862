import { VueModule } from '@Foundation/utilities';
import SelectMultiple from './code/Scripts/components/select-multiple.vue';
import SelectOption from './code/Scripts/components/select-option.vue';

export default VueModule({
  components: {
    SelectMultiple,
    SelectOption
  }
});
