import Vue from 'vue';
import { getProducts,getFilterProducts } from '../services/products-service';
import { getNewItems } from '../services/update-list';
import { toQueryString } from '../services/to-querystring';

export const productsStore = {
  state: {
    avoidFetch: false,
    loading: false,
    isFilterClick: false,
    products: {},
    filterProducts: [],
    filters: {},
    elementToFocus: null,
    initialItemsSize: null,
    loadMoreQuery: {},
    listType: '',
    isLoadMoreDisabled: false,
    preferredProductVariant: {
      name: null,
      imgUrl: []
    },
    dataTrueFalse: [],
    dataQuestionAns: [],
    sortBy: '',
    productListId: '',
    takeCount: 12,
    skipCount: 12,
    productsList: [],
    initialData: [],
    stopAPIcall: false,
    noMoreProducts: false,
    totalProductCount: 0,
    ZeroProductMessage: '',
    filterSortCta: false,
    avoidDropdownRef: false,
    initialProductCount: 0
  },

  mutations: {
    filterSortCta(state,value) {
      state.filterSortCta = value
    },
    dataQuestionAns(state, value) {
      state.dataQuestionAns = value;
    },

    setDropdownRef(state, value) {
      state.avoidDropdownRef = value
    },

    dataTrueFalse(state, value) {
      state.dataTrueFalse = value;
    },

    loading(state, value) {
      state.loading = value;
    },

    isFilterClick(state, value) {
      state.isFilterClick = value;
    },

    avoidFetch(state, value) {
      state.avoidFetch = value;
    },

    filters(state, { type, value }) {
      Vue.set(state.filters, type, value);
    },

    deleteFilter(state, filterType) {
      Vue.delete(state.filters, filterType);
    },

    clearFilters(state) {
      Object.keys(state.filters).forEach((type) => {
        Vue.set(state.filters, type, []);
      });
    },

    products(state, { productListId, value }) {
      Vue.set(state.products, productListId, value);
    },

    setListType(state, value) {
      state.listType = value;
    },

    initialItemsSize(state, value) {
      state.initialItemsSize = value;
    },

    loadMoreQuery(state, value) {
      state.loadMoreQuery = value;
    },

    loadMoreDIsabled(state) {
      state.isLoadMoreDisabled = true;
    },

    updateList(state, { productListId, value }) {
      state.elementToFocus = state.products[productListId].length;
      value.forEach((item) => {
        state.products[productListId].push(item);
      });
    },

    deleteProducts(state, productListId) {
      Vue.delete(state.products, productListId);
    },

    clearProducts(state) {
      Object.keys(state.products).forEach((productListId) => {
        Vue.set(state.products, productListId, undefined);
      });
    },

    setPreferredProductVariant(state, variant) {
      state.preferredProductVariant = variant;
    },

     //Store initial data
     initialData(state,value) {
      value.forEach((data) =>{
        state.initialData.push(data);
      });
      state.initialData.forEach((data) =>{
        state.productsList.push(data);
      });
    },

    //Store productlist id
    productListId(state,value) {
     state.productListId = value;
    },

    totalProductCount(state,value) {
      state.totalProductCount = value;
    },

    zeroProductMessage(state, value) {
      state.ZeroProductMessage = value;
    },

    filterProducts(state,payload) {
      state.filterProducts = [];
      if(payload.isfilter && state.skipCount == 0) {
        state.productsList = [];
      }
      let rangelist = payload.products.RangeList;
      rangelist.forEach((value) => {
        state.filterProducts.push(value);
      }); 
      state.filterProducts.forEach((data) =>{
        state.productsList.push(data);
      });
    },

    // Store skipcount
    skipCount(state,isfilter) {
      //Reset skipcount
      if(isfilter) { 
        state.skipCount = 0;
      } else {
        let count = state.skipCount + 12;
        state.skipCount = count;
      }
    },

    stopAPIcall(state, payload) {
      state.stopAPIcall = payload;
    },

    noMoreProducts(state,payload) {
      state.noMoreProducts = payload;
    },

    sortBy(state, sortBy) {
      state.sortBy = sortBy;
    },

    clearProductsrange(state) {
      state.filterProducts = [];
      state.productsList = [];
      state.initialData.forEach((data)=>{
        state.productsList.push(data);
      });
      state.totalProductCount = state.initialProductCount
      state.skipCount = 12;
      state.sortBy = "";
    },

  },

  actions: {
    selectPreferredProductVariant({ commit }, variant) {
      const variantPassGate = {
        name: variant.color ? variant.color : '',
        imgUrl: variant.imgUrl ? variant.imgUrl : []
      };
      commit('setPreferredProductVariant', variantPassGate);
    },

    setFilterValue({ commit, dispatch }, payload) {
      (typeof payload.value == 'string') && (payload.value = payload.value.split());
      commit('filters', payload);
      dispatch('updateHistoryState');
    },

    setQuickFilterValue({commit, dispatch,state}, payload) {
      if(!(payload.type in  state.filters)) {
        state.filters[payload.type]=[];
      }
      Object.keys(state.filters).forEach((key,ele) =>{
        if(key == payload.type) {
          const index = state.filters[key].indexOf(payload.value);
          const includes = index > -1;
          if (!includes) {
            state.filters[key] = state.filters[key].concat([payload.value]);
            commit('filters', {type:key, value:state.filters[key]});
            dispatch('updateHistoryState');
          }else {
            const before = state.filters[key].slice(0, index);
            const after = state.filters[key].slice(index + 1, state.filters[key].length);
            //before.concat(after);
            commit('filters', {type:key,  value: before.concat(after)});
            dispatch('updateHistoryState');
          }
        }
      });
    },

    clearFilters({ commit, dispatch }) {
      commit('avoidFetch', true);
      commit('clearFilters');
      commit('clearProducts');
      dispatch('updateHistoryState');
      Vue.nextTick(() => {
        commit('avoidFetch', false);
      });
    },

    updateHistoryState({ state }) {
      const { products, filters, sortBy } = state;
      filters.sortBy = sortBy.split();  // Appending sortby also with the filters object for URI.
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${toQueryString(filters)}`;
      window.history.pushState({ products, filters }, '', newurl);
    },

    loadMoreUpdateHistory({ state }, query) {
      const { products, loadMoreQuery, isLoadMoreDisabled } = state;
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${query.params.replace('25', '')}`;
      window.history.pushState({ products, loadMoreQuery, isLoadMoreDisabled }, '', newurl);
    },

    loadMoreHistoryNavigation({ state }, payload) {
      const { products, initialItemsSize } = state;
      const { listId } = payload;

      window.onpopstate = (event) => {
        if (event.state) {
          products[listId] = event.state.products[listId];
          state.loadMoreQuery = event.state.loadMoreQuery;
          state.isLoadMoreDisabled = event.state.isLoadMoreDisabled;
        } else {
          products[listId] = products[listId].slice(0, initialItemsSize);
          state.loadMoreQuery.pageNbr = 2;
          state.isLoadMoreDisabled = false;
        }
      };
    },

    setState({ commit }, state) {
      /* istanbul ignore else */
      if (state && state.products) {
        const { products, filters } = state;
        commit('avoidFetch', true);

        Object.keys(products).forEach((productListId) => {
          commit('products', { productListId, value: products[productListId] });
        });

        Object.keys(filters).forEach((type) => {
          commit('filters', { type, value: filters[type] });
        });

        Vue.nextTick(() => commit('avoidFetch', false));
      }
    },

    async fetchProducts({ commit, state, dispatch }) {
      if (state.avoidFetch) {
        return;
      }

      commit('loading', true);
      const filters = { ...state.filters };
      const promises = Object
        .keys(state.products)
        .map(async (productListId) => {
          const products = await getProducts(productListId, filters);
          const productList = { productListId, value: products };

          commit('products', productList);

          return productList;
        });

      await Promise.all(promises);

      dispatch('updateHistoryState');

      commit('loading', false);
    },
    
    async fetchFilterProducts({ commit, state, dispatch }, isfilter) {
      if (state.avoidFetch) {
        return;
      }
      await commit('loading', true);
      //get request data
      const filters = { ...state.filters };
      if(isfilter == true) {
        commit('skipCount',isfilter);
        commit('isFilterClick', true);
      }
      const products = await getFilterProducts(filters,state.sortBy,state.productListId,state.skipCount,state.takeCount);
      if (typeof products === 'object' && products.RangeList.length !== 0) {
        commit('filterProducts',{products,isfilter});
        commit('noMoreProducts', false);
      }else if(isfilter && products.RangeList.length === 0) {
        commit('noMoreProducts', true);
      }
      commit('isFilterClick', false);
      commit('totalProductCount',products.TotalProductCount);
      commit('zeroProductMessage', products.ZeroProductMessage);
      
      if(products.RangeList.length < 12){
        commit('stopAPIcall', true);
      }else {
        commit('skipCount',false);
        commit('stopAPIcall', false);
      }

      // dispatch('updateHistoryState');
      commit('loading', false);
    },

    clearFiltersProducts({ commit, dispatch }) {
      commit('avoidFetch', true);
      commit('clearFilters');
      commit('clearProductsrange');
      dispatch('updateHistoryState');
      commit('stopAPIcall',false);
      commit('noMoreProducts', false);
      Vue.nextTick(() => {
        commit('avoidFetch', false);
      });
    },

    storeInitialData({commit,state,dispatch},initialData) {
      commit('initialData', initialData);
    },

    storeTotalProductCount({commit,state,dispatch},productcount) {
      state.initialProductCount = productcount;
      commit('totalProductCount', productcount);
    },

    storeZeroProductMessage({commit}, zeroProductMsg) {
      commit('zeroProductMessage', zeroProductMsg);
    },

    storeProductListId({commit,state,dispatch}, productListId) {
      commit('productListId', productListId);
    },

    sortBy({ commit,dispatch}, payload) {
      commit('sortBy', payload);
      dispatch('updateHistoryState');
    },

    async loadMore({ state, commit, dispatch }, payload) {
      commit('loading', true);
      const items = await getNewItems(payload.url, payload.query, payload.type);
      const params = await toQueryString(state.loadMoreQuery);

      if (items.data !== null) {
        commit('updateList', { productListId: payload.listId, value: items.data });

        /* istanbul ignore else */
        if (items.data.length < state.loadMoreQuery.pageSize) {
          commit('loadMoreDIsabled');
        }

        dispatch('loadMoreUpdateHistory', {
          params
        });

        commit('loadMoreQuery', { q: payload.searchPageQuery, ...items.queryString });

        dispatch('loadMoreHistoryNavigation', payload);
      } else {
        commit('loadMoreDIsabled');
      }

      commit('loading', false);
    }
  },

  getters: {
    getDataTrueFalse: (state) => state.dataTrueFalse,
    getDataQuestionAns: (state) => state.dataQuestionAns,
    productsLoading: (state) => state.loading,
    getFilter: (state) => (filterType) => state.filters[filterType],
    getProducts: (state) => (productListId) => state.products[productListId],
    isLoadMoreDisabled: (state) => state.isLoadMoreDisabled,
    getLoadMoreQuery: (state) => state.loadMoreQuery,
    getItemToFocus: (state) => state.elementToFocus,
    getListType: (state) => state.listType,
    preferredProductVariant: (state) => state.preferredProductVariant,
    hasSelectedFilters: (state) => {
      return !!Object.keys(state.filters).find((key) => state.filters[key].length);
    },
    getSortBy: (state) => state.sortBy,
    getFilterProducts: (state)  => state.productsList,
    getIsFilterClick: (state) => state.isFilterClick,
    getStopApIcall: (state) => state.stopAPIcall,
    getNoMoreProducts: (state) => state.noMoreProducts,
    getToltalFilter: (state) => state.filters,
    getTotalProductCount: (state) => state.totalProductCount,
    getFilterSortCta: (state) => state.filterSortCta,
    getDropdownRefState: state => state.avoidDropdownRef
  }
};
