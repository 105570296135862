/**
 * InputHandler listens to keyboard and mouse events on the document.
 * It adds a classname to the body while the user is using keyboard navigation.
 * It removes the keyboard using classname when the user uses the mouse again.
 * @class InputHandler
 */
export default class InputHandler {
  /**
   * InputHandler constructor.
   * @constructs InputHandler
   */
  constructor() {
    /**
     * keyboardUsageClassName
     * The classname which is added to the body while using the keyboard
     * @type {string}
     * @member InputHandler#keyboardUsageClassName
     */
    this.keyboardUsageClassName = 'is-using-keyboard';

    /**
     * wasUsingTab
     * using tab keyboard state
     * @type {boolean}
     * @member InputHandler#wasUsingTab
     */
    this.wasUsingTab = false;

    /**
     * wasBound
     * eventListsners bound
     * @type {boolean}
     * @member InputHandler#wasBound
     */
    this.wasBound = false;

    /**
     * isTab
     * is using tab
     * @type {boolean}
     * @member InputHandler#isTab
     */
    this.isTab = false;

    // bind to keyboard and mouse events on init of the class
    this.bind();
  }

  /**
   * Binds event listeners for keydown and mousedown
   */
  bind() {
    document.addEventListener('keydown', this.handleKeyboardInput.bind(this), true);
    document.addEventListener('mousedown', this.handleMouseInput.bind(this), true);
    this.wasBound = true;
  }

  /**
   * Unbinds event listeners for keydown and mousedown
   */
  unbind() {
    document.removeEventListener('keydown', this.handleKeyboardInput.bind(this), true);
    document.removeEventListener('mousedown', this.handleMouseInput.bind(this), true);
    this.wasBound = false;
  }

  /**
   * listens to keyboard input and determines
   * if this.keyboardUsageClassName needs to be added to the body
   * @param {object} e - keyboard input event
   */
  handleKeyboardInput(e) {
    /* istanbul ignore next */
    this.isTab = e.key === 'Tab' || e.code === 'Tab' || e.keyCode === 9;
    /* istanbul ignore next */
    if (this.isTab && !this.wasUsingTab) {
      document.body.classList.add(this.keyboardUsageClassName);
    }
    this.wasUsingTab = this.isTab;
  }

  /**
   * listens to mouse input and determines
   * if this.keyboardUsageClassName needs to be removed from the body
   */
  handleMouseInput() {
    /* istanbul ignore next */
    if (this.wasUsingTab) {
      document.body.classList.remove(this.keyboardUsageClassName);
    }
    this.wasUsingTab = false;
  }

  static getInputHandler() {
    this.$inputHandler = this.$inputHandler || new InputHandler();
    return this.$inputHandler;
  }
}
