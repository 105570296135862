import { VueModule } from '@Foundation/utilities';
import Carousel from './code/Scripts/components/carousel';
import homePageCarousel from './code/Scripts/components/homePageCarousel.vue'

export default VueModule({
  components: {
    Carousel,
    homePageCarousel
  }
});
