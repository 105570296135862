<template> 
<div> 


 <div  v-for="(item,index) in initialData" v-if="item.variantid === selectedVariant" >
   <a :href="item.pharmacylink" target="_blank" @click="clickandCollectTag()" class="pharmacy-cta btn--black btn--icon btn--find"> 
     <img v-if="imageUrl" :src="imageUrl" :alt="dataAlttext" class="btn__icon" />
    <!--</a>-->
    <div :class="ctaClass">
      <span class="btn__wrapper"  :data-default-variant="item.defaultvariantid" :data-variant="item.variantid" >
          <span class="btn__text">
          <!--<a :href="item.pharmacylink" target="_blank" class="pharmacy-cta__url"> -->
            {{ctaLable}}
          </span>
      </span>
    </div>
    </a>
 </div>
 
 </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
  name: 'pharmacy-cta-button',
   props: {
    initialData: { type: Array, required: true },
    ctaLable: { type: String, required: false, default: 'Click and Collect' },
    imageUrl: { type: String, required: false,default:'#'},
    dataAlttext : { type: String, required: false},
    ctaClass: {type: String, default: 'cta cta--light cta--alt'},
    dataClickandCollectTagging: { type: Array, required: false, default: [] },
    
  },
   data() {
    return {
     
      selectedVariant: this.initialData[0].defaultvariantid
  
    };
  },
  mounted() {
    this.selectedVariant = this.initialData[0].defaultvariantid;
     window.eventBus.$emit('clickandcollectEnabled', true);
    window.eventBus.$on('SelectVarientPdpChangedAction', payload => {
        if (payload) {
            this.selectedVariant = payload;
        } 
      });
      window.eventBus.$on('SelectVarientPdpChangedActionColor', payload => {
        if (payload) {
            this.selectedVariant = payload;
        } 
      });

  },
methods:{
  clickandCollectTag(){
           AnalyticsHandler.pushDataLayer({ ...this.dataClickandCollectTagging[0].tagging});
  },
}
 
}
</script>