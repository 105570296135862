<template>
  <li class="slider__slide" @click="buynowOnChange(), emptyInventory();" :style="{ padding: `0 ${this.padding}px` }" :aria-current="current">
    <slot/>
  </li>
</template>

<script>
  export default {
    name: 'slide',
    props: {
      current: { type: Boolean, required: false },
      tagging: { type: Object, required: false, default() {
        return {};
      } }
    },
    data() {
      return {
        width: null,
        padding: this.$parent.slidePadding
      };
    },
    methods: {

      /* This function is to pass the API url */
      getData: function(url) {
        return fetch(url).then(response => response.json());
      },

      /* This function is to pass the API url --ends here */
      /* This function is to check empty inventory on variant change */
      emptyInventory: function () {
        const addToCart = document.getElementById('addtocartComponentLoader');
        if (addToCart) {
          addToCart.classList.add('active');
        }
        setTimeout(() => {
          var emptyProductItemIdActive;
          var emptyisColorVariantExist = document.getElementsByClassName('color-variant');
          if (emptyisColorVariantExist.length > 0) {
            emptyProductItemIdActive = document.getElementsByClassName('productItemActiveId')[1].innerHTML;
          } else {
            emptyProductItemIdActive = document.getElementsByClassName('productItemActiveId')[0].innerHTML;
          }
          this.getData(`/api/connector/productexistsininventory?productid=${emptyProductItemIdActive}`)
            .then(jsonData => {
              if (!(jsonData.orderable)) {
                document.getElementById('addtocartComponent').classList.add('disabledComponent');
              } else {
                var addtocartComponentData = document.getElementById('addtocartComponent');
                var priceContent = document.getElementById('sfccPrice');
                if (priceContent.innerHTML  !== '') {
                  addtocartComponentData.classList.remove('disabledComponent');
                  this.isMaxInventory();
                } else {
                  addtocartComponentData.classList.add('disabledComponent');
                }
              }
              document.getElementById('addtocartComponentLoader').classList.remove('active');
            });
        }, 300);
      },

      /* This function is to check empty inventory on variant change --ends here */

      /* This function is to check max inventory on variant change */
      isMaxInventory: function () {
        var emptyProductItemIdActive;
        var emptyisColorVariantExist = document.getElementsByClassName('color-variant');
        if (emptyisColorVariantExist.length > 0) {
          emptyProductItemIdActive = document.getElementsByClassName('productItemActiveId')[1].innerHTML;
        } else {
          emptyProductItemIdActive = document.getElementsByClassName('productItemActiveId')[0].innerHTML;
        }
        var ProductItemID = document.getElementsByClassName('productCart__item_ID');
        for (var i = 0; i < ProductItemID.length; i++) {
          var activeData = ProductItemID[i].textContent;
          if (emptyProductItemIdActive === activeData) {
            var theSelectData = document.getElementsByClassName('productCart__itemQuantitySelect')[i];
            var lastValue = theSelectData.options[theSelectData.options.length - 1].value;
            var selectedText = theSelectData.options[theSelectData.selectedIndex].value;
            if (selectedText >= lastValue) {
              document.getElementById('addtocartComponent').classList.add('disabledComponent');
            } else {
              document.getElementById('addtocartComponent').classList.remove('disabledComponent');
            }
          }
        }
      },

      /* This function is to check max inventory on variant change --ends here */
      buynowOnChange() {
        this.$parent.$parent.$emit('showRetailer', true);
      },
      addcartTaggingValue() {
        window.dataLayer.push(this.tagging);
      }
    }
  };
</script>
