/**
 *
 * @param eID
 * @returns {number}
 */
export function elmYPosition(eID) {
  const elm = eID;
  let y = elm.offsetTop;
  let node = elm;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  return y;
}
