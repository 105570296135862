export const noJsHandler = { // eslint-disable-line
  name: 'no-js-handler',
  bind: (el) => {
    const noScriptTags = el.querySelectorAll('noscript img');

    for (let index = 0; index < noScriptTags.length; index++) {
      noScriptTags[index].setAttribute('src', 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=');
    }
  }
};
