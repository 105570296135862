import { VueModule } from '@Foundation/utilities';
import ProductFilter from './code/Scripts/components/product-filter/product-filter.vue';
import ProductFilters from './code/Scripts/components/product-filters/product-filters.vue';
import ProductList from './code/Scripts/components/product-list/product-list.vue';
import ProductRangeList from './code/Scripts/components/product-range-list/product-range-list.vue';
import productCard from './code/Scripts/components/product-card/product-card.vue';
import ProductQuickSort from './code/Scripts/components/product-quick-sort/product-quick-sort.vue';
import ProductSort from './code/Scripts/components/product-sort/product-sort.vue';
import KeyIngredientsV2 from '../KeyIngredients-V2/code/Scripts/components/key-ingredients-v2.vue';
import ProductSlider from './code/Scripts/components/product-slider/product-slider.vue';
import medicalPopin from './code/Scripts/components/medical-popin/medical-popin.vue';

export * from './code/Scripts/store/products';

export default VueModule({
  components: {
    ProductFilter,
    ProductFilters,
    ProductList,
    ProductRangeList,
    productCard,
    ProductQuickSort,
    ProductSort,
    KeyIngredientsV2,
    ProductSlider,
    medicalPopin,
  },
});
