<template>
  <div ref="relSticky" :class="{ relsticky: !active, 'header-active': headerActive }" v-show="showJump">
    <nav role="navigation" class="jump-navbar"
      :class="{ 'stickyblock': active, 'mobile-sticky': listItemActive }"
      ref="sticky">
      <div class="jump-content">
        <swiper :options="swiperOption" class="swiper" ref="swiper" v-if="!mobileScreen">
          <div class="swiper-button-prev" slot="button-prev" @click="prev()"
            :class="{ 'swiper-button-disabled': swiperAtBegain }" />
          <swiper-slide v-for="(item, index) in Navlinks" :id="item.target" @click.native="slideClicked($event, index)"
            :data-index="index" :key="index + 0" v-tag:useractionevent="{
              action: `select::${index + 1}::${item.title.toLowerCase()}`,
              category: pageCategory,
              label: articleName(),
              event_name: 'body_button_click',
              module_name: 'article anchor click',
              cta_name: `${index + 1}::${item.title.toLowerCase()}`,
              link_url: location,
              article_name: articleName()
            }" :class="{ 'swiper-highligher': currentLink === index }">
            <h3 class="is-4"><span />
              <div :class="checkStringCountText(item.title)" v-html="item.title" />
            </h3>
          </swiper-slide>
          <div class="swiper-button-next" slot="button-next" @click="next()"
            :class="{ 'swiper-button-disabled': swiperAtEnd }" />
        </swiper>
        <div class="swiper-mobile" :class="{ 'swiper-arrow-active': listItemActive }" v-if="mobileScreen">
          <div class="swiper-mobile-wrapper" />
          <div class="select-custom" @click="activeListItem(listItemActive)">
            <button id="current-volume" aria-label="current-volume" aria-expanded="false" class="select-custom__btn"><svg
                width="16" height="8" aria-hidden="true">
                <path fill-rule="nonzero" d="M14.675.62l.65.76L8 7.659.675 1.379l.65-.759L8 6.341z" />
              </svg></button>
          </div>
          <swiper :options="swiperOption" v-show="listItemActive" class="swiper" ref="swiper">
            <swiper-slide v-for="(item, index) in Navlinks" :id="item.target"
              @click.native="slideClicked($event, index, true)" :data-index="index" :key="index + 0" v-tag:useractionevent="{
                action: `select::${index + 1}::${item.title.toLowerCase()}`,
                category: pageCategory,
                label: articleName(),
                event_name: 'body_button_click',
                module_name: 'article anchor click',
                cta_name: `${index + 1}::${item.title.toLowerCase()}`,
                link_url: location,
                article_name: articleName()
              }" :class="{ 'swiper-highligher swiper-highligher-blue ': currentLink === index }">
              <h3 class="is-4"><span />
                <div :class="checkStringCountText(item.title)" v-html="item.title" />
              </h3>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { tag } from '@Foundation/analyticsHandler';
import { eventBus } from '@EventBus';

export default {
  name: 'jump-to',
  directives: { tag },
  components: { Swiper, SwiperSlide },
  props: {
    traget: {
      type: String,
      default: 'jump-to-content',
    },
    pageCategory: {
      type: String,
      default: 'content page',
    }
  },
  data() {
    return {
      location: window.document.URL,
      showJump: true,
      activeCurrentItem: true,
      relSticky: 0,
      customOffsetValue: 100,
      active: false,
      headerActive: false,
      scrollEvent: true,
      currentLink: undefined,
      Navlinks: [],
      mobileScreen: window.matchMedia('(max-width: 992px)').matches,
      swiperAtEnd: false,
      swiperAtBegain: true,
      listItemActive: false,
      scrollDown: false,
      lastScrollPosition: 0,
      scrollOffset: 0,
      swiperOption: {
        pagination: '.swiper-pagination',
        watchOverflow: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          hiddenClass: '.swiper-button-hidden',
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          320: {
            slidesPerView: 1,
          },
        },
      },
      offset: 110,
      pagename: ''
    };
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeMount() {
    const vm = this;
    const element = document.getElementsByClassName('jump-to-content');
    if (element) {
      return Array.prototype.map.call(element, (elId) => {
        if (elId.dataset.target !== '') {
          vm.Navlinks.push({
            target: elId.dataset.target,
            title: elId.dataset.title,
          });
        }
      });
    }
  },

  mounted() {
    this.screenMobile();
    let lastScrollTop = 0;
    window.addEventListener('scroll',() => {
      this.scrollEvent = true;
      var st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        this.customOffsetValue = 100;
      } else {
        this.customOffsetValue = 130;
      }
      lastScrollTop = st <= 0 ? 0 : st;
    }, false);

    if (this.mobileScreen) {
      if (document.querySelector('.swiper-highligher')) {
        const innerText = document.querySelector('.swiper-highligher').innerHTML;
        document.querySelector('.swiper-mobile-wrapper').innerHTML = innerText;
      } else {
        const innerTextFirst = document.querySelector('.swiper-slide ').innerHTML;
        const newTextValue = this.removeTags(innerTextFirst);
        document.querySelector('.swiper-mobile-wrapper').innerHTML = '<div class="swiper-slide-mobile"><h4 class="is-4 2"><span>1</span><div class="swiper-title-text-two-line">' + newTextValue + '</div></h4></div>';
      }
    }
    this.lastScrollPosition = window.pageYOffset,
    window.addEventListener('scroll', this.handleScroll);
  },

  beforeUpdate() {
    this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
    this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    articleName() {
      let ele = document.getElementsByClassName("breadcrumb__item");
      if (ele != null) {
        let itemslength = ele.length;
        let itemdata = ele.length > 0 ? ele[itemslength - 1].innerHTML : '';
        return this.decodeHtmlstring(itemdata).toLowerCase();
      }
    },
    decodeHtmlstring(html) {
      let div = document.createElement("div");
      div.innerHTML = html;
      let datas = div.innerText.replaceAll('\n', ' ').trim();
      return datas;
    },
    checkStringCountText(title) {
      const strCount = title;
      if (strCount.length > 23) {
        return 'swiper-title-text-two';
      } else {
        return 'swiper-title-text';
      }
    },
    removeTags(str) {
      if ((str === null) || (str === '')) {
        return false;
      } else {
        str = str.toString();
      }
      return str.replace(/(<([^>]+)>)/ig, '');
    },
    activeListItem(status) {
      this.listItemActive = true;
      this.activeCurrentItem = false;
      if (status === true) {
        this.listItemActive = false;
      }
    },
    slideClicked($event, index, status = false) {
      // only for mobile
      if (status === true) {
        this.listItemActive = false;
        this.activeCurrentItem = true;
        this.active = true;
        const innerTextMobile = document.querySelectorAll('.swiper-slide')[index].innerHTML;
        const newTextValueMobile = this.removeTags(innerTextMobile);
        const indexValue = index + 1;
        this.offset = 90;
        let newTextValueMobileText = '';
        if (newTextValueMobile.length > 23) {
          newTextValueMobileText = '<div class="swiper-title-text-two-line">' + this.removeTags(newTextValueMobile) + '</div>';
        } else {
          newTextValueMobileText = '<div class="swiper-title-text-line">' + this.removeTags(newTextValueMobile) + '</div>';
        }
        document.querySelector('.swiper-mobile-wrapper').innerHTML = '<div class="swiper-slide-mobile"><h4 class="is-4"><span>' + indexValue + '</span>' + newTextValueMobileText + '</h4></div>';
      }
      // both desktop and mobile
      const element = document.querySelector('[data-target=' + $event.currentTarget.id + ']');
      if (element) {
        const elementRect = element.offsetTop, scrollPos = elementRect - this.offset;
        window.scrollTo({
          top: scrollPos,
          behavior: 'smooth',
        });
        this.currentLink = index;
        this.scrollEvent = false;
      }
    },
    handleScroll() {
      this.listItemActive = false;
      const stickyElement = this.$refs.relSticky,
        windowPosition = Math.round(window.scrollY),
        stickyContainer = document.querySelector('.jump-navbar');
      if (stickyElement && stickyContainer) {
        this.relSticky = stickyElement.getBoundingClientRect().top;
          const stickyContainerParent = stickyContainer.parentElement.offsetTop;
          if (stickyContainerParent >= windowPosition) {
            this.active = false;
            this.$refs.swiper.$swiper.slideTo(0);
        } else {
          this.active = true
        }
        this.headerActive = this.$parent.$children[0].showHeader
        if (this.scrollEvent === true) {
          this.updateHighlighter();
        }
        if (window.pageYOffset < 0) {
          return;
        }
        if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.scrollOffset) {
          return;
        }
        this.scrollDown = window.pageYOffset < this.lastScrollPosition;
        this.lastScrollPosition = window.pageYOffset;
      }
    },
    updateHighlighter() {
      const swiperCount = document.querySelectorAll('.swiper-slide').length,
        sections = document.querySelectorAll('.jump-to-content');
      const observer = new IntersectionObserver(entries => {
        entries.forEach((entry, index) => {
          if (index < entries.length - 1) {
            const currentBox = sections[index];
            const nextBox = sections[index + 1];
            if (entry.isIntersecting && entry.intersectionRatio >= 0.3) {
              currentBox.classList.add('visible');
              nextBox ? nextBox.classList.remove('visible') : '';
              if (this.currentLink > index) {
                this.prev();
              }
              if (index > this.currentLink) {
                this.next();
              }
              this.currentLink = index;
              if (swiperCount >= index + 1)  {
                if (swiperCount == index + 1) {
                  this.currentLink = index;
                }
                if (this.mobileScreen) {
                  const nevIndexValue = index + 1;
                  if (document.querySelector('.swiper-highligher')) {
                    const innerText = document.querySelector('.swiper-highligher').innerHTML;
                    let newTextValueMobile1 = '';
                    const textLength =  this.removeTags(innerText);
                    if (textLength.length > 23) {
                      newTextValueMobile1 = '<div class="swiper-title-text-two-line">' + this.removeTags(innerText) + '</div>';
                    } else {
                      newTextValueMobile1 = '<div class="swiper-title-text-line">' + this.removeTags(innerText) + '</div>';
                    }
                    document.querySelector('.swiper-mobile-wrapper').innerHTML = '<div class="swiper-slide-mobile"><h4 class="is-4"><span>' + nevIndexValue + '</span>' + newTextValueMobile1 + '</h4></div>';
                  } else {
                    const innerTextFirst = document.querySelector('.swiper-slide').innerHTML;
                    let scrollTextValueMobile = '';
                    const innerTextFirstLength = this.removeTags(innerTextFirst);
                    if (innerTextFirstLength.length > 52) {
                      scrollTextValueMobile  = '<div class="swiper-title-text-two-line">' + this.removeTags(innerTextFirst) + '</div>';
                    } else {
                      scrollTextValueMobile = '<div class="swiper-title-text-line">' + this.removeTags(innerTextFirst) + '</div>';
                    }
                    document.querySelector('.swiper-mobile-wrapper').innerHTML = '<div class="swiper-slide-mobile"><h4 class="is-4"><span>' + nevIndexValue + '</span>' + scrollTextValueMobile + '</h4></div>';
                  }
                }
              }
            } else {
              currentBox.classList.remove('visible');
              nextBox ? nextBox.classList.add('visible') : '';
              index === sections.length - 1 ? this.next() : this.prev
            }
          }
        });
      }, { threshold: 0.3 });
      sections.forEach(box => {
        observer.observe(box);
      });
    },
    prev() {
      this.$refs.swiper.$swiper.slidePrev();
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
    },
    next() {
      this.$refs.swiper.$swiper.slideNext();
      this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
    },
    screenMobile() {
      const element = document.querySelectorAll('jump-to-content');
      this.showJump = element.length <= 1;
      eventBus.$on('mediaquery::changed', (mq) => {
        this.mobileScreen = !!(mq.size === 'small' || mq.size === 'medium');
      });
    },
  },
};
</script>

<style lang="scss" src="./swiper.scss"></style>
<style lang="scss" src="./jump-to.scss"></style>
