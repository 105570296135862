export default {
    data() {
		return {
			prev: window.pageYOffset,
            handle: null
		}
	},
    mounted() {
        this.calcualteScroll();
    },
    methods :{
        calcualteScroll() {
            window.addEventListener("scroll", () => {
                var curr = window.pageYOffset;
                if (this.prev > curr) {
                    this.active = true;
                } else {
                    this.active = false;
                }
                if (curr === 0) {
                   this.active = false;
                }
                this.prev = curr;
                if (this.handle) {
                    clearTimeout(this.handle);
                }
                this.handle = setTimeout(() =>{
                    if (curr !== 0) { 
                        this.active = true;
                    }
                },200);
            });
        }
    }
}


