export function getHighlight(list) {
  list.forEach((item) => {
    let articleItems = item.ArticleItems;
    if (!item.HighlightedItems) {
      item.HighlightedItems = articleItems ? [articleItems.shift()] : [];
      item.ArticleItems = articleItems;
    }
  });
  return list;
}
