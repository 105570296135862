import { checkStatus } from './check-status';


export async function getVotes(endpoint, answerId) {

  const response = await fetch(`${endpoint}?answerId=${answerId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    cache: 'no-store'
  });

  checkStatus(response);

  return await response.json();
}

export async function postVotes(endpoint, votes) {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    cache: 'no-store',
    body: JSON.stringify(votes)
  });

  checkStatus(response);

  return await response.json();
}
