<template>
    <div id="news-letter-subscription-model" class="news-letter-container-popin-v2" v-if="showModal">
      <div class="modal__backface" @click="closeModal"/>
      <div class="news-letter modal__wrapper">
        <button class="modal__close-btn newsletter-close-btn"
                @click="closeModal"
                @keyup.esc="closeModal"
                type="button">
          <div class="btn_close close" id="btn_close"/>
        </button>
        <div class="news-letter__img">
          <responsive-image :srcset="srcset" animate="">
            <img :src="imageUrl" alt="">
            <noscript>
              <img :src="imageUrl" alt="">
            </noscript>
          </responsive-image>
        </div>
        <div class="news-letter__body-txt">
          <div class="news-letter__content">
            <div class="news-letter__title">
                <h1 class="news-letter_title is-2" v-html="title"></h1>
                <div class="news-letter__description" v-html="description"></div>
            </div>
            <form @submit.prevent="submitForm()" novalidate autocomplete="off">  
                <div class="fieldset" v-if="fieldEmail.label != ''">
                  <field-input v-model="form.email"
                              :v="$v.form.email"
                              :field="fieldEmail" />
                </div>
                <div class="fieldset" v-if="fieldPhoneNumber.label != ''">
                  <field-input v-model="form.phone"
                              :v="$v.form.phone"
                              :field="fieldPhoneNumber" @checkSMSOption="checkSMSOption"/>
                </div>
                <div class="nameWrapper">
                  <div class="fieldset" v-if="fieldFirstName.label != ''">
                    <field-input v-model="form.firstName"
                                :v="$v.form.firstName"
                                :field="fieldFirstName" />
                  </div>
                  <div class="fieldset" v-if="fieldLastName.label != ''">
                    <field-input v-model="form.lastName"
                                :v="$v.form.lastName"
                                :field="fieldLastName" />
                  </div>
                </div>
                <date-of-birth v-if="fieldBirth.label != ''" @birth="setBirthData" :field-birth="fieldBirth" :start-validate="validateDate" @birthIsValid="setBirthValidation" :required="isrequired" />
                <div>
                    <p class="news-letter__terms-text" v-html="termsText"></p>
              </div>
              <div class="subscription__options">
                <div class="custom-form__checkbox-group" v-if="fieldEmail.label != '' &&  emailOptionCheckbox.label != ''">
                  <field-checkbox  :value="form.emailOption"
                                  v-model="form.emailOption"
                                  :v="$v.form.emailOption"
                                  :field="emailOptionCheckbox" />
                </div>
                <div  class="custom-form__checkbox-group" v-if="fieldPhoneNumber.label != '' && smsOptionCheckbox.label != '' ">
                  <field-checkbox  :value="form.smsOption"
                                    v-model="form.smsOption"
                                    :v="$v.form.smsOption"
                                    :field="smsOptionCheckbox" />
                </div>
                </div>
                 <template>
                  <div class="custom-form__checkbox-group" 
                  v-for="(item, index) in fieldCheckboxes"
                    :key="index">    
                    <div v-if="!disableCheckbox">
                  <field-checkbox v-if="item.required"
                                  :value="form.gdpr[`checkbox-${item.gdprId}`]"
                                  v-model="form.gdpr[`checkbox-${item.gdprId}`]"
                                  :v="$v.form.gdpr[`checkbox-${item.gdprId}`]"
                                  :field="item" />
                  <field-checkbox v-else
                                  :value="form.gdpr[`checkbox-${item.gdprId}`]"
                                  v-model="form.gdpr[`checkbox-${item.gdprId}`]"
                                  :field="item" />
                    </div>
                    <div v-else>
                      <span class="disabled-checkbox-txt" v-html="htmlStr(item)" />
                    </div>
                  </div>
                </template>
                <div class="news-letter__info" v-html="termsOfCaption"></div>
                <div class="custom-form__actions">
                  <div>
                    <button type="submit" :class="ctaClass">
                      <span class="btn__wrapper">{{ submitBtnText }}</span>
                    </button>
                  </div>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { email, helpers, minLength, required, maxLength,sameAs } from 'vuelidate/lib/validators';
import FieldInput from '@Feature/CustomForms/fields/FieldInput/code/Scripts/components/field-input.vue';
import { validationMixin } from 'vuelidate';
import dateOfBirth from '@Feature/CustomForms/dateOfBirth/code/Scripts/components/date-of-birth.vue';
import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';
import FieldCheckbox from '@Feature/CustomForms/fields/FieldCheckbox/code/Scripts/components/field-checkbox.vue';
import { postData } from '@Foundation/services';
/**
 * Custom validators vor Vuelidate
 */
const alphaExtended = helpers.regex('alphaExtended', /^[\w\D][^\u0021-\u002D\u003A-\u0040]*$/);

  export default {
    name: 'newsletter-subscription',
    components: { ResponsiveImage,FieldInput,dateOfBirth,FieldCheckbox},
    mixins: [validationMixin],
    props: {
      pageCategory: { type: String,default:''},
      fieldEmail: { type: Object, required: true, default: undefined },
      srcset: {
        type: [Object, String],
        required: false,
        default: undefined
      },
      fieldFirstName: { type: Object, required: true, default: undefined },
      fieldLastName: { type: Object, required: true, default: undefined },
      fieldPhoneNumber: { type: Object, required: true, default: undefined },
      imageUrl: { type: String, default: undefined },
      fieldBirth: { type: Object, required: true, default: undefined },
      ctaClass: { type: String, default: 'btn btn--black' },
      submitBtnText: { type: String, default: 'Submit' },
      fieldCheckboxes: { type: Array, required: true, default: undefined },
      apiUrl: { type: String, required: true, default: undefined },
      smsOption: { type: Object, required: true, default: undefined }, 
      emailOption: { type: Object, required: true, default: undefined },
      title: { type: String, required: true, default: undefined },
      description: { type: String, required: true, default: undefined },
      termsOfCaption: { type: String, required: true, default: undefined },
      termsText: { type: String, required: true, default: undefined },
      validationMsg: { type: Object,default: undefined },
      placement: { type: String, required: true, default: undefined },
      disableCheckbox: {type: Boolean, required: true, default: false},
    },
    data() {
      return {
        flag: '',
        position: 'popin',
        showModal: false,
        isrequired: true,
        form: {
          email: '',
          firstName: '',
          lastName: '',
          phone: '',
          gdpr: {},
          birth: {
            day: '',
            month: '',
            year: ''
          },
          emailOption: false,
          smsOption: false
        },
        validateDate: true,
        validationSettings: {
          form: {
            firstName: {
              alphaExtended
            },
            lastName: {
              alphaExtended,
            },
            email: {
              email,
              required
            },
            phone: {},
            gdpr: {},
            emailOption:{  required, sameAs: sameAs(() => true)},
            smsOption: {}
          },
        },
        checkboxFieldIDs: [],
        emailOptionCheckbox: {
           required:false,
           id:"emailoption",
           idError:"emailoption-error",
           value:null,
           label:"Email",
           errors:[
            {
                message:"",
                validator:sameAs
            }
           ]
        },
        smsOptionCheckbox:  {
           required:false,
           id:'sms',
           idError:'sms-error',
           value:null,
           label:'SMS',
           errors:[
            {
                message:"",
                validator:sameAs
            }
           ]
        },
        isBirthValid: true, 
      };
    },
    computed: {
      vulidateSettings: {
        get() {
          // GDPR checkboxes
          if (!this.disableCheckbox) {
            for (let index = 0; index < this.fieldCheckboxes.length; index++) {
              if (this.fieldCheckboxes[index].required) {
                this.$set(this.validationSettings.form.gdpr, `checkbox-${this.fieldCheckboxes[index].gdprId}`, {
                  required,
                  sameAs: sameAs(() => true)
                });
              }
            }
          }
          /* istanbul ignore else */
          if(this.fieldEmail.required) {
            this.validationSettings.form.email = {email,required};
          }
          if(this.fieldPhoneNumber.required) {
            this.validationSettings.form.phone = {required};
          }
          if(this.fieldPhoneNumber.regExp !== '') {
            this.validationSettings.form.phone.phoneFormat = helpers.regex('regexpval', new RegExp(this.fieldPhoneNumber.regExp ));
          }
          if(this.fieldFirstName.required) {
            this.validationSettings.form.firstName = {required,alphaExtended};
          }
          if(this.fieldLastName.required) {
            this.validationSettings.form.lastName = {required,alphaExtended};
          }
          if(this.fieldBirth.required) {
            this.isrequired = true;
          }else{
            this.isrequired = false;
          }
          if(this.emailOptionCheckbox.required) {
            this.validationSettings.form.emailOption = {  required, sameAs: sameAs(() => true)}; 
          }
          if(this.smsOptionCheckbox.required) {
            this.validationSettings.form.smsOption = {required, sameAs: sameAs(() => true)}; 
          }
          return this.validationSettings.form;
        },
        set(newValue) {
          if(newValue === 'mandatory') {
            this.$set(this.validationSettings.form, 'smsOption', {
              required, sameAs: sameAs(() => true),
            });
          }else {
            this.$set(this.validationSettings.form, 'smsOption', {});
          }
        }
      },
    },
    validations() {
      return {
        form: this.vulidateSettings
      };
    },
    created() {
      this.setCheckboxesToVueData();
      this.initNewsLetterV2Popin();
    },
    mounted() {
      window.eventBus.$on('newsletterpopup', payload => {
        if (payload.show) {
          this.showModal = payload.show;
          this.flag = 'sticky bar';
          this.position = payload.postion;
          this.taggingNewsletterImpression(payload.postion);
          document.cookie = 'newsletterPopin=';
          setTimeout(function() { document.getElementById('news-letter-subscription-model').scrollIntoView({ behavior: 'smooth'});}, 1000);
          this.showScroll();
        }
      });
      //Create email and sms data
      this.emailOptionCheckbox.label = this.emailOption?.emailOptinText;
      this.smsOptionCheckbox.label = this.smsOption?.smsOptinText;
      this.emailOptionCheckbox.errors[0].message = this.emailOption?.errorMessage;
      this.smsOptionCheckbox.errors[0].message = this.smsOption?.errorMessage;
      if(this.emailOption?.isEmailOptin) {
        this.emailOptionCheckbox.required = true;
      }
      if(this.smsOption?.isSmsOptin) {
        this.smsOptionCheckbox.required = true
      }
      let emailOptionId = this.emailOptionCheckbox?.id;
      this.checkboxFieldIDs.push(emailOptionId);
      let smsOptionId = this.smsOptionCheckbox.id;
      this.checkboxFieldIDs.push(smsOptionId);
      this.fieldCheckboxes.forEach((ele,i)=>{
        this.checkboxFieldIDs.push(ele.id);
      });
    },
    methods: {
      htmlStr(field) {
        if (!field || !field.label) return '';
        const decodedLabel = this.htmlDecode(field.label);
        return this.allReplace(decodedLabel, { '<': '<', '>': '>' });
      },

      htmlDecode(string) {
        const textarea = document.createElement('textarea');
        textarea.innerHTML = string;
        return textarea.value;
      },

      allReplace(str, replacements) {
        return Object.entries(replacements).reduce((acc, [key, value]) => 
          acc.replace(new RegExp(key, 'g'), value), str || '');
      },
      initNewsLetterV2Popin() {
        const getStatus = this.getCookie('newsletterPopin');
        if (this.placement.toLowerCase() === 'sticky') {
          window.eventBus.$emit('newsletterSticky');
        } else {
          if (this.placement === '1st page viewed') {
            this.showModal = !getStatus;
            this.flag = 'popin';
            this.taggingNewsletterImpression('load');
            this.showScroll();
          } else if (this.placement === '2nd page viewed') {
            const isSecondPage = this.getCookie('newsLetterPopIn1stPage');
            this.showModal = isSecondPage.length && !getStatus.length;
            this.flag = 'popin';
            this.taggingNewsletterImpression('load');
            this.showScroll();
          }
        }
        if (!getStatus) {
          document.cookie = 'newsLetterPopIn1stPage=true';
        }
      },
      showScroll() {
        setTimeout(function() { 
          let body = document.getElementsByTagName("body");
          let newsletterElement = document.getElementById("news-letter-subscription-model");
          if(body && newsletterElement != null) {
            body[0].classList.add("newsletterPopup");
          }
        }, 1000);
      },
      objectMap(object, mapFn) {
        return Object.keys(object).reduce(function (result, key) {
          result[key] = mapFn(object[key], key);
          return result;
        }, {});
      },
      getCookie(cName) {
        var name = cName + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return '';
      },
      setCheckboxesToVueData() {
        this.fieldCheckboxes.forEach((item) => {
          this.$set(this.form.gdpr, `checkbox-${item.gdprId}`, item.value);
        });
      },
      closeModal() {
        this.showModal = false;
        document.cookie = 'newsletterPopin=true';
        let body = document.getElementsByTagName("body");
        if(body) {
          body[0].classList.remove("newsletterPopup");
        }
        if (this.placement.toLowerCase() === 'sticky') {
          window.eventBus.$emit('newsletterStickyClose');
        }
      },
      setBirthData(payload) {
        this.form.birth[payload.type] = payload.value;
      },
      setBirthValidation(payload) {
        this.isBirthValid = payload;
      },
      submitForm() {
        this.checkSMSOption();
        this.validateDate = false;
        this.$v.form.$touch();
        this.$nextTick(() => {
          this.validateDate = true;
          setTimeout(() => {
            /* istanbul ignore next */
            if(this.fieldBirth.label === '') {
              this.isBirthValid = true;
            }
            if (!this.isBirthValid || (this.$v.form.$pending || this.$v.form.$error) ) {
              return;
            }else {
              const gdpr = this.fieldCheckboxes.map((item, index) => {
                return {
                  gdrpSettingItemId: this.fieldCheckboxes[index].gdprId,
                  isSelected: this.form.gdpr[`checkbox-${item.gdprId}`]
                };
              });
              const serializedForm = this.objectMap(this.form, (value, key) => {
                if (typeof value === 'object' && this.checkboxFieldIDs.includes(key)) {
                  const checkedValues = [];
                  for (const key in value) {
                    if (value.hasOwnProperty(key)) {
                      value[key] && checkedValues.push(key);
                    }
                  }
                  return checkedValues;
                } else {
                  return value;
                }
              });
              postData(`${window.location.origin}/${this.apiUrl}`, {
                ...serializedForm,
                gdpr
              })
              .then((response) => {
                window.eventBus.$emit('clickandEnabledPopup', true);
               
              })
              .catch(() => {
                console.error('Request failed');
              })
              .finally(() => {
                this.taggingRegistration();
                this.showModal = false;
                document.cookie = 'newsletterPopin=true';
                let body = document.getElementsByTagName("body"); 
                if(body) {
                  body[0].classList.remove("newsletterPopup");
                }
                window.eventBus.$emit('newsletterStickyClose');
                window.eventBus.$emit('clickandEnabledPopup', true);
              })
            }
          }, 200);
        });
      },
      checkSMSOption() {
        if(this.form.phone !== '') {
          this.vulidateSettings = 'mandatory'
          if(this.form.smsOption==null || this.form.smsOption==false) {
            this.vulidateSettings = 'mandatory' 
          }else{
            this.vulidateSettings = 'not mandatory'
          }
        }else {
          this.vulidateSettings = 'not mandatory'
        }
      },
      taggingRegistration() {
        let referral = this.position.toLowerCase();
        window.dataLayer.push({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'eventCategory': 'registration',
          'eventAction': 'newsletter',
          'eventLabel': referral,
          'event_name': 'newsletter_registration',
          'referral': referral
        });
      },
      taggingNewsletterImpression(position) {
        window.dataLayer.push({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name':'newsletter_popin',
          'eventCategory': this.pageCategory,
          'eventAction': 'popin impression',
          'eventLabel': 'popin impression' + ' '+ position.toLowerCase(),
          'module_name':'popin_impression',
          'cta_name': 'popin impression' + ' ' + position.toLowerCase()
        });
      }
    }
  };
</script>
<style lang='scss' src="./newsletter-subscription-v2.scss"></style>


