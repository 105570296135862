/**
 * This function add an active class to the menu item clicked
 * @param {object} el - The element from which we want the closest parent class
 * @param {string} sel - The classname that we are looking for
 * @param {object} oPar - First parent of el
 */
export default function closest(el, sel) {
  const oPar = el.parentNode;
  if (oPar === document.querySelector('html')) {
    return null;
  }
  if (!oPar.classList.contains(sel)) {
    return closest(oPar, sel);
  }
  return oPar;
}
