<template>
  <div id="geoLocationMap" v-show="showModal && !userPrefferedDomain">
    <div class="pop-in" :class="{ opened: isShown , 'scroll-popup': isScroll}">
      <div class="pop-in-content">
        <button class="close" @click="closePopIn" />
        <slot />
      </div>
      <div class="popin-overlay" @click="showModal = false" />
    </div>
  </div>
</template>
<script>
  export default {
    name: 'geo-popin',
    props: {
      userPrefferedDomain: { type: Boolean, default: false }
    },
    data() {
      return {
        showModal: true,
        isShown: true,
        isScroll: false
      };
    },
    created() {

      window.addEventListener('keydown', (e) => {
        if (e.key == 'Escape') {
          this.showModal = !this.showModal;
        }
      });
    },

    methods: {
      hideScroll(isHide) {
        const body = document.getElementsByTagName('body')[0];
        if (isHide) {
          body.classList.add('no-scroll');
        } else {
          body.classList.remove('no-scroll');
        }
      },
      closePopIn() {

        this.hideScroll(false);
        this.showModal = false;
      }
    }
  };
</script>
<style lang="scss" src="./geo-popin.scss"></style>
