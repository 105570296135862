<template>
  <div :class="[ routineProduct ? '':' cell', 'commerce-connector']">
  <template v-if="!isSingleVariant">
    <div class="buyOnline -btnOnly" v-if="this.showBuyNowButton==true && !isBuyOnline && !stickyBuynowButton">
      <div @click="buybuttonselect()" class="popupContainer__bagIcon">
        <img alt="bag" src="/frontend/master/images/icons/bag.svg">
      </div>
      <div :class="ctaClass">
        <span @click="buybuttonselect()" class="btn__wrapper" v-tag:useractionevent="{
                  category: 'Ecommerce',
                  action: 'click to buy',
                  label: buynowEventlabelData,
                  event_name: 'buy_now',
                  product_info: buynowEventlabelData
                }">
          {{ buynowTextData }}
        </span>
      </div>
    </div>
    <div v-else-if="isBuyOnline && !stickyBuynowButton ">
      <template v-if="productPopinCtaClass !== ''">
        <button @click="buybuttonselect()" :class="productPopinCtaClass" v-tag:useractionevent="{
            category: 'Ecommerce',
            action: 'click to buy',
            label: buynowEventlabelData,
            event_name: 'buy_now',
            product_info: buynowEventlabelData
          }">
          <span class="btn__wrapper">
            {{ buynowTextData }}
          </span>
        </button>
      </template>
      <template v-else>
        <p class="actionBtn buyOnline" @click="buybuttonselect()"> {{ buynowTextData }} </p>
      </template>
    </div>
    <div v-if="stickyBuynowButton">
      <p class="actionBtn buyOnline" @click="mobilebuybutton()"> {{ buynowTextData }} </p>
    </div>
  </template>
  <div class="popupContainerOuter" id="popupContainerOuter" v-show="showPopup">
    <div class="popupContainer" :class="[ stickyBuynowButton ? 'retailerpopup':'']">
      <div class="popupContainer__close" @click="closePopupScreen()">&#10006;</div>
      <div class="container" id="app">
        <div class="tabs">
          <ul>
            <li v-for="(tab, index) in this.eretailer" :class="{'is-active': show == index}" @click.prevent="show = index" :key="index">
              <a>{{ tab.countryCode }}</a>
            </li>
          </ul>
        </div>
        <div class="tabData">
          <transition-group name="fade-up" target="div" appear @click.native="navigate($event)">
            <div v-for="(tab, index) in this.eretailer" v-if="show == index " :key="index+0">
              <div class="popupContainer__inner" id="commerceConnectWrapper" v-if="tab.shopsUnavailable==false">
                <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings" v-if="tab.shops.length > 1">
                  <div class="popupContainer__item desktop" v-if="!smallScreen" v-for="shop in tab.shops" :key="shop.id">
                    <a :href="shop.link" target="_blank" class="popupContainer__item_link" @click="setAnalytics(shop.cartTagging)">
                      <div class="popupContainer__image">
                        <img alt="shop" :src="shop.logo">
                      </div>
                      <div class="popupContainer__bagIcon">
                        <img alt="bagblack" src="/frontend/master/images/icons/bag--black.svg">
                      </div>
                      <div class="popupContainer__buyNow btn__wrapper">
                        {{ shop.buyButton }}
                      </div>
                    </a>
                  </div>
                </VueSlickCarousel>
                <div class="popupContainer__item  mobile" v-for="shop in tab.shops" :key="shop.id" v-if="smallScreen">
                  <a :href="shop.link" target="_blank" class="popupContainer__item_link" @click="setAnalytics(shop.cartTagging)">
                    <div class="popupContainer__image">
                      <img :src="shop.logo" :alt="shop.buyButton">
                    </div>
                    <div class="popupContainer__bagIcon">
                      <img src="/frontend/master/images/icons/bag--black.svg" :alt="shop.buyButton">
                    </div>
                    <div class="popupContainer__buyNow btn__wrapper">
                      {{ shop.buyButton }}
                    </div>
                  </a>
                </div>
              </div>
              <div class="popupContainer__inner popupContainer__inner--empty" id="commerceConnectWrapper" v-else>
                {{ tab.defaultDescriptionTitle }}
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
  <div class="popupContainerOuter variantPopupOuter capacityPopupContainerOuterroutine " id="capacityPopupContainerOuter" v-if="capacityPopup">
    <div class="variant popupContainer">
      <div class="popupContainer__close" @click="closecapacityPopupScreen()">&#10006;</div>
      <div class="container pdp-stikcy-bar" id="app">
        <div class="sticky-parent">
          <div class="sticky__heading">
            <img :src="productImg" alt="product images">
          </div>
          <div class="sticky__select">
            <div class="sticky__title" v-html="pfdTitle" />
            <div class="sticky__select--select_capacity">
              <volume-variant-v2 :volumes="volumesDetail" :legend-name="capacityText" a11y-aria-label="legend-volume current-volume" :sfccvolumesdetails="variantDetail" :popup-dropdown="true" :productid="productIds" />
            </div>
            <div class="sticky__select--select_color" v-if="colorList.length > 0">
              <color-variant-v2 a11y-close-panel-text="panelText" a11y-next-panel-text="nextPanelText" a11y-previous-panel-text="previousPanelText" :colors="colorList" :legend-name="colorText" itemprop="color" :productid="productIds" :sfcccolorsdetails="colorsDetails" :popup-dropdown="true" />
            </div>
          </div>
        </div>
        <div class="sticky__buynow-button">
          <p class="actionBtn buyOnline" @click="buybuttonselect()"> {{ buynowTextData }} </p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import { tag, AnalyticsHandler } from '@Foundation/analyticsHandler';
  import ColorVariantV2 from '@Feature/ColorVariant-V2/code/Scripts/components/color-variant-v2';
  import VolumeVariantV2 from '@Feature/VolumeVariant-V2/code/Scripts/components/volume-variant-v2';
  export default {
    name: 'buynow',
    components: { VueSlickCarousel, ColorVariantV2,
                  VolumeVariantV2, },
    directives: {
      tag
    },
    props:	{
      requestData: { type: String, required: false, default: '' },
      isSingleVariant: { type: Boolean, default: false },
      capacityText: { type: String, required: false, default: 'Capacity' },
      colorText: { type: String, required: false, default: 'Color' },
      productId: { type: String, required: false, default: '' },
      buynowText: { type: String, required: true },
      buynowEventlabel: { type: String, required: false, default: 'Productname::Productean' },
      isOnline: { type: Boolean, required: false, default: false },
      productImg: { type: String, required: false, default: '' },
      stickyBuynowButton: { type: Boolean, required: false, default: false },
      pfdTitle: { type: String, required: false, default: '<strong>TOLERIANE</strong> <br/> SENSITIVE <br/>LE TEINT CREME' },
      variantDetail: { type: Array, required: false, default: [] },
      volumesDetail: { type: Array, required: false, default: [] },
      colorsDetails: { type: Array, required: false, default: [] },
      colorList: { type: Array, required: false, default: [] },
      productIds: { type: Array, required: false, default: [] },
      productName: { type: String, required: false, default: '' },
      productEan: { type: String, required: false, default: '' },
      tagEvent: { type: Boolean, required: false, default: false },
      routineProduct: { type: Boolean, required: false, default: false },
      routineMobile: { type: Boolean, required: false, default: false },
      ctaClass: {type: String, default: 'cta cta--light'},
      productPopinCtaClass: {type: String, default: ''},
      
    },
    data() {
      return {
        capacityPopup: false,
        settings: {
          'dots': true,
          'arrows': true,
          'infinite': false,
          'speed': 500,
          'slidesToShow': 4,
          'slidesToScroll': 4,
          'initialSlide': 0,
          'touchThreshold': 5,
          'responsive': [
            {
              'breakpoint': 1024,
              'settings': {
                'slidesToShow': 4,
                'slidesToScroll': 4,
                'infinite': false,
                'dots': true
              }
            },
            {
              breakpoint: 1023,
              settings: {
                'slidesToShow': 1,
                'slidesToScroll': 1,
                'dots': true,
                'arrows': true,
              }
            },
            {
              breakpoint: 768,
              settings: {
                vertical: true,
                verticalSwiping: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 0,
                speed: 15000,
                cssEase: 'linear',
                infinite: true,
                arrows: false,
                touchMove: true,
                swipeToSlide: true,
                swipe: true
              }
            },
          ]
        },
        eretailer: [],
        eretailershops: [],
        shopsUnavailable: true,
        defaultDescriptionTitle: '',
        buynowTextData: this.buynowText,
        buynowEventlabelData: this.buynowEventlabel,
        showBuyNowButton: true,
        showPopup: false,
        show: 0,
        prescriptedValue: '',
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
        isBuyOnline: this.isOnline,
        rangeListData: {}
      };
    },
    mounted() {
      if(this.isSingleVariant) {
          this.apiFetch(this.requestData);
      }
      if (this.routineProduct) {
        this.buybuttonselect();
      }
      if(this.routineMobile){
       this.mobilebuybutton();
     }
      this.getUrlParam();
      var checkSections = document.querySelectorAll('.js-select-option');
      var numberOfChildren = checkSections && checkSections.length;
      if (numberOfChildren <= 0) {
        this.showBuyNowButton = false;
      }
      let elem = document.getElementsByClassName('popupContainer__bagIcon');
      if(elem.length >= 1){
        document.getElementsByClassName('popupContainer__bagIcon')[0].style.marginTop = 'auto';
      }
      window.eventBus.$on('showRetailerPopup',() => {
        setTimeout(() => {
          this.buybuttonselect();
        }, 300);
      });
      eventBus.$on('mediaquery::changed', () => {
        this.smallScreen = !window.matchMedia('(min-width: 1024px)').matches;
      });
    },

    methods: {
      getUrlParam() {
        const uri = window.location.href.split('?');
        if (uri.length == 2) {
          const vars = uri[1].split('&');
          const getVars = {};
          let tmp = '';
          vars.forEach(function(v) {
            tmp = v.split('=');
            if (tmp.length == 2) {
              getVars[tmp[0]] = tmp[1];
            }
          });
          if (getVars) {
            this.prescriptedValue = getVars;
          }
        }
      },
      setAnalytics(dataObject) {
        AnalyticsHandler.pushDataLayer(dataObject);
      },
      navigate: function(e) {
        if (e.target.dataset.show) {
          e.preventDefault();
          this.show = e.target.dataset.show;
        }
      },
      productBuynowTag() {
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'event_name': 'buy_now',
          'ecommerce': 'undefined',
          'eventCategory': 	'Ecommerce',
          'eventAction': 'click to buy',
          'eventLabel': `${[this.productName]}::${[this.productEan]}`,
          'product_info': `${[this.productName]}::${[this.productEan]}`,
        });
      },

      buybuttonselect: function() {
        if(this.tagEvent == true) {
          this.productBuynowTag();
        }

        if (this.capacityPopup) {
          this.capacityPopup = false;
          document.getElementById('capacityPopupContainerOuter').classList.remove('showPopup');
        }


        if (!this.isBuyOnline) {
          document.querySelector('.sticky-wrapper').classList.add('sticky-wrapper__active');
        }
        document.getElementById('popupContainerOuter').classList.remove('hidePopup');
        document.getElementById('popupContainerOuter').classList.add('showPopup');
        document.querySelector('.buyOnline')?.classList.add('buyOnline__active');
        var dataIdChecked;
        if(this.rangeListData.israngelist) {
          dataIdChecked = this.rangeListData.commerceConnectorId;
        } else { 
          var colorselectionid = document.querySelectorAll('.productItemId');
          var colorselectionChildren = colorselectionid && colorselectionid.length;
          if (colorselectionChildren > 0) {
            var colorselection = document.querySelectorAll('.productItemId')[0].textContent;
            dataIdChecked = '{' + colorselection + '}';
          } else {
            var checkSections = document.querySelectorAll('.js-select-option');
            var numberOfChildren = checkSections && checkSections.length;
            if (numberOfChildren > 0) {
              for (var i = 0; i < checkSections.length; i++) {
                var checkboxIds = checkSections[i].getAttribute('id');
                var checkedOnes = document.getElementById(checkboxIds).checked;
                if (checkedOnes === true) {
                  dataIdChecked = checkboxIds;
                }
              }
            }
          }
        }    
        this.apiFetch(dataIdChecked);
      },
      apiFetch(dataIdChecked) {
        fetch(`/api/sitecore/CommerceConnector/CommerceConnector?itemId=${dataIdChecked}&prescripted=${this.prescriptedValue.prescripted}`)
          .then(response => response.json())
          .then(jsonData => {
            this.eretailer = jsonData;
            //this.shopsUnavailable = this.eretailer.shopsUnavailable;
            //this.defaultDescriptionTitle = this.eretailer.defaultDescriptionTitle;
            //this.eretailershops = this.eretailer.shops;
          });
        this.showPopup = true;
      },
      closePopupScreen: function() {
        if (this.productId) {
          this.$emit('closepopup');
        }
        window.eventBus.$emit('closeProductpopin');
        this.showPopup = false;
        window.eventBus.$emit('retailerbuynow', false);
        document.getElementById('popupContainerOuter').classList.add('hidePopup');
        document.getElementById('popupContainerOuter').classList.remove('showPopup');
        document.querySelector('.sticky-wrapper')?.classList.remove('sticky-wrapper__active');
        document.querySelector('.buyOnline')?.classList.remove('buyOnline__active');
        document.body.style.removeProperty('overflow');
      },
      mobilebuybutton: function() {
        this.productBuynowTag();
        this.capacityPopup = true;
        this.showPopup =false;
        if(document.getElementById('capacityPopupContainerOuter')){
            document.getElementById('capacityPopupContainerOuter').classList.add('showPopup');
        }
        document.querySelector('.buyOnline').classList.add('buyOnline__active');
        document.body.style.overflow = 'hidden';
        document.getElementById('main').classList.add('variantPopup_active');
        document.querySelector('.capacityPopupContainerOuterroutine').classList.add('showPopup');
      },
      closecapacityPopupScreen: function() {
        this.capacityPopup = false;
        this.showPopup =false;
        window.eventBus.$emit('retailerbuynow', false);
        document.getElementById('capacityPopupContainerOuter').classList.remove('showPopup');
        document.querySelector('.buyOnline').classList.remove('buyOnline__active');
        document.body.style.removeProperty('overflow');
        document.getElementById('main').classList.remove('variantPopup_active');
      }
    }
  };

</script>
<style lang="scss" src="./buynow.scss"></style>
<style lang="scss" src="./vue-slick-carousel.scss"></style>
