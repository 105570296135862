<template>
  <div class="sprite-background">
    <div class="sprite-background__content">
      <slot/>
    </div>

    <div class="sprite-background__wrapper">
      <div v-for="background in backgrounds"
           class="sprite-background__sprite"
           :key="background.id"
           :class="background.classes"
           :style="background.style"/>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'sprite-background',
    props: {
      src: {
        type: String,
        required: true
      },

      height: {
        type: String,
        required: true
      },

      width: {
        type: String,
        required: true
      }
    },

    computed: {
      backgrounds() {
        const { height, width, src } = this;
        const backgrounds = [];

        for (let i = 0; i < 2; i++) {
          backgrounds.push({
            id: i,
            classes: [`-sprite-${i}`],
            test: `0 calc(-${height} * ${i})`,
            style: {
              height,
              width,
              backgroundPosition: `0 ${i ? 'bottom' : 'top'}` ,
              backgroundImage: `url(${src})`,
            }
          });
        }

        return backgrounds;
      }
    }
  };
</script>
<style lang="scss" src="./sprite-background.scss"></style>
