<template>
  <div
    class="panel__wrapper">
    <div
      ref="toggleButtonWrapper"
      class="panel__toggle">
      <slot
        name="toggleButton"
        :panelToggler="togglePanel"
        :expanded="show"/>
    </div>
    <div
      class="panel"
      ref="panelTop"
      :class="{ 'panel--open' : show, 'panel--on-top' : showOnTop }">
      <div
        v-if="!isGlobalOverlay"
        class="panel__overlay"
        @click="closePanel"/>
      <div
        class="panel__content"
        @keyup.esc.prevent="closePanel"
        ref="panelWrapper">
        <button
          @blur="closeOnBlur"
          class="panel__close"
          @click.prevent="closePanel"
          @keyup.prevent.enter="closePanel"
          ref="closeBtn">
          <span class="is-sr-only">{{ a11yClosePanelText }}</span>
        </button>
        <slot/>
      </div>
    </div>
  </div>
</template>
 
<script>
  import { throttle } from '@Foundation/utilities';
  import { clickoutside } from '@Foundation/directives/clickoutside/clickoutside';
 
  export default {
    name: 'panel',
 
    directives: { clickoutside },
 
    props: {
      a11yClosePanelText: { type: String, required: false, default: 'Close panel' },
      focusableClass: { type: String, required: false, default: undefined },
      isGlobalOverlay: { type: Boolean, required: false, default: false },
 
      //When any panel needs to open by default use this property.
      defaultOpen: {
        type: Boolean,
        default: false
      },
      individualInstance: {
        type: Boolean,
        default: false
      }
    },
 
    data() {
      return {
        show: this.defaultOpen,
        showOnTop: false
      };
    },
 
    mounted() {
      this.sliderA11y();
      const focusable = this.$refs.panelWrapper.querySelectorAll('[panel-focusable]');
 
      if (focusable.length) {
        for (let index = 0; index < focusable.length; index++) {
          focusable[index].addEventListener('blur', () => {
            this.closeOnBlur();
          });
        }
      }
 
      this.updatePanelPositionListener = throttle(() => {
        if (this.show) {
          this.$nextTick(() => this.updatePanelPosition());
        }
      }, 200);
      window.addEventListener('scroll', this.updatePanelPositionListener);
      // setup a global overlay based on prop setup
      this.isGlobalOverlay && this.setGlobalOverlay();
    },
 
    destroyed() {
      window.removeEventListener('scroll', this.updatePanelPositionListener);
    },
 
    methods: {
      focusCloseBtn() {
        this.$refs.closeBtn.focus();
      },
 
      togglePanel(e) {
        /****/
        // Toggle event and handled only in product-filters component.
        window.eventBus.$emit('toggleProductFilter');
        /****/

        //Handling only for Individual Instances
        if(this.individualInstance) {
          if(!this.show) {
            this.$store.commit('setDropdownRef', true)
            this.show = true;
            setTimeout(() => {
              this.$store.commit('setDropdownRef', false);
            }, 500)
            return;
          }
          else {
            this.closePanel();
            return;
          }
        }
        
        e.preventDefault();
        if (!this.show) {
          setTimeout(() => {
            this.show = true;
            // re set global overlay if in use
            this.isGlobalOverlay && this.setGlobalOverlay();
            setTimeout(() => {
              this.focusCloseBtn();
            }, 500);
 
            this.$nextTick(() => this.updatePanelPosition());
          }, 100);
        } else {
          this.closePanel();
        }
      },
 
      updatePanelPosition() {
        const viewportHeight = window.innerHeight;
 
        const panelRect = this.$refs.panelWrapper.getBoundingClientRect();
        const toggleRect = this.$refs.toggleButtonWrapper.getBoundingClientRect();
 
        const panelTopPos = toggleRect.top;
        const panelHeight = panelRect.height + toggleRect.height;
 
        this.showOnTop = !(viewportHeight > (panelTopPos + panelHeight));
      },
 
      isUsingKeyboard() {
        return document.body.classList.contains('is-using-keyboard');
      },
      /* eslint-disable no-console */
 
      sliderA11y() {
        [...this.$el.querySelectorAll('.slider__navigation-button')].forEach((item) => {
          item.addEventListener('blur', () => {
            /*istanbul ignore else*/
            if (this.isUsingKeyboard()) {
              this.closeOnBlur();
            }
          });
        });
      },
      // attempt to avoid regression issues instead of heavy component refactoring to support global overlay
      // check product page buy btn.
      setGlobalOverlay() {
        let globalOverlay = document.getElementById('panel-global-overlay');
 
        if (!globalOverlay) { // if global overlay does not exist make one (first load)
          const boydTag = window.document.getElementsByTagName('body')[0];
          globalOverlay = document.createElement('div');
          globalOverlay.id = 'panel-global-overlay';
          globalOverlay.addEventListener('click', () => {
            this.closePanel();
          });
          boydTag.appendChild(globalOverlay);
        } else { // if global overlay already exist display it
          globalOverlay.classList.add('show');
        }
        // panel overlaps with anything on top of it
        // class="panel panel--open panel--on-top"
        this.$refs.panelTop.addEventListener('click', (e) => {
          if (e.target.classList.contains('panel--open')) {
            this.closePanel();
          }
        });
      },
 
      closePanel() {
        if(this.individualInstance) {
          this.$store.commit('setDropdownRef', true);
          setTimeout(() => {
            this.$store.commit('setDropdownRef', false);
          },500)
        }
        else {
          this.$store.commit('setDropdownRef', false)
        }
        this.show && this.isUsingKeyboard() && this.$refs.toggleButtonWrapper.querySelector('button').focus();
        this.show = false;
        this.isGlobalOverlay && window.document.getElementById('panel-global-overlay').classList.remove('show');
      },
 
      closeOnBlur() {
        setTimeout(() => {
          /*istanbul ignore else*/
          if (!(!!this.$el.querySelector('[panel-focusable]:focus')) && !(!!this.$el.querySelector('.panel__close:focus')) && !document.activeElement.classList.contains('slider__navigation-button') && this.show === true && this.isUsingKeyboard()) {
            this.closePanel();
          }
        }, 200);
      }
    }
  };
</script>
 
<style lang='scss' src="./panel.scss"></style>
 