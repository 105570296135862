export function trimRichText(inputString, maxLength) {
  const tagOrTextRegex = /(<[^>]+>)|([^<]+)/g;
  let match;
  let length = 0;
  let result = '';
  const openTags = [];

  while ((match = tagOrTextRegex.exec(inputString)) !== null && length < maxLength) {
    const [fullMatch, tag, text] = match;

    if (tag) {
      // Add the HTML tag to the result and handle opening/closing tags
      result += tag;

      // If it's an opening tag (excluding self-closing tags), push to the openTags stack
      if (/^<[^\/!][^>]*[^\/]>$/.test(tag)) {
        const tagName = tag.match(/^<(\w+)/)[1];
        openTags.push(tagName);
      }

      // If it's a closing tag, pop from the openTags stack
      if (/^<\/(\w+)/.test(tag)) {
        openTags.pop();
      }
    } else if (text) {
      // Add as much text as possible without exceeding maxLength
      if (length + text.length > maxLength) {
        result += text.slice(0, maxLength - length);
        if (length + text.length > 50) {
          if (result != ''){
            result += '...';
          }
        }
        length = maxLength;
      } else {
        result += text;
        length += text.length;
      }
    }
  }

  // Close any unclosed tags in reverse order
  while (openTags.length > 0) {
    const tagName = openTags.pop();
    result += `</${tagName}>`;
  }
  return result;
};