<template>
  <div id="news-letter-subscription-model" class="news-letter-container-popin" v-if="showModal">
    <div class="modal__backface" @click="closeModal"/>
    <div class="news-letter-container modal__wrapper">
      <button class="modal__close-btn newsletter-close-btn"
              @click="closeModal"
              @keyup.esc="closeModal"
              type="button">
        <div class="btn_close close" id="btn_close"/>
      </button>
      <div class="news-letter-img">
        <responsive-image :srcset="srcset" animate="">
          <img src="/frontend/master/images/placeholder.png" alt="placeholder">
          <noscript>
            <img :src="this.imageUrl" alt="">
          </noscript>
        </responsive-image>
      </div>
      <div class="news-letter-body-txt">
        <div class="newsletter-content">
          <slot name="title"/>
          <form action="" method="post" @submit.prevent="validateAndSend">

            <div class="newsletter-input">
              <label v-show="showemailPlaceholder"
                     for="newsletter-email"
                     class="newsletter-label"
                     :class="{ 'fixed': isFixedPlaceholder }"
                     ref="emailLabel">{{ emailPlaceholder }}</label>
              <input
                v-model="emailField"
                @blur="checkEmailValid(), isEmailEmpty()"
                type="email"
                id="newsletter-email"
                :class="{ 'input-error': hasErrorValidEmail || hasErrorEmail }"
                :aria-describedby="emailDescribedby"
                name="newsletter-email"
                value="Email">
              <p v-if="hasErrorValidEmail" id="newsletter-email-error" class="newsletter__error">{{ validationMsg.emailFormat }}</p>
              <p v-if="hasErrorEmail" id="newsletter-email-error" class="newsletter__error">{{ validationMsg.emailEmpty }}</p>
            </div>
            <slot name="termstext"/>
            <div class="newsletter__terms">
              <div class="newsletter_checkbox" >
                <input v-if="disableCheckbox ==='false'"
                       id="terms-of-use"
                       class="is-sr-only"
                       v-model="termsField"
                       type="checkbox"
                       :aria-describedby="termsDescribedby"
                       name="terms-of-use" >
                <label for="terms-of-use">
                  <slot name="terms"/>
                </label>

              </div>
              <p v-if="hasErrorTerms" id="terms-of-use-error" class="newsletter__error">{{ validationMsg.terms }}</p>

            </div>
            <slot name="termsofcaption"/>
            <div>
              <button type="submit" :class="ctaClass">
                <span class="btn__wrapper">{{ submitBtnText }}</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-undef */
import { postData } from '@Foundation/services';
import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';

export default {
  name: 'newsletter-subscription',
  components: { ResponsiveImage },
  props: {
    srcset: {
      type: [Object, String],
      required: false,
      default: undefined
    },
    pageCategory: { type: String, default: '' },
    isVisible: { type: Boolean, default: false },
    apiUrl: { type: String, default: '' },
    imageUrl: { type: String, default: '' },
    errorMsg: { type: String, default: '' },
    emailPlaceholder: { type: String, default: '' },
    submitBtnText: { type: String, default: '' },
    validationMsg: { type: Object, default: () => {
        return {
          emailFormat: 'Please use valid email format.',
          emailEmpty: 'Please enter your email address.',
          terms: 'Please accept the terms of use.'
        };
      } },
    ctaClass: { type: String, default: 'btn btn--black' },
    disableCheckbox: { type: String, default: 'false' }
  },

  data() {
    return {
      isSumbitEnabled: false,
      serverResponse: null,
      emailField: '',
      showemailPlaceholder: true,
      termsField: false,
      hasErrorValidEmail: false,
      hasErrorEmail: false,
      hasErrorTerms: false,
      showModal: false
    };
  },

  computed: {
    fetchUrl() {
      return `${window.location.origin}/${this.apiUrl}`;
    },
    canSubmit() {
      return !!this.emailField && !this.hasErrorValidEmail && this.termsField;
    },
    isFixedPlaceholder() {
      return (!!this.emailField && this.hasErrorValidEmail) || (this.emailField !== '' && !this.hasErrorValidEmail && !this.hasErrorEmail) || (this.emailField !== '' && !this.hasErrorValidEmail);
    },
    emailDescribedby() {
      if (this.hasErrorValidEmail || this.hasErrorEmail) {
        return 'newsletter-email-error';
      }
      return false;
    },
    termsDescribedby() {
      if (this.hasErrorTerms) {
        return 'terms-of-use-error';
      }
      return false;
    },
  },
  mounted() {
    window.eventBus.$on('newsletterpopup', payload => {
      if (payload.show) {
        this.showModal = payload.show;
        document.cookie = 'newsletterPopin=';
        setTimeout(function() { document.getElementById('news-letter-subscription-model').scrollIntoView({ behavior: 'smooth'});}, 1000);

      }
    });

  },
  watch: {
    emailField() {
      if (this.emailField.length !== 0) {
        this.showemailPlaceholder = false;
      } else {
        this.showemailPlaceholder = true;
      }
    },
    canSubmit(value) {
      this.isSumbitEnabled = value;
    },
    termsField() {
      this.checkTermsValid();
    }
  },

  created() {
    const getStatus = this.getCookie('newsletterPopin');
    if (getStatus === '' && this.isVisible) {
      this.showModal = true;
      this.taggingValue();
    }
  },

  methods: {
    getCookie(cName) {
      var name = cName + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    },
    closeModal() {
      this.showModal = false;
      document.cookie = 'newsletterPopin=true';
    },
    isEmailEmpty() {
      if (this.emailField === '') {
        this.hasErrorEmail = true;
      } else {
        this.hasErrorEmail = false;
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    checkEmailValid() {
      if (!this.validateEmail(this.emailField) && this.emailField !== '') {
        this.hasErrorValidEmail = true;
      } else {
        this.hasErrorValidEmail = false;
      }
    },
    checkTermsValid() {
      if (!this.termsField) {
        this.hasErrorTerms = true;
      } else {
        this.hasErrorTerms = false;
      }
    },
    sendEmail(email) {
      return postData(this.fetchUrl, {
        'email': email
      });
    },
    validateAndSend() {
      this.isEmailEmpty();
      if(this.disableCheckbox==='false'){
        this.checkTermsValid();
      }

      this.checkEmailValid();
      /* istanbul ignore else */
      if (!!this.emailField &&  !this.hasErrorValidEmail) {
        this.isSumbitEnabled = false;
        this.sendEmail(this.emailField)
        .then(response => {
          this.serverResponse = response;
          if (response) {
            window.eventBus.$emit('clickandEnabledPopup', true);
            this.showModal = false;
            document.cookie = 'newsletterPopin=true';
            this.taggingRegistration();
          }
        });
      }
    },
    taggingValue() {
      window.dataLayer.push({
        'event': 'uaevent',
        'ecommerce': 'undefined',
        'eventCategory': this.pageCategory,
        'eventAction': 'display::newsletterpopin',
        'eventLabel': 'newsletter'
      });
    },
    taggingRegistration() {
      window.dataLayer.push({
        'event': 'uaevent',
        'ecommerce': 'undefined',
        'eventCategory': 'registration',
        'eventAction': 'newsletter',
        'eventLabel': 'popin',
        'event_name': 'newsletter_registration',
        'referral': 'popin'
      });
    },
  }
};
</script>
<style lang='scss' src="./newsletter-subscription.scss"></style>
