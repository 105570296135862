<template>
  <div class="images-mosaic list-container" :class="{'hide-num': isShowNum}">

    <ol class="images-mosaic-items-list" v-if="!isMobile" ref="desctopList">
      <slot />
    </ol>
    <Carousel v-if="isMobile"
              :bullets="true" :navigation="true"
              :item-per-page-desktop="3"
              :page-category="pageCategory"
              next-panel-text="Next panel"
              previous-panel-text="previous panel"
              :nav-tagging-label="navTaggingLabel">
      <slot />
    </Carousel>
  </div>
</template>
<script>
import { eventBus } from '@EventBus';
import Carousel from '@Feature/Carousel/code/Scripts/components/carousel.vue';
export default {
  name: 'images-mosaic',
  components: { Carousel },
  props: {
    isShowNum: {
      type: Boolean,
      default: false
    },
    breakPoint: {
      type: String,
      default: '1024px',
      required: true
    },
    navTaggingLabel: { type: [String, Array], required: false, default: '' },
    pageCategory: { type: String, required: false, default: 'page category' },
  },

  data() {
    return {
      isMobile: !window.matchMedia(`(min-width: ${this.breakPoint})`).matches,
    };
  },

  watch: {
    isMobile(val) {
      !val && this.makeList();
    }
  },

  mounted() {
    eventBus.$on('mediaquery::changed', mq => {
      this.isMobile = !!(mq.size === 'small' || mq.size === 'medium');

      if(this.breakPoint === '767px') {
        this.isMobile = false
      }
    });
    this.makeList();
    this.screenMobile();
  },

  methods: {
    // desktop should be a ol li: check 4024
    // nothing but ol li can be used.
    // nojs approach should be presumed
    makeList() {
      if (this.$refs.desctopList && !this.isMobile) {
        const listItems = this.$refs.desctopList.querySelectorAll('.images-mosaic-items-list-item');

        listItems.forEach(item => {
          const liWrapper = document.createElement('li');
          liWrapper.appendChild(item);
          this.$refs.desctopList.appendChild(liWrapper);
        });
      } else if (!this.isMobile) {
        // required when resizing
        // can not be test covered (specific condition when images-mosaic-items-list is not in dom yet upon resize)
        /* istanbul ignore next */
        setTimeout(() => {
          this.makeList();
        }, 250);
      }
    },
    screenMobile() {
      eventBus.$on('mediaquery::changed', (mq) => {
        this.isMobile = !!(mq.size === 'small' || mq.size === 'medium');
      });
    },
  }
};
</script>
<style lang="scss" src="./ImagesMosaic.scss"></style>
