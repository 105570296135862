<template>
  <div class="date-of-birth" role="group" aria-labelledby="legend-date-of-birth">
    <div class="legend" id="legend-date-of-birth">
      {{ fieldBirth.label }}
    </div>
    <ul>
      <li>
        <Field-select
          :start-update="updateDay"
          :is-disabled="fieldBirth.isDisabled"
          v-model="day"
          :v="v.day"
          :custom-validation="true"
          :field="FieldDays"/>
      </li>
      <li>
        <Field-select
          :is-disabled="fieldBirth.isDisabled"
          v-model="month"
          :v="v.month"
          :custom-validation="true"
          :field="fieldBirth.month"/>
      </li>
      <li>
        <Field-select
          :start-update="updateYear"
          :is-disabled="fieldBirth.isDisabled"
          v-model="year"
          :v="v.year"
          :custom-validation="true"
          :field="FieldYears"/>
      </li>
    </ul>

    <template v-for="(error, index) in fieldBirth.errors">
      <p
        class="field-help"
        :key="index"
        v-if="(v.day.error || v.month.error || v.year.error)"
        :id="v.day.error ? fieldBirth.day.idError : v.month.error ? fieldBirth.month.idError : v.year.error ? fieldBirth.year.idError : ''">
        {{ error.message }}
      </p>
      <p
        class="field-help"
        :key="`s-${index}`"
        v-if="(!!day && !!month && !!year) && !isDateExist">
        {{ error.message }}
      </p>
    </template>
  </div>
</template>

<script>
  import { tag } from '@Foundation/analyticsHandler';
  import FieldSelect from '@Feature/CustomForms/fields/FieldSelect/code/Scripts/components/field-select.vue';
  import FieldHelp from '@Feature/CustomForms/fields/FieldHelp/code/Scripts/components/field-help.vue';

  export default {
    name: 'date-of-birth',

    components: { FieldSelect, FieldHelp },

    directives: { tag },

    props: {
      fieldBirth: { type: Object, required: true, default: undefined },
      startValidate: { type: Boolean, required: false, default: undefined },
      required: { type: Boolean, required: false, default: false }
    },

    data() {
      return {
        day: this.fieldBirth.day.value || '',
        month: this.fieldBirth.month.value || '',
        year: this.fieldBirth.year.value || '',
        days: [],
        years: [],
        updateDay: false,
        updateYear: false,
        v: {
          day: {
            error: false,
            required: false
          },
          month: {
            error: false,
            required: false
          },
          year: {
            error: false,
            required: false
          }
        }
      };
    },

    computed: {
      FieldDays() {
        const dayData = this.fieldBirth.day;
        dayData.listValue = this.days;
        return dayData;
      },

      FieldYears() {
        const yearData = this.fieldBirth.year;
        yearData.listValue = this.years;
        return yearData;
      },

      isDateExist() {
        return (!!this.day && !!this.month && !!this.year) ? Date.now() > Date.parse(`${this.month}/${this.day}/${this.year}`) : true;
      },

      isValid() {
        return !this.v.day.error && !this.v.month.error && !this.v.year.error;
      }
    },

    /**
     * Vuelidate config
     * @see {@link https://github.com/monterail/vuelidate|GitHub}
     */

    watch: {
      FieldDays() {
        this.updateDay = true;
        this.$nextTick(() => this.updateDay = false);
      },
      FieldYears() {
        this.updateYear = true;
        this.$nextTick(() => this.updateYear = false);
      },
      month(value) {
        this.populateDays(value);
        /* istanbul ignore else */
        if (+this.day > this.days.length) {
          this.day = this.days.length;
        }
        this.$emit('birth', { type: 'month', value });

        this.v.day.required = true;
        this.v.year.required = true;

        this.v.month.error = false;
      },
      day(value) {
        this.$emit('birth', { type: 'day', value });

        this.v.month.required = true;
        this.v.year.required = true;

        this.v.day.error = false;
      },
      year(value) {
        this.populateDays(this.month);
        /* istanbul ignore else */
        if (+this.day > this.days.length) {
          this.day = this.days.length;
        }
        this.$emit('birth', { type: 'year', value });

        this.v.day.required = true;
        this.v.month.required = true;

        this.v.year.error = false;
      },
      isValid(value) {
        const payload = value && this.isDateExist;
        this.$emit('birthIsValid', payload);
      },
      isDateExist(value) {
        const payload = value && this.isValid;
        this.$emit('birthIsValid', payload);
      },
      startValidate(value) {
        if(this.required) {
          if (value && (this.day=="" || this.month=="" || this.year=="")) {
            this.day !== '' ? this.v.day.error = false : this.v.day.error = true;
            this.month !== '' ? this.v.month.error = false : this.v.month.error = true;
            this.year !== '' ? this.v.year.error = false : this.v.year.error = true;
            this.$emit('birthIsValid', false);
          }else{
            this.$emit('birthIsValid', true);
          }
        }else {
          /* istanbul ignore else */
          if (value && (!!this.day || !!this.month || !!this.year)) {
            this.day !== '' ? this.v.day.error = false : this.v.day.error = true;
            this.month !== '' ? this.v.month.error = false : this.v.month.error = true;
            this.year !== '' ? this.v.year.error = false : this.v.year.error = true;
          }
        }
      }
    },

    mounted() {
      this.populateDays(this.month);
      this.populateYears();
    },

    methods: {
      populateDays(month) {
        // delete the current set of <option> elements out of the
        // day <select>, ready for the next set to be injected

        this.days = [];

        // Create variable to hold new number of days to inject
        let dayNum;
        const monthIndex = this.fieldBirth.month.listValue.indexOf(month) + 1;

        // 31 or 30 days? to do with month index
        if (monthIndex === 1 || monthIndex === 3 || monthIndex === 5 || monthIndex === 7 || monthIndex === 8 || monthIndex === 10 || monthIndex === 12) {
          dayNum = 31;
        } else if (monthIndex === 4 || monthIndex === 6 || monthIndex === 9 || monthIndex === 11) {
          dayNum = 30;
        } else if (monthIndex === 2) {
          // If month is February, calculate whether it is a leap year or not
          const year = this.year;
          const leap = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
          dayNum = leap ? 29 : 28;
        } else {
          dayNum = 31;
        }

        // inject the right number of new <option> elements into the day <select>
        for (let i = 1; i <= dayNum; i++) {
          this.days.push(i);
        }
      },

      populateYears() {
        // get this year as a number
        const date = new Date();
        const year = date.getFullYear();

        // Make this year, and the 100 years before it available in the year <select>
        for (let i = 18; i <= 100; i++) {
          this.years.push(year - i);
        }
      }
    }
  };
</script>

<style lang='scss' src="./date-of-birth.scss"></style>
