<template>
  <div class="loader" :class="{'-show': show}" aria-hidden="true">
    <div class="loader__wrapper">
      <div>
        <svg class="loader__circular" viewBox="25 25 50 50" aria-hidden="true">
          <circle class="loader__path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
        </svg>
      </div>
      <div class="loader__content">
        <slot/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'loader',
    props: {
      show: {
        type: Boolean,
        default: false,
        required: true
      }
    }
  };
</script>
<style lang="scss" src="./loader.scss"></style>
