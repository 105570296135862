import { throttle } from '@Foundation/utilities';

/**
 * Sets a CSS Custom Property for actual vh value and updates it on resize
 * @see {https://css-tricks.com/the-trick-to-viewport-units-on-mobile} for detailled explanation
 * @returns {undefined}
 */
export function customVhProperty() {
  setValue();

  window.addEventListener('resize', throttle(() => {
    setValue();
  }, 100));
}

/**
 * Get the viewport height and multiple it by 1% to get a value for a vh unit
 * Set the value in the --vh custom property to the root of the document
 * @returns {undefined}
 */
function setValue() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};
