<template>
  <div
    class="slider"
    :class="{ 'slider-vertical': vertical, 'slider-text-selectable': !swipeOnDesktop }"
    :id="carouselIdentifier">
    <button
      v-if="navigationEnabled"
      class="slider__navigation-button slider-navigation-prev"
      @click.prevent="advancePage('backward', 'navigation'); navTagging('prev','arrow');"
      :class="{ 'slider-navigation--disabled': !canAdvanceBackward }"
      :aria-disabled="!canAdvanceBackward"
      :disabled="!canAdvanceBackward"
      v-on="!canAdvanceForward ? { blur: blurEvent, 'keyup.shift.tab': shiftTabKeyupEvent } : {}">
      <span class="is-sr-only">{{ previousPanelText }}</span>
    </button>
    <div class="slider__wrapper">
      <ol
        class="slider__inner"
        :style="{
          transform: `translate${this.vertical ? 'Y' : 'X'}(${this.currentOffset}px)`,
          transition: this.transitionStyle,
          flexBasis: `${this.slideWidth}px`,
          msFlexPreferredSize: `${this.slideWidth}px`,
          visibility: `${this.slideWidth ? 'visible' : 'hidden'}`
        }">
        <slot/>
      </ol>
    </div>
    <button
      v-if="navigationEnabled"
      class="slider__navigation-button slider-navigation-next"
      @click.prevent="advancePage('forward', 'navigation'); navTagging('next','arrow');"
      :class="{ 'slider-navigation--disabled': !canAdvanceForward }"
      :aria-disabled="!canAdvanceForward"
      :disabled="!canAdvanceForward"
      @blur="blurEvent()"
      @keyup.shift.tab="shiftTabKeyupEvent">
      <span class="is-sr-only">{{ nextPanelText }}</span>
    </button>
    <pagination
      @paginationClick="(index) => this.goToPage(index, 'pagination')"
      v-if="paginationEnabled && pageCount > 0"
      :current-page="currentPage"
      :page-count="pageCount"
      :labels="labels"
      :tagging-labels="taggingLabels"
      :is-clickable="isPaginationClickable"
      :nav-tagging-label="navTaggingLabel"
      :item-per-page-desktop="3"
      :page-category="pageCategory"/>
  </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import { debounce } from '@Foundation/utilities/timing';
import Pagination from '../pagination/pagination.vue';

export default {
  name: 'slider',

  components: { Pagination },

  props: {
    withFocusableItems: { type: Boolean, required: false, default: false },
    nextPanelText: { type: String, required: false, default: 'next panel' },
    previousPanelText: { type: String, required: false, default: 'previous panel' },
    navTaggingLabel: { type: [String, Array], required: false, default: '' },
    itemPerPageDesktop: { type: Number, required: true },
    pageCategory: { type: String, required: false, default: 'page category' },

    // Copy labels for translation
    labels: {
      type: Object,
      required: false,
      default: () => ({
        pagination: {
          ariaLabel: 'Slide {0}'
        },
        navigation: {
          ariaLabelPrevious: 'Go to the previous slide',
          ariaLabelNext: 'Go to the next slide'
        }
      })
    },

    // Copy labels for tagging
    taggingLabels: {
      type: Object,
      required: false,
      default: () => ({
        pagination: {
          category: 'product pictures'
        },
        navigation: {
          category: 'product pictures'
        }
      })
    },

    isPaginationClickable: {
      type: Boolean,
      required: false,
      default: true
    },

    // Slide transition easing. any valid CSS transition easing accepted
    easing: { type: String, default: 'ease' },

    // Minimum distance for the swipe to trigger a slide advance
    minSwipeDistance: { type: Number, default: 20 },

    // Flag to render the navigation component (next/prev buttons)
    navigationEnabled: { type: Boolean, default: true },

    // Flag to render pagination component
    paginationEnabled: { type: Boolean, default: true },

    // Maximum number of slides displayed on each page
    perPage: { type: Number, default: 2 },

    // Vertical instead of horizontal
    vertical: { type: Boolean, default: false },

    // the offset between items
    slideSpacing: {
      type: Object | Array,
      default: () => ({})
    },

    // padding for the slides
    slidePadding: { type: Number, default: 0 },

    /**
     * Configure the number of visible slides with a particular browser width.
     * This will be an array of arrays, ex. [[320, 2], [1199, 4]]
     * Formatted as [x, y] where x=browser width, and y=number of slides displayed.
     * ex. [1199, 4] means if (window <= 1199) then show 4 slides per page
     */
    perPageCustom: {
      type: Array,
      default: undefined
    },

    // Scroll per page, not per item
    scrollPerPage: { type: Boolean, default: false },

    // Slide transition speed. Number of milliseconds accepted
    speed: { type: Number, default: 400 },

    // Start at slide
    startAt: { type: Number, default: 0 },

    // Start at slide
    carouselIdentifier: { type: String, default: `carousel-${Date.now()}` },

    // disable or enable swipe on desktop for Sitecore edit mode
    swipeOnDesktop: { type: Boolean, default: true }
  },

  data() {
    return {
      browserWidth: null,
      smallScreen: !window.matchMedia('(min-width: 1023px)').matches,
      carouselSize: null,
      currentPage: this.startAt,
      prevPage: this.startAt,
      dragOffset: 0,
      dragStartX: 0,
      mousedown: false,
      slideCount: 0,
      maxSwipeAngle: 60,
      angle: 0,
      slidesElms: null,
      shiftAndTabHappened: false
    };
  },

  computed: {
    /**
     * Given a viewport width, find the number of slides to display
     * @param  {Number} width - Current viewport width in pixels
     * @return {Number}       Number of slides to display
     */
    breakpointSlidesPerPage() {
      if (!this.perPageCustom) {
        return this.perPage;
      }

      const breakpointArray = this.perPageCustom;
      const width = this.browserWidth;

      const breakpoints = breakpointArray.sort((a, b) => ((a[0] > b[0]) ? -1 : 1));

      // Reduce the breakpoints to entries where the width is in range
      // The breakpoint arrays are formatted as [widthToMatch, numberOfSlides]
      const matches = breakpoints.filter(breakpoint => width >= breakpoint[0]);

      // If there is a match, the result should return only
      // the slide count from the first matching breakpoint
      const match = matches[0] && matches[0][1];

      return match || this.perPage;
    },

    /**
     * @return {Boolean} Can the slider move forward?
     */
    canAdvanceForward() {
      return (this.currentPage < (this.pageCount - 1));
    },

    /**
     * @return {Boolean} Can the slider move backward?
     */
    canAdvanceBackward() {
      return (this.currentPage > 0);
    },

    /**
     * Number of slides to display per page in the current context.
     * This is constant unless responsive perPage option is set.
     * @return {Number} The number of slides per page to display
     */
    currentPerPage() {
      return (!this.perPageCustom || this.$isServer)
        ? this.perPage
        : this.breakpointSlidesPerPage;
    },

    /**
     * The horizontal distance the inner wrapper is offset while navigating.
     * @return {Number} Pixel value of offset to apply
     */
    currentOffset() {
      const page = this.currentPage;
      const width = this.slideWidth;
      const dragged = this.dragOffset;

      // The offset distance depends on whether the scrollPerPage option is active.
      // If this option is active, the offset will be determined per page rather than per item.
      const offset = (this.scrollPerPage) ? (page * width * this.currentPerPage) : (page * width);
      const currentOffset = (offset + dragged) * -1;
      const slideSpacing = this.getSlideSpacing();
      let elem = document.getElementById('main-container');
      let style = getComputedStyle(elem);
      if((style.direction === 'rtl') && this.smallScreen) {
        return (currentOffset + slideSpacing) * -1;
      }
      else {
        return currentOffset + slideSpacing;
      }

    },

    isHidden() {
      return (this.carouselSize <= 0);
    },

    /**
     * Calculate the number of pages of slides
     * @return {Number} Number of pages
     */
    pageCount() {
      const slideCount = this.slideCount; // eslint-disable-line prefer-destructuring
      const perPage = this.currentPerPage;

      if (this.scrollPerPage) {
        const pages = Math.ceil(slideCount / perPage);
        return (pages < 1) ? 1 : pages; // Be sure to not round down to zero pages
      }

      return (slideCount - (this.currentPerPage - 1));
    },

    /**
     * Calculate the width of each slide
     * @return {Number} Slide width
     */
    slideWidth() {
      const width = this.carouselSize;
      const perPage = this.currentPerPage;
      const slideWidth = width / perPage;

      return slideWidth - (this.getSlideSpacing() * 2);
    },

    transitionStyle() {
      return `${this.speed / 1000}s ${this.easing} transform`;
    }
  },

  beforeUpdate() {
    this.computeCarouselSize();
  },

  updated() {
    this.$nextTick(this.slidesVisibility);
  },

  mounted() {
    window.addEventListener('resize', debounce(this.computeCarouselSize, 16));

    if ('ontouchstart' in window) {
      this.$el.addEventListener('touchstart', this.handleMousedown);
      this.$el.addEventListener('touchend', this.handleMouseup);
      this.$el.addEventListener('touchmove', this.handleMousemove);
    }

    /* istanbul ignore else */
    if (this.swipeOnDesktop) {
      this.$el.addEventListener('mousedown', this.handleMousedown);
      this.$el.addEventListener('mouseup', this.handleMouseup);
      this.$el.addEventListener('mousemove', this.handleMousemove);
    }

    this.attachMutationObserver();
    this.computeCarouselSize();
    this.$nextTick(this.slidesVisibility);
  },

  /* istanbul ignore next */
  beforeDestroy() {
    this.detachMutationObserver();
    window.removeEventListener('resize', this.getBrowserWidth);
    /* istanbul ignore else */
    if ('ontouchstart' in window) {
      this.$el.removeEventListener('touchmove', this.handleMousemove);
    } else {
      this.$el.removeEventListener('mousemove', this.handleMousemove);
    }
  },

  methods: {
    isUsingKeyboard() {
      return document.body.classList.contains('is-using-keyboard');
    },
    a11yFocus() {
      const slidesOnPage = this.currentPerPage;
      const currentSlide = this.currentPage;
      /* istanbul ignore else */
      if (this.isUsingKeyboard()) {
        const activeSlide = currentSlide * slidesOnPage;

        if (!window.matchMedia('(min-width: 1024px)').matches) {
          this.$children[activeSlide].$el.querySelector('[panel-focusable]').focus();
        } else {
          this.$nextTick(() => {
            this.$children[activeSlide].$el.querySelector('[panel-focusable]').focus();
          });
        }
      }
    },

    slidesVisibility() {
      this.slidesElms = [...this.$el.querySelectorAll('.slider__slide')];

      this.slidesElms.forEach((item) => {
        item.style.visibility = 'hidden';
      });

      if (!this.scrollPerPage) {
        this.slidesElms.slice(this.currentPage, this.currentPage + this.currentPerPage).forEach((item) => {
          item.style.visibility = 'visible';
        });
      } else {
        this.slidesElms.slice(this.currentPage * this.currentPerPage, (this.currentPage + 1) * this.currentPerPage).forEach((item) => {
          item.style.visibility = 'visible';
        });
      }
    },

    /**
     * Increase/decrease the current page value
     * @param  {String} direction - (Optional) The direction to advance
     * @param from
     */
    advancePage(direction, from = false) {
      if (direction && direction === 'backward' && this.canAdvanceBackward) {
        this.goToPage(this.currentPage - 1, from);
      } else if (
        (!direction || (direction && direction !== 'backward'))
        && this.canAdvanceForward
      ) {
        this.goToPage(this.currentPage + 1, from);
      }
      this.slidesVisibility();
    },

    /**
     * A mutation observer is used to detect changes to the containing node
     * in order to keep the magnet container in sync with the height its reference node.
     * Note: This does not work for ie10.
     */
    /* istanbul ignore next */
    attachMutationObserver() {
      const MutationObserver = window.MutationObserver
        || window.WebKitMutationObserver
        || window.MozMutationObserver;
      /* istanbul ignore next */
      if (MutationObserver) {
        const config = { attributes: true, data: true };
        this.mutationObserver = new MutationObserver(() => {
          this.$nextTick(() => {
            this.computeCarouselSize();
          });
        });
        if (this.$parent.$el) {
          this.mutationObserver.observe(this.$parent.$el, config);
        }
      }
    },

    getSlideSpacing() {
      let value = 0;
      for (const key in this.slideSpacing) {
        /* istanbul ignore else */
        if (Object.prototype.hasOwnProperty.call(this.slideSpacing, key)) {
          /* istanbul ignore else */
          if (key <= this.getBrowserWidth()) {
            value = this.slideSpacing[key];
          }
        }
      }
      return value;
    },

    /**
     * Stop listening to mutation changes
     */
    detachMutationObserver() {
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    },

    /**
     * Get the current browser viewport width
     * @return {Number} Browser"s width in pixels
     */
    getBrowserWidth() {
      this.browserWidth = window.innerWidth;
      return this.browserWidth;
    },

    /**
     * Get the width of the carousel DOM element
     * @return {Number} Width of the carousel in pixels
     */
    getCarouselSize() {
      if (this.vertical) {
        this.carouselSize = (this.$el && this.$el.clientHeight) || 0; // Assign globally
      } else {
        this.carouselSize = (this.$el && this.$el.clientWidth) || 0; // Assign globally
      }

      return this.carouselSize;
    },

    /**
     * Filter slot contents to slide instances and return length
     * @return {Number} The number of slides
     */
    getSlideCount() {
      this.slideCount = (
        this.$slots
        && this.$slots.default
        && this.$slots.default.filter(slot =>
          slot.tag
          && slot.tag.indexOf('slide') > -1).length
      ) || 0;
    },

    /**
     * Set the current page to a specific value
     * This function will only apply the change if the value is within the carousel bounds
     * @param  {Number} page - The value of the new page number
     * @param from
     */
    goToPage(page, from = false) {
      /*istanbul ignore else */
      if ((page >= 0) && (page <= this.pageCount)) {
        this.currentPage = page;
        this.$emit('pageChange', this.currentPage, this.prevPage, from);
        this.prevPage = this.currentPage;

        /*istanbul ignore else */
        if (this.withFocusableItems) {
          this.a11yFocus();
        }
      }
    },

    /**
     * Trigger actions when mouse is pressed
     * @param  {Object} e - The event object
     */
    handleMousedown(e) {
      /*istanbul ignore else */
      if (!e.touches) {
        e.preventDefault();
      }

      this.mousedown = true;
      this.dragStart = (e.touches) ? e.touches[0] : e;
    },

    /**
     * Trigger actions when mouse is released
     * @param  {Object} e - The event object
     */
    handleMouseup() {
      this.mousedown = false;
      this.dragOffset = 0;
    },

    /**
     * We know the main Distance swiped and the sub direction distance swiped
     * based on that we can calculate the swipe angle
     * @param {number} Opposite
     * @param {number} Adjacent
     * @returns {number} the angle based on Tangent: tan(Î¸) = Opposite / Adjacent
     */
    swipeAngle(main, sub) {
      return Math.atan(Math.abs(sub) / Math.abs(main)) * (180 / Math.PI);
    },

    /**
     * Trigger actions when mouse is pressed and then moved (mouse drag)
     * @param  {Object} e - The event object
     */
    handleMousemove(e) {
      if (!this.mousedown) {
        return;
      }

      const event = (e.touches) ? e.touches[0] : e;

      let mainDirectionDragDistance = 0;
      let subDirectionDragDistance = 0;

      if (this.vertical) {
        mainDirectionDragDistance = (this.dragStart.clientY - event.clientY);
        subDirectionDragDistance = (this.dragStart.clientX - event.clientX);
      } else {
        mainDirectionDragDistance = (this.dragStart.clientX - event.clientX);
        subDirectionDragDistance = (this.dragStart.clientY - event.clientY);
      }

      this.angle = this.swipeAngle(mainDirectionDragDistance, subDirectionDragDistance);

      /**
       * If the swipe angle is less then the max swipe angle then
       * the user is probably using the slideshow to see the next slide
       *
       * We want to use prevent default to prevent the page from scrolling when switching to a new slide.
       */
      if (this.angle < this.maxSwipeAngle) {
        e.preventDefault();
      }

      this.dragOffset = mainDirectionDragDistance;

      if (this.dragOffset > this.minSwipeDistance) {
        this.handleMouseup();
        this.advancePage();
      } else if (this.dragOffset < -this.minSwipeDistance) {
        this.handleMouseup();
        this.advancePage('backward');
      }
    },

    /**
     * Re-compute the width of the carousel and its slides
     */
    /* istanbul ignore next */
    computeCarouselSize() {
      this.getSlideCount();
      this.getBrowserWidth();
      this.getCarouselSize();
      this.setCurrentPageInBounds();
    },

    /**
     * When the current page exceeds the carousel bounds, reset it to the maximum allowed
     */
    setCurrentPageInBounds() {
      if (!this.canAdvanceForward) {
        const setPage = (this.pageCount - 1);
        this.currentPage = (setPage >= 0) ? setPage : 0;
      }
    },

    blurEvent() {
      if (this.shiftAndTabHappened) {
        this.shiftAndTabHappened = false;
      }
    },

    shiftTabKeyupEvent() {
      this.shiftAndTabHappened = true;
    },
    generateSequence(start, end){
      var sequence = "";
      for (var i = start+1; i <= end; i++) {
        sequence += i+'-//-';
      }
      return sequence.slice(0,-4);;
    },
    navTagging(param,navigationEvent,index=null){
      const currentElemnt = this.$el;
      let activeSliderNumber = 0;
      if(currentElemnt.querySelector(".slider__dot--active").id){
        activeSliderNumber=currentElemnt.querySelector(".slider__dot--active").id;
      }

      let start =1;
      let end =1;
      let sliderValue =parseInt(activeSliderNumber);
      const slideNum = param === 'next' ? 1 : -1
      sliderValue +=  slideNum;
      let newvalue='';
      let sliderNumber =''
      if(this.smallScreen){
        start = sliderValue-1;
        end = sliderValue;
        sliderNumber =end;
        newvalue= `${this.navTaggingLabel.slice(start, end)}`;
      }
      else{
        start = sliderValue *this.itemPerPageDesktop;
        end = start - this.itemPerPageDesktop;
        sliderNumber =end+1;
        newvalue= `${this.navTaggingLabel.slice(end, start).join('-//-')}`;
      }
      let arrayLenth = this.navTaggingLabel.slice(end, start).length;
      if(arrayLenth >1){
        sliderNumber=this.generateSequence(end, end+arrayLenth);
      }
      AnalyticsHandler.pushDataLayer({
        event: "uaevent",
        ecommerce: "undefined",
        event_name: "slider_button_click",
        eventCategory: this.pageCategory,
        eventAction: "select::slider navigation elements",
        eventLabel: `${navigationEvent}::${newvalue}::${sliderNumber}`,
        cta_name:  `${navigationEvent}::${newvalue}::${sliderNumber}`,
        module_name: `slider navigate::${navigationEvent}`
      });

    },
  }
};
</script>

<style lang="scss" src="./slider.scss"></style>
