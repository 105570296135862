<template>
  <div class="my-skin-sos">
    <load-script
      v-if="CaptchaConfig.isVisible"
      src="https://www.google.com/recaptcha/api.js?onload=onCaptchaAPIReady&render=explicit"
    />
    <a :href="documentReferrer" @click="goToPreviusPage" class="my-skin-sos-close">
      <span class="close-icon heavy"/>
      <span class="is-sr-only">
        Back to: {{ previousPageName }}
      </span>
    </a>
    <div class="my-skin-sos-landing" v-if="!isInSurvey">
      <slot name="banner" />
      <div class="my-skin-sos-gdpr-content">
        <div class="my-skin-sos-gdpr-content-intro">
          <slot name="gdpr-intro" />
        </div>
        <div class="my-skin-sos-pass-gate">
          <div
            v-if="GdprCheckBoxConfig.isVisible"
            class="my-skin-sos-iagree-check-box-wrapper">
            <field-checkbox
              v-model="GDPRiAgree"
              :field="GdprCheckBoxConfig"/>
          </div>
          <div class="my-skin-sos-cpacha" v-if="CaptchaConfig.isVisible" v-show="GDPRiAgree">
            <div :id="CaptchaConfig.id" class="g-recaptcha"/>
            <p
              class="field-help"
              v-if="hasErrorCapcha"
              :id="CaptchaConfig.idError">
              {{ CaptchaConfig.error }}
            </p>
          </div>
          
          <button type="button" :class="ctaClass" v-if="GDPRiAgree && !hasErrorCapcha" @click="switchToSurvey">
            <span class="btn__wrapper" v-html="decodeHTML(StartSurveyButtonText)" />
          </button>
          
        </div>
      </div>
    </div>
    <div class="my-skin-sos-survey" v-if="isInSurvey">
      <div
        v-if="surveyStepData"
        class="my-skin-sos-survey-progress-bar"
        :style="{width: selectedAnswers.length / surveyStepData.stepsToCompleetSurvey * 100 + '%'}"
      />
      <button type="button" class="my-skin-sos-prev-step"
              v-if="selectedAnswers.length"
              @click="goToPreviusStep"
      >
        <span class="is-sr-only">Previous step</span>
      </button>
      <div class="my-skin-sos-survey-column my-skin-sos-question-container" v-if="surveyStepData" ref="answersContainer">
        <p
          aria-atomic="true"
          aria-live="polite"
          class="my-skin-sos-steps-count">
          <span class="intro-text" v-html="decodeHTML(StepIntroText)" />
          {{ surveyStepData.stepNumber }} / {{ surveyStepData.stepsToCompleetSurvey }}
        </p>
        <h1 class="my-skin-sos-question" v-html="surveyStepData.question" />
        <p
          v-if="surveyStepData && surveyStepData.questionNotice"
          class="my-skin-sos-question-notice"
          v-html="surveyStepData.questionNotice"
        />
      </div>
      <div
        class="my-skin-sos-answers-container"
        :style="{backgroundImage: `url(${answersContainerBackgroundImageUrl})`}"
      >
        <ul
          v-if="surveyStepData"
          class="my-skin-sos-answers"
          :class="`${reanimate ? 'slideInRight' : 'rotateOutUpLeft'} ${surveyStepData.answers.length <= 3 && 'short-list'} ${surveyStepData.answers.length % 2 !== 0 && 'odd-list'}`"
        >
          <li
            class="my-skin-sos-answer"
            v-for="(answerData, index) in surveyStepData.answers"
            :class="answerIsSelected(answerData.id) && 'selected'"
            @click="toggleSelection(answerData.id)"
            @keydown.space="toggleSelection(answerData.id)"
            tabindex="0"
            :key="index"
            role="checkbox"
            :aria-checked="`${answerIsSelected(answerData.id)}`"
            v-html="answerData.answer"
          />
        </ul>

        <div v-if="smallScreen" class="my-skin-sos-survey-column my-skin-sos-action-container">
          <button
            type="button"
            :aria-hidden="`${!stepState.selectedAnswersIDs.length}`"
            :class="stepState.selectedAnswersIDs.length ? StepNextButtonBlackOnMobile ? '-black show' : 'show' : ''"
            class="cta cta--light"
            @click="requestNextStep()"
          >
            <span class="btn__wrapper" v-html="decodeHTML(StepNextButtonText)" />
          </button>
        </div>

      </div>

      <div v-if="!smallScreen" class="my-skin-sos-survey-column my-skin-sos-action-container">
        <button
          type="button"
          :aria-hidden="`${!stepState.selectedAnswersIDs.length}`"
          :class="stepState.selectedAnswersIDs.length && '-black show'"
          class="cta cta--light"
          @click="requestNextStep()"
        >
          <span class="btn__wrapper" v-html="decodeHTML(StepNextButtonText)" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  import LoadScript from '@Foundation/loadscript/loadscript';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';
  import FieldCheckbox from '@Feature/CustomForms/fields/FieldCheckbox/code/Scripts/components/field-checkbox.vue';
  import { postData } from '@Foundation/services';
  import { smoothScroll } from '@Foundation/utilities';
  import { eventBus } from '@EventBus';

  export default {
    name: 'my-skin-sos',
    components: { FieldCheckbox, LoadScript },
    props: {
      GdprCheckBoxConfig: { type: Object, required: true, default: undefined },
      CaptchaConfig: { type: Object, required: false, default: undefined },
      StartSurveyButtonText: { type: String, required: true, default: '' },
      StepIntroText: { type: String, required: true, default: '' },
      StepNextButtonText: { type: String, required: true, default: '' },
      StepNextButtonBlackOnMobile: { type: Boolean, required: false, default: false },
      answersContainerBackgroundImageUrl: { type: String, required: true, default: '' },
      surveyEndPoint: { type: String, required: true, default: '' },
      skipGdprPage: { type: Boolean, required: true, default: true },
      ctaClass: {type: String, default: 'cta cta--dark cta--alt'},
    },

    data() {
      return {
        GDPRiAgree: !this.GdprCheckBoxConfig.isVisible,
        hasErrorCapcha: this.CaptchaConfig.isVisible,
        isInSurvey: this.skipGdprPage,
        previousPageName: 'Home page',
        surveyStepData: null,
        serverError: null,
        stepState: {
          questionID: null,
          isMultipleAnswers: false,
          selectedAnswersIDs: [],
        },
        stepsHistory: [],
        selectedAnswers: [],
        reanimate: true,
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
        documentReferrer: window.document.referrer.length ? window.document.referrer : '/',
      };
    },

    created() {
      window.onCaptchaAPIReady = this.onCaptchaAPIReady;
      this.previousPageName = this.getPreviousPageName();

      eventBus.$on('mediaquery::changed', () => {
        this.smallScreen = !window.matchMedia('(min-width: 1024px)').matches;
      });

      this.requestNextStep();
    },

    methods: {
      getPreviousPageName() {
        const storageData = sessionStorage.getItem('lrp-previous-page-name');
        return !storageData && 'Home page';
      },
      // re animate answers block on step switch
      reAnimate() {
        this.reanimate = false;
        setTimeout(() => this.reanimate = true, 250);
      },
      // BE pass html in encoded format
      // v-html wont parse encoded html
      // fractal will bark at ' char in string
      decodeHTML(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      },

      switchToSurvey() {
        this.pushTagging();
        this.isInSurvey = !this.isInSurvey;
        /* istanbul ignore else */
        setTimeout(() => {
          smoothScroll(this.$refs.answersContainer);
        }, 300);
      },

      onCaptchaAPIReady() {
        /* istanbul ignore next */
        setTimeout(() => {
          if (typeof window.grecaptcha === 'undefined' && typeof window.grecaptcha.render !== 'function') {
            this.onCaptchaAPIReady();
          } else {
            window.grecaptcha.render(this.CaptchaConfig.id, {
              sitekey: this.CaptchaConfig.siteKey,
              callback: this.checkCaptcha
            });
          }
        }, 100);
      },

      checkCaptcha() {
        /* istanbul ignore next */
        window.grecaptcha.getResponse().length !== 0 ? this.hasErrorCapcha = false : this.hasErrorCapcha = true;
      },

      stripTags(string) {
        return string.replace(/<\/?[^>]+(>|$)/g, '');
      },

      pushTagging(label = this.decodeHTML(this.StartSurveyButtonText), eventType = 'start') {
        AnalyticsHandler.pushDataLayer({
            event: 'uaevent',
            ecommerce: 'undefined',
            event_name:'site_load_service',
            eventCategory: 'content page::service',
            eventAction: 'content page::service::select::myskinsos',
            eventLabel:'load::myskinsos',
            service_name:'skindr',
            service_category:'diagnose',
            cta_name: this.stripTags(label).toLowerCase(),
            product_info:'none'
        });
      },

      // extra analytics requested by agency
      onStepTaging(stepData, countryCode = 'en') {
        AnalyticsHandler.pushDataLayer({
          'event': 'updatevirtualpath',
          'virtualPageURL': `/lrp/${countryCode}/${this.stripTags(stepData.question).toLowerCase()}/step${stepData.stepNumber}`,
          'virtualPageTitle': `${this.stripTags(stepData.question).toLowerCase()} step${stepData.stepNumber}`,
        });
      },

      goToPreviusPage() {
        this.pushTagging(this.isInSurvey ? this.stripTags(this.surveyStepData.question) : 'my skin sos gdpr form', 'close');
      },

      goToPreviusStep() {
        this.pushTagging(this.surveyStepData.question, 'previous');
        this.updateStepState(
          this.stepsHistory.pop(),
          this.selectedAnswers.pop().selectedAnswersIDs
        );
      },

      answerIsSelected(answerID) {
        return this.stepState.selectedAnswersIDs.includes(answerID);
      },

      updateStepState(surveyStepData, slectedAswers = []) {
        this.surveyStepData = surveyStepData;
        this.stepState.questionID = surveyStepData.questionID;
        this.stepState.isMultipleAnswers = surveyStepData.isMultipleAnswers;
        this.stepState.selectedAnswersIDs = slectedAswers;
        // this is best solution to recive locale at the moment
        const currentLocale = window.location.pathname.split('/')[1];
        this.onStepTaging(surveyStepData, currentLocale);
      },

      toggleSelection(answerID) {
        let answers = this.stepState.selectedAnswersIDs;
        if (answers.includes(answerID)) {
          answers = answers.filter(entry => entry !== answerID);
        } else if (!this.stepState.isMultipleAnswers)  {
          answers = [answerID];
        } else {
          answers.push(answerID);
        }
        this.stepState.selectedAnswersIDs = answers;
      },

      /*
        Extra code made especially for 'Jonathan Echirpeau' [agency]
        no logic here: All previous answers to all previous questions should be provided to each GA question event push
        TODO: refactor(delete) at first opportunity
        P.S.
        step split :: in step answer split -//-
      */
      getAllStepAnswers() {
        const allAnswers = this.selectedAnswers.map((stepSelectedAnswers, index) => {
          const inStepAnswers = this.stepsHistory[index].answers.filter(answer => {
            return stepSelectedAnswers.selectedAnswersIDs.includes(answer.id);
          });
          return `${index > 0 && '::'}${inStepAnswers.reduce((gaString, answerData, index) => `${gaString}${index > 0 ? '-//-' : ''}${answerData.answer}`, '')}`;
        });
        return allAnswers.join('');
      },

      requestNextStep() {
        if (this.surveyStepData) { // avoid history updated calls on load
          // save current step data if user will wish to return to it
          this.stepsHistory.push(this.surveyStepData);
          // save current step state data if user will wish to return to
          // Notice: this.stepState is reactive prop and we need snapshot to be saved (reason for Object.assign)
          this.selectedAnswers.push(Object.assign({}, this.stepState));
          // push ga
          this.pushTagging(this.getAllStepAnswers(), `${this.surveyStepData.question}::next`);
        }

        postData(this.surveyEndPoint, {
          answers: this.selectedAnswers
        })
          .then((response) => {
            if (response.resultPage) {
              location.href = response.resultPage;
            } else {
              this.updateStepState(response);
              this.reAnimate();
              smoothScroll(this.$refs.answersContainer);
            }
          })
          .catch((err) => {
            this.serverError = err;
          });
      },
    },
  };
</script>
<style lang="scss" src="./mySkinSOS.scss"></style>
